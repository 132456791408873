
.bottom_sheet_wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: map-get($z-index, popup);
  &.action_bar {
    bottom: 64px;
  }
  .dimmed {
    position: static;
    width: 100%;
    height: 100%;
    background: rgba($color: #000, $alpha: 0.5);
  }
}
.bottom_sheet {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  @include safeArea(false, padding, bottom);
  width: 100%;
  background-color: $color-white;
  border-radius: 10px 10px 0 0;
  &.type_handlebar {
    &::before {
      position: absolute;
      top: 16px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 32px;
      height: 4px;
      border-radius: 2px;
      background: rgba(121, 116, 126, 0.4);
      content: '';
    }
  }
}
.bottom_sheet_header {
  padding: 15px 20px;
  .bottom_sheet_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: $color-black;
  }
}
.bottom_sheet_content {
  overflow-y: auto;
  min-height: 112px;
  max-height: 242px;
  margin: 20px 0;
  padding: 0 20px 12px;
  box-sizing: border-box;
  .bottom_sheet.type_large & {
    max-height: 441px;
  }
  .bottom_sheet.type_calendar & {
    max-height: none;
    margin: 0;
    padding: 0 20px 32px;
  }
  .bottom_sheet.type_handlebar & {
    max-height: calc(100vh - 65px);
    margin: 30px 0 0;
    padding: 0;
    > div {
      padding-bottom: 15px;
    }
  }
  .bottom_sheet.type_full & {
    max-height: none;
  }
  .bottom_sheet_header + & {
    margin-top: 8px;
  }
  .bottom_check_item {
    + .bottom_check_item {
      margin-top: 17px;
    }
  }
  .bottom_banner_item {
    position: relative;
    + .bottom_banner_item {
      margin-top: 10px;
    }
  }
  .bottom_banner_imgbox {
    overflow: hidden;
    border-radius: 10px;
    background-color: map-get($color-background, bg-01);
  }
  .bottom_banner_link {
    display: block;
    position: relative;
    padding-top: 54.6875%;

    &.event_game_list {
      padding-top: 28.125%;
    }
  }
  .bottom_banner_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.bottom_sheet_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: -20px;
  background: $color-white;
  z-index: 1;
  &.blind {
    margin-top: 0;
  }
  .checkbox_item {
    padding: 14px 20px;
  }
  .bottom_sheet_close {
    padding: 15px 20px;
    margin-left: auto;
    font-size: 14px;
    line-height: 1.43;
    color: $color-black;
  }
  .common_btn_box {
    width: 100%;
    padding: 12px 20px;
  }
}
.bottom_sheet {
  .calendar_content {
    padding-bottom: 32px;
  }
}

