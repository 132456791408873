$old-svg-origin: (
  total-width: 269px,
  total-height: 244px,
  padding: 4px,
  imageSrc: '../img/old-svg.svg'
);

$old-svg-vars: (
  'arrow_black': (
    offset-x: -220px,
    offset-y: -25px,
    width: 16px,
    height: 16px
  ),
  'arrow_left_white': (
    offset-x: -220px,
    offset-y: -49px,
    width: 16px,
    height: 16px
  ),
  'arrow_right': (
    offset-x: -220px,
    offset-y: -73px,
    width: 16px,
    height: 16px
  ),
  'arrow_right_white': (
    offset-x: -220px,
    offset-y: -97px,
    width: 16px,
    height: 16px
  ),
  'barcode_facenter': (
    offset-x: -118px,
    offset-y: -78px,
    width: 24px,
    height: 24px
  ),
  'btn_down_box': (
    offset-x: -188px,
    offset-y: -160px,
    width: 20px,
    height: 20px
  ),
  'btn_question_outlined': (
    offset-x: -156px,
    offset-y: -128px,
    width: 20px,
    height: 20px
  ),
  'btn_refresh': (
    offset-x: -160px,
    offset-y: -188px,
    width: 17px,
    height: 16px
  ),
  'btn_retry': (
    offset-x: -185px,
    offset-y: -188px,
    width: 17px,
    height: 16px
  ),
  'btn_retry_black': (
    offset-x: -245px,
    offset-y: -144px,
    width: 12px,
    height: 14px
  ),
  'btn_share': (
    offset-x: -78px,
    offset-y: -118px,
    width: 24px,
    height: 24px
  ),
  'btn_up_box': (
    offset-x: -160px,
    offset-y: -156px,
    width: 20px,
    height: 20px
  ),
  'calendar_black': (
    offset-x: -220px,
    offset-y: -121px,
    width: 16px,
    height: 16px
  ),
  'calendar_gray': (
    offset-x: -220px,
    offset-y: -145px,
    width: 16px,
    height: 16px
  ),
  'clock': (
    offset-x: -220px,
    offset-y: -169px,
    width: 16px,
    height: 16px
  ),
  'clock_gray': (
    offset-x: -78px,
    offset-y: -78px,
    width: 32px,
    height: 32px
  ),
  'close': (
    offset-x: -110px,
    offset-y: -118px,
    width: 24px,
    height: 24px
  ),
  'close_gray': (
    offset-x: -220px,
    offset-y: -193px,
    width: 16px,
    height: 16px
  ),
  'close_white': (
    offset-x: -156px,
    offset-y: 0px,
    width: 24px,
    height: 24px
  ),
  'dot': (
    offset-x: 0px,
    offset-y: -220px,
    width: 16px,
    height: 16px
  ),
  'face': (
    offset-x: -24px,
    offset-y: -220px,
    width: 16px,
    height: 16px
  ),
  'icon_arrow_facenter_r': (
    offset-x: -48px,
    offset-y: -220px,
    width: 16px,
    height: 16px
  ),
  'icon_bank': (
    offset-x: -156px,
    offset-y: -32px,
    width: 24px,
    height: 24px
  ),
  'icon_discount_coupon': (
    offset-x: -156px,
    offset-y: -64px,
    width: 24px,
    height: 24px
  ),
  'icon_email': (
    offset-x: -72px,
    offset-y: -220px,
    width: 16px,
    height: 16px
  ),
  'icon_entrance': (
    offset-x: -156px,
    offset-y: -96px,
    width: 24px,
    height: 24px
  ),
  'icon_face': (
    offset-x: 0px,
    offset-y: 0px,
    width: 70px,
    height: 70px
  ),
  'icon_face_black': (
    offset-x: -78px,
    offset-y: 0px,
    width: 70px,
    height: 70px
  ),
  'icon_game_ticket': (
    offset-x: 0px,
    offset-y: -156px,
    width: 24px,
    height: 24px
  ),
  'icon_home': (
    offset-x: -96px,
    offset-y: -220px,
    width: 16px,
    height: 16px
  ),
  'icon_lock': (
    offset-x: 0px,
    offset-y: -78px,
    width: 70px,
    height: 70px
  ),
  'icon_membership_card': (
    offset-x: -32px,
    offset-y: -156px,
    width: 24px,
    height: 24px
  ),
  'icon_money': (
    offset-x: -64px,
    offset-y: -156px,
    width: 24px,
    height: 24px
  ),
  'icon_mycoupon': (
    offset-x: -96px,
    offset-y: -156px,
    width: 24px,
    height: 24px
  ),
  'icon_name': (
    offset-x: -120px,
    offset-y: -220px,
    width: 16px,
    height: 16px
  ),
  'icon_phone': (
    offset-x: -144px,
    offset-y: -220px,
    width: 16px,
    height: 16px
  ),
  'icon_point_star': (
    offset-x: -245px,
    offset-y: -166px,
    width: 12px,
    height: 12px
  ),
  'icon_seasoncard': (
    offset-x: -128px,
    offset-y: -156px,
    width: 24px,
    height: 24px
  ),
  'icon_smartticket': (
    offset-x: -188px,
    offset-y: 0px,
    width: 24px,
    height: 24px
  ),
  'icon_ticket_login': (
    offset-x: -188px,
    offset-y: -32px,
    width: 24px,
    height: 24px
  ),
  'info': (
    offset-x: -168px,
    offset-y: -220px,
    width: 16px,
    height: 16px
  ),
  'int_dropdown': (
    offset-x: -192px,
    offset-y: -220px,
    width: 16px,
    height: 16px
  ),
  'large_calendar': (
    offset-x: -188px,
    offset-y: -64px,
    width: 24px,
    height: 24px
  ),
  'large_clock': (
    offset-x: -188px,
    offset-y: -96px,
    width: 24px,
    height: 24px
  ),
  'large_prev': (
    offset-x: -188px,
    offset-y: -128px,
    width: 24px,
    height: 24px
  ),
  'large_quotes': (
    offset-x: 0px,
    offset-y: -188px,
    width: 24px,
    height: 24px
  ),
  'minus': (
    offset-x: -216px,
    offset-y: -220px,
    width: 16px,
    height: 16px
  ),
  'paging_end': (
    offset-x: -245px,
    offset-y: 0px,
    width: 16px,
    height: 16px
  ),
  'paging_first': (
    offset-x: -245px,
    offset-y: -24px,
    width: 16px,
    height: 16px
  ),
  'paging_next': (
    offset-x: -245px,
    offset-y: -48px,
    width: 16px,
    height: 16px
  ),
  'paging_prev': (
    offset-x: -245px,
    offset-y: -72px,
    width: 16px,
    height: 16px
  ),
  'payment': (
    offset-x: -32px,
    offset-y: -188px,
    width: 24px,
    height: 24px
  ),
  'plus': (
    offset-x: -245px,
    offset-y: -96px,
    width: 16px,
    height: 16px
  ),
  'search': (
    offset-x: -245px,
    offset-y: -120px,
    width: 16px,
    height: 16px
  ),
  'search_white': (
    offset-x: -220px,
    offset-y: 0px,
    width: 17px,
    height: 17px
  ),
  'smart_ticket': (
    offset-x: -64px,
    offset-y: -188px,
    width: 24px,
    height: 24px
  ),
  'smart_ticket_gray': (
    offset-x: -96px,
    offset-y: -188px,
    width: 24px,
    height: 24px
  ),
  'smart_ticket_white': (
    offset-x: -128px,
    offset-y: -188px,
    width: 24px,
    height: 24px
  ),
);
@function get-ratio($width, $height) {
    @return parse-int($width / $height);
}

@function get-scale($width, $new-width) {
    @return parse-int($new-width / $width);
}

@mixin useSvg-old($image, $size: false) {
  $image-origin: $old-svg-origin;
  $image-var: map-get($old-svg-vars, $image);
  $image-path: map-get($image-origin, 'imageSrc');
  $padding: map-get($old-svg-origin, 'padding');
  $aspectRatio: calc(map-get($image-var, 'height') / map-get($image-var, 'width'));
  $sizeRatio: calc($size / map-get($image-var, 'width'));

  @if($size){
    @if(unitless($size)){
      @warn '"#{$size}" of "#{$image}" is unitless value. Please insert value with unit(px)';
    }
    @else {
      width: $size;
      height: $size * $aspectRatio;
      background-image: url($image-path);
      background-size: (map-get($image-origin, 'total-width') * $sizeRatio) (map-get($image-origin, 'total-height') * $sizeRatio);
      background-position: ((map-get($image-var, 'offset-x') - $padding) * $sizeRatio) ((map-get($image-var, 'offset-y') - $padding) * $sizeRatio);
    }
  }
  @else {
    width: map-get($image-var, 'width');
    height: map-get($image-var, 'height');
    background-image: url($image-path);
    background-size: map-get($image-origin, 'total-width') map-get($image-origin, 'total-height');
    background-position: (map-get($image-var, 'offset-x') - $padding) (map-get($image-var, 'offset-y') - $padding);
  }
  background-repeat: no-repeat;
}
