.m_toolbar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  @include safeArea(false, padding, bottom);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: $color-white;
  box-shadow: 0 -4px 14px 0 rgba(26, 41, 69, 0.05);
  z-index: map-get($z-index, nav-fixed);
  transition: transform 0.2s;
  &.is-hide {
    transform: translateY(100%);
  }
}
.toolbar_menu {
  display: flex;
  justify-content: space-around;
}
.toolbar_item {
  display: flex;
  flex: 1;
  align-items: center;
  height: 60px;
}
.toolbar_link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: $color-black;
  &.is-active {
    color: $color-primary;
  }
  .icon_toolbar {
    width: 24px;
    height: 24px;
  }
  .toolbar_text {
    display: inline-block;
    margin: 4px 0 7px;
    font-size: 9px;
    line-height: 1;
    vertical-align: top;
  }
}
.toolbar_util {
  position: absolute;
  right: 20px;
  @include safeArea(false, bottom, 76px bottom);
  width: 40px;
  .toolbar_refresh,
  .toolbar_top {
    position: relative;
    width: 40px;
    height: 40px;
    margin-top: 14px;
    border: solid 1px map-get($color-line, line-01);
    border-radius: 50%;
    background: rgba($color: $color-white, $alpha: 0.95);
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
    }
  }
}
.toolbar_refresh {
  &::before {
    @include useSvg-common('refresh', 24px);
  }
}
.toolbar_top {
  &::before {
    @include useSvg-common('move_top', 24px);
  }
}