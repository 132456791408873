// common data none
.common_data_none {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 0 20px;
  font-size: 13px;
  line-height: 1.38;
  color: $color-gray;
  text-align: center;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  .text_black {
    color: $color-black;
  }
  &.type_icon {
    &::before {
      display: block;
      margin: 0 auto 12px;
      @include useSvg-common('error', 70px);
      content: '';
    }
  }
}

// common error
.page_error {
}
.common_error {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 500px;
  padding: 0 20px;
  line-height: 0;
  text-align: center;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  .error_icon {
    display: inline-block;
    @include useSvg-common('error', 70px);
  }
  .error_info {
    margin-top: 30px;
    letter-spacing: -0.04em;
  }
  .error_title {
    font-size: 15px;
    line-height: 1.4;
    color: $color-black;
  }
  .error_desc {
    margin-top: 15px;
    font-size: 14px;
    line-height: 1.36;
    color: $color-gray;
    .text_emphasis {
      font-weight: 500;
      color: $color-black;
    }
  }
  .common_btn_box {
    margin-top: 30px;
    .common_btn {
      &:only-child {
        flex: none;
        min-width: 180px;
        margin: 0 auto;
      }
    }
  }
}

// 얼굴입장
.face_error_wrap{
  width: 100%;
  line-height: 0;
  text-align: center;
  box-sizing: border-box;
  background-color: #fff;
  padding: 135px 20px 30px;
  .error_icon {
    display: inline-block;
    @include useSvg-common('error', 70px);
    margin-bottom: 30px;
  }
  .error_info {
    margin-top: 30px;
    letter-spacing: -0.04em;
    h3{
      padding-bottom: 15px;
    }
  }
  .error_title {
    font-size: 15px;
    line-height: 1.4;
    color: $color-black;
  }
  .error_desc {
    margin-top: 15px;
    font-size: 14px;
    line-height: 1.36;
    color: $color-gray;
    .text_emphasis {
      font-weight: 500;
      color: $color-black;
    }
  }
  .common_btn_box {
    margin-top: 30px;
    .common_btn {
      &:only-child {
        flex: none;
        min-width: 180px;
        margin: 0 auto;
      }
    }
  }
}