.page_smart {

}
.section_smart_ticket {
  margin-top: 12px;
}
.smart_ticket_list {
  padding: 0 20px;
}
.smart_ticket_item {
  & + & {
    margin-top: 8px;
  }
  .smart_ticket_link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 15px 0 10px;
    border-radius: 10px;
    background: $color-white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    &::before {
      position: absolute;
      top: 50%;
      right: 0;
      width: 6px;
      height: 12px;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      box-shadow: 0 2px 10px 10px rgba(0, 0, 0, 0.05) inset;
      transform: translateY(-50%);
      content: '';
    }
  }
  .smart_ticket_imgbox {
    position: relative;
    overflow: hidden;
    margin: 10px 10px 10px 0;
    width: 128px;
    height: 70px;
    border-radius: 6px;
    background: map-get($color-background, bg-01);
  }
  .smart_ticket_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .smart_ticket_flags {
    display: flex;
    gap: 0 3px;
    align-items: center;
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
  .smart_ticket_sheet {
    display: inline-flex;
    padding: 3px 6px;
    border-radius: 16px;
    background: rgba(36, 36, 40, 0.8);
    font-size: 10px;
    line-height: 1;
    color: $color-white;
    text-align: center;
  }
  .smart_ticket_info {
    flex: 1;
  }
  .smart_ticket_title {
    @include multi-ellipsis(2);
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 1.43;
    color: $color-black;
  }
  .smart_ticket_date,
  .smart_ticket_place {
    @include multi-ellipsis(1);
    font-size: 12px;
    line-height: 1.33;
    color: map-get($color-type, gray-01);
  }
}
.btn_reserve_history {
  display: flex;
  align-items: center;
  position: fixed;
  left: 50%;
  @include safeArea(false, bottom, 76px bottom);
  padding: 10px 16px;
  border-radius: 40px;
  background: $color-black;
  font-size: 14px;
  line-height: 1.43;
  transform: translateX(-50%);
  color: $color-white;
  transition: bottom 0.2s;
  &::before {
    margin-right: 6px;
    @include useSvg-common('list_16_white', 16px);
    content: '';
  }
  .m_toolbar.is-hide ~ .m_container & {
    @include safeArea(false, bottom, 16px bottom);
  }
}