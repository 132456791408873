.page_mypage {
}
.section_mypage_login {
  padding: 20px 20px 16px;
}
.section_mypage_user {
  padding: 15px 15px 12px 20px;
}
.mypage_login, 
.mypage_user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .mypage_login_text,
  .mypage_user_account {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
  }
  .btn_alarm_gate {
    position: relative;
    padding: 5px;
    &::before {
      display: block;
      @include useSvg-common('alarm', 24px);
      content: '';
    }
    .alarm_new {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background: $color-primary;
    }
  }
}
.section_mypage_own {
  padding: 0 20px;
}
.mypage_own_list {
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  background: map-get($color-type, black-02);
  color: $color-white;
}
.mypage_own_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 16px;
  width: 50%;
  box-sizing: border-box;
  &:nth-last-child(odd):not(.payco) {
    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 1px;
      height: 20px;
      background: $color-white;
      transform: translateY(-50%);
      opacity: 0.1;
      content: '';
    }
  }
  &.payco {
    padding: 17px 16px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: $color-black;
    .mypage_own_number {
      font-size: 24px;
      line-height: 1.5;
      color: $color-primary;
    }
  }
  .mypage_own_text {
    font-size: 13px;
    line-height: 1.38;
  }
  .mypage_own_number {
    @include font-family('Roboto');
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
  }
}
.section_mypage_info {
  margin-top: 8px;
  padding: 0 20px;
}
.mypage_group {
  padding: 12px 0;
  & + & {
    border-top: 1px solid map-get($color-line, line-01);
  }
}
.mypage_item {
  .mypage_link {
    display: flex;
    align-items: center;
    position: relative;
    padding: 12px 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: $color-black;
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      @include useSvg-common('arrow_right', 16px);
      transform: translateY(-50%);
      content: '';
    }
  }
  .count {
    margin: 1px 0 0 2px;
    padding: 1px 6px;
    border: 1px solid $color-primary;
    border-radius: 16px;
    @include font-family('Roboto');
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    color: $color-primary;
    box-sizing: border-box;
  }
  .mypage_item_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
  }
  .mypage_item_title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: $color-black;
  }
  .mypage_item_desc {
    font-size: 13px;
    line-height: 1.38;
    color: map-get($color-type, gray-01);
  }
  .mypage_item_radio {
    margin: 4px 0 12px;
   .radio_item + .radio_item {
     margin-top: 14px;
   }
   .label_radio {
     display: block;
     padding: 2px 0 2px 0;
     font-size: 13px;
     line-height: 1.38;
     &::before {
       left: auto;
       right: 0;
     }
   }
  }
}
.page_login {

}
.section_login {
  margin: 66px 20px 0;
  .m_header ~ .m_container & {
    margin-top: 12px;
    padding-top: 40px;
    border-top: 1px solid $color-black;
  }
}
.login_payco_logo {
  margin: 0 auto;
  width: 98px;
  height: 22px;
  background: url(../img/svg/common/logo_payco.svg) center / 100% no-repeat;
}
.login_desc {
  margin-top: 12px;
  font-size: 13px;
  line-height: 1.38;
  color: $color-gray;
  text-align: center;
}
.login_way_group {
  margin-top: 24px;
}
.login_way_or {
  position: relative;
  margin: 20px 0;
  line-height: 0;
  text-align: center;
  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: map-get($color-line, line-01);
    transform: translateY(-50%);
    content: '';
  }
  .text {
    position: relative;
    padding: 0 8px;
    background: $color-white;
    font-size: 12px;
    line-height: 1.33;
    color: map-get($color-type, gray-02);
    z-index: 1;
  }
}
.login_app_info {
  position: absolute;
  left: 50%;
  @include safeArea(false, bottom, 34px bottom);
  color: map-get($color-type, gray-01);
  text-align: center;
  transform: translateX(-50%);
  .btn_hyperlink {
    color: map-get($color-type, gray-01);
    &::after {
      margin-left: 5px;
      border-color: map-get($color-type, gray-01);;
    }
  }
  .login_app_version {
    display: block;
    margin-top: 3px;
    font-size: 12px;
    line-height: 1.33;
  }
}
.page_alarm {

}
.section_alarm_list {
  margin-top: 24px;
  > .alarm_list {
    padding: 0 20px;
    .alarm_item {
      border-bottom: 1px solid map-get($color-line, line-01);
    }
    .alarm_link {
      display: block;
      padding: 18px 0;
    }
    .alarm_new_wrap {
      position: relative;
      .alarm_new {
        position: absolute;
        right: 0;
        top: 50%;
        display: block;
        width: 8px;
        height: 8px;
        margin-top: -4px;
        border-radius: 8px;
        background: $color-primary;
      }
    }
    .alarm_title {
      @include multi-ellipsis(1);
      font-size: 14px;
      line-height: 1.428;
      color: $color-black;
      letter-spacing: -0.04em;
    }
    .alarm_message {
      @include multi-ellipsis(2);
      margin-top: 2px;
      font-size: 12px;
      line-height: 1.5;
      color: map-get($color-type, gray-01);
    }
    .alarm_date {
      @include font-family('Roboto');
      margin-top: 3px;
      font-size: 11px;
      font-weight: 400;
      line-height: 1.455;
      color: map-get($color-type, gray-01);
    }    
  }
  &.type_img {
    margin-top: 32px;
  }
  .alarm_date_group {
    padding: 0 20px;
    .alarm_date {
      @include font-family('Roboto');
      margin-top: 12px;
      font-size: 11px;
      font-weight: 400;
      line-height: 1.455;
      color: map-get($color-type, gray-01);
    }
    .alarm_item {
      border-bottom: 1px solid map-get($color-line, line-01);
      &:first-child {
        .alarm_link {
          padding-top: 6px;
        }
      }
    }
    .alarm_link {
      display: flex;
      align-items: center;
      padding: 12px 0;
    }
    .alarm_imgbox {
      flex: none;
      overflow: hidden;
      position: relative;
      width: 50px;
      height: 61px;
      margin-right: 16px;
      border-radius: 6px;
      background: map-get($color-background, bg-01);
      @include img-border;
      .alarm_img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .alarm_new {
      display: block;
      width: 6px;
      height: 6px;
      margin-bottom: 2px;
      border-radius: 6px;
      background: $color-primary;
    }
    .alarm_title {
      @include multi-ellipsis(1);
      font-size: 14px;
      line-height: 1.428;
      color: $color-black;
      letter-spacing: -0.04em;
    }
    .alarm_message {
      @include multi-ellipsis(2);
      margin-top: 2px;
      font-size: 12px;
      line-height: 1.5;
      color: map-get($color-type, gray-01);
    }
  }
}
.section_alarm_setting {
  margin-top: 33px;
}
.alarm_setting_system {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 60px;
  .text {
    flex: 1;
    font-size: 13px;
    line-height: 1.385;
    color: $color-black;
  }
  .common_btn {
    margin-left: 10px;
  }
}
.alarm_setting {
  padding: 0 20px;
  .switch_item + .switch_item {
    margin-top: 20px;
  }
}
.page_login_team {
  background: map-get($color-background, bg-02);
  @include safeArea(false, padding, 80px bottom);
}
.top_notice {
  display: flex;
  padding: 12px 20px;
  background: map-get($color-background, bg-02);
  &.type_center {
    text-align: center;
  }
}
.top_notice_icon {
  flex: none;
  margin-right: 4px;
  @include useSvg-common('info', 16px);
}
.top_notice_desc {
  display: inline-block;
  flex: 1;
  font-size: 13px;
  line-height: 1.38;
  color: $color-gray;
  .text_emphasis {
    font-weight: 700;
  }
  .page_withdraw &,
  .page_login_team & {
    font-size: 11px;
    line-height: 1.36;
    letter-spacing: -0.04em;
  }
}
.team_login_title {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
}
.team_sport_choice {
  padding: 16px 20px;
  border-top: 1px solid map-get($color-line, line-01);
  background: $color-white;
  &:last-child {
    border-bottom: 1px solid map-get($color-line, line-01);
  }
  .team_group {
    font-size: 0;
  }
  .radio_item {
    display: inline-block;
    margin-top: 10px;
    width: 50%;
    vertical-align: top;
    .label_radio {
      padding-left: 26px;
      font-size: 12px;
      letter-spacing: -0.04em;
    }
  }
}
.section_team_login {
  margin-top: 5px;
  .common_btn_box {
    margin-top: 20px;
    padding: 0 20px;
  }
}
.team_login_form {
  .team_login_input {
    display: block;
    width: 100%;
    padding: 16px 20px;
    border-top: 1px solid map-get($color-line, line-01);
    background: $color-white;
    font-size: 14px;
    line-height: 1.43;
    color: $color-black;
    box-sizing: border-box;
    &::placeholder {
      color: map-get($color-type, gray-02);
    }
    &:last-child {
      border-bottom: 1px solid map-get($color-line, line-01);
    }
  }
  .checkbox_item {
    margin-top: 10px;
    padding: 0 20px;
    .label_checkbox {
      padding-left: 26px;
      font-size: 12px;
      color: $color-gray;
      letter-spacing: -0.04em;
    }
  }
}
.team_login_agree {
  margin-top: 32px;
  .team_login_title {
    margin-bottom: 10px;
    padding: 0 20px;
  }
  .team_agree_item {
    padding: 14px 20px;
    border-top: 1px solid map-get($color-line, line-01);
    font-size: 0;
    &:last-child {
      border-bottom: 1px solid map-get($color-line, line-01);
    }
  }
  .checkbox_item {
    display: inline-block;
    vertical-align: middle;
    .label_checkbox {
      padding-left: 30px;
      font-size: 12px;
      color: $color-gray;
      letter-spacing: -0.04em;
    }
  }
  .btn_question_box {
    margin-left: 5px;
  }
}
.page_advance_ticket {
  background: map-get($color-background, bg-02);
  .common_data_none {
    position: static;
    margin: 100px 0;
    color: map-get($color-type, gray-01);
    transform: none;
  }
}
.advance_ticket_register {
  margin-top: 12px;
  padding: 20px;
  border: 1px solid map-get($color-line, line-01);
  border-width: 1px 0;
  background: $color-white;
  .advance_ticket_title {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: $color-black;
  }
  .ticket_register_form {
    display: flex;
    margin-top: 14px;
    .ticket_register_box {
      position: relative;
      flex: 1;
    }
    .ticket_register_input {
      padding: 0 36px 0 10px;
      width: 100%;
      height: 36px;
      border: 1px solid map-get($color-type, gray-03);
      border-radius: 6px;
      font-size: 13px;
      line-height: 1.38;
      color: $color-black;
      box-sizing: border-box;
      &::placeholder {
        color: map-get($color-type, gray-02);
      }
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        appearance: none;
      }
    }
    .ticket_register_clear {
      position: absolute;
      top: 10px;
      right: 10px;
      @include useSvg-common('close_gray', 16px);
    }
    .common_btn {
      margin-left: 7px;
      width: 84px;
      height: 36px;
    }
  }
}
.bottom_notice {
  padding: 0 20px;
  .bottom_notice_title {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.15;
    color: $color-gray;
    &::before {
      display: inline-block;
      margin-right: 5px;
      @include useSvg-common('info', 16px);
      vertical-align: middle;
      content: '';
    }
  }
  .bottom_notice_list {
    padding-left: 13px;
  }
  .bottom_notice_item {
    position: relative;
    padding-left: 5px;
    font-size: 11px;
    line-height: 1.82;
    color: map-get($color-type, gray-01);
    &::before {
      position: absolute;
      top: 9px;
      left: 0;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: map-get($color-type, gray-01);
      content: '';
    }
  }
}
.advance_ticket_list {
  padding: 12px 20px 30px;
}
.advance_ticket_item {
  & + & {
    margin-top: 7px;
  }
  .advance_ticket_link {
    display: block;
    padding: 13px;
    border: 1px solid map-get($color-line, line-01);
    background: $color-white;
  }
  .ticket_info_item {
    display: flex;
    font-size: 12px;
    line-height: 1.58;
    & + .ticket_info_item {
      margin-top: 3px;
    }
  }
  .ticket_info_title {
    width: 112px;
    color: map-get($color-type, gray-01);
  }
  .ticket_info_desc {
    flex: 1;
    color: $color-gray;
    .text_number {
      @include font-family('Roboto');
    }
  }
}
.member_card {
  border-top: 1px solid map-get($color-line, line-01);
  &::before {
    display: block;
    width: 100%;
    height: 12px;
    background: map-get($color-background, bg-02);
    content: '';
  }
}
.member_card_heading {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  .card_emblem_imgbox {
    flex: none;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    .card_emblem_img {
      width: 100%;
      height: 100%;
      vertical-align: top;
    }
  }
  .member_card_title {
    @include multi-ellipsis(1);
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -0.04em;
    color: $color-black;
  }
}
.member_card_info {
  border-top: 1px solid map-get($color-line, line-01);
  padding: 12px 20px;
  .card_info_item {
    display: flex;
    font-size: 13px;
    line-height: 1.46;
    & + .card_info_item {
      margin-top: 7px;
    }
  }
  .card_info_title {
    width: 90px;
    color: map-get($color-type, gray-01);
  }
  .card_info_desc {
    flex: 1;
    color: $color-black;
  }
}
.member_card_barcode {
  padding: 18px 0;
  border-top: 1px solid map-get($color-line, line-01);
  .card_barcode_time {
    font-size:0;
    text-align: center;
    &::before {
      display: inline-block;
      margin-right: 5px;
      @include useSvg-common('clock', 16px);
      vertical-align: middle;
      content: '';
    }
    .barcode_time {
      @include font-family('Roboto');
      font-size: 13px;
      font-weight: 700;
      line-height: 1.38;
      vertical-align: middle;
    }
  }
  .payco_live_band {
    margin-top: 16px;
  }
  .card_barcode {
    margin-top: 22px;
    .card_barcode_imgbox {
      overflow: hidden;
      margin: 0 auto;
      width: 220px;
      height: 129px;
      vertical-align: top;
    }
    .card_barcode_img {
      width: 100%;
      vertical-align: top;
    }
    .card_barcode_name {
      display: block;
      margin-top: 15px;
      padding: 0 25px;
      font-size: 13px;
      font-weight: 700;
      line-height: 1.46;
      text-align: center;
      color: $color-gray;
    }
  }
}
.payco_live_band {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 24px;
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% + 54px);
    height: 24px;
    background: url(../img/svg/common/logo_payco_band.svg) 0 0 / 54px 24px repeat-x #fa2828;
    animation: payco_live_band 3s linear infinite;
    content: '';
  }
}
@keyframes payco_live_band {
  0% {
    transform: translate(-54px);
  }
  100% {
    transform: translate(0);
  }
}
.page_withdraw {
  background: map-get($color-background, bg-02);
}
.section_unused_reserve {
  border-top: 1px solid map-get($color-line, line-01);
  .top_notice {
    padding: 16px 20px;
  }
}
.unused_reserve {
  margin: 0 20px;
  border: 1px solid map-get($color-line, line-01);
  border-radius: 4px;
}
.unused_reserve_ticket {
  background: $color-white;
}
.unused_reserve_notice {
  background: map-get($color-line, line-01);
}
.unused_reserve_list {
  padding: 17px 12px;
}
.unused_reserve_item {
  position: relative;
  padding-left: 10px;
  font-size: 11px;
  line-height: 1.36;
  color: $color-gray;
  letter-spacing: -0.04em;
  &::before {
    position: absolute;
    top: 8px;
    left: 0;
    width: 3px;
    height: 1px;
    background: $color-gray;
    content: '';
  }
  & + & {
    margin-top: 3px;
  }
}
.section_withdraw_form {
  padding: 0 20px;
}
.withdraw_notice {
  display: flex;
  align-items: center;
  margin-top: 4px;
  padding: 8px 0;
  &::before {
    margin-right: 4px;
    @include useSvg-common('info', 16px);
    content: '';
  }
  .withdraw_notice_desc {
    font-size: 11px;
    line-height: 1.36;
    color: $color-gray;
    letter-spacing: -0.04em;
  }
  .common_btn {
    margin-left: auto;
    padding: 0 7px;
    height: 24px;
    border-color: map-get($color-type, gray-01);
    background: map-get($color-type, gray-01);
    font-size: 10px;
    color: $color-white;
  }
}
.withdraw_form {
  margin-top: 14px;
  .label_checkbox {
    font-size: 12px;
    color: $color-gray;
  }
  .common_btn_box {
    margin-top: 20px;
  }
}

// trip
.mypage_trip , .reserve_trip_detail{
  .product_manager_info {
    display: flex;
    align-items: center;
    margin: 20px;
    .manager_profile {
      margin-left: 20px;
      font-size: 12px;
      line-height: 18px;
    }
    .manager_name {
      display: block;
      dt, dd {
        display: inline-block;
      }
      dd {
        font-weight: 700;
      }
    }
    .manager_phone, .manager_mail {
      display: inline-block;
      @include font-family('Roboto');
      letter-spacing: 0;
      color:#62676C;
    }
    .manager_mail {
      margin-left: 10px;
    }
  }
  .product_manager_img {
    width: 52px;
    height: 52px;
    border-radius: 100%;
  }
  .rdo_box {
    .item_payco_point {
      .inp_rdo {
        label {
          width: 52px;
          height: 20px;
          color: $color-primary;
          background-position: -111px -880px;
        }
        .logo_payco {
          display: inline-block;
          width: 98px;
          height: 22px;
          font-size: 0;
          background: url(../img/svg/common/logo_payco.svg) center / 100% no-repeat;
        }
      }
    }
  }
}