.common_btn_box {
  display: flex;
  .common_btn {
    flex: 1;
    margin: 0 3px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.common_btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 0 10px;
  border-radius: 6px;
  font-size: 13px;
  line-height: 1.38;
  text-align: center;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &.btn_primary {
    border: 1px solid $color-primary;
    background-color: $color-primary;
    color: $color-white;
    &:hover,
    &:focus {
      border: 1px solid map-get($color-type, red-01);
      background-color: map-get($color-type, red-01);
    }
    &:disabled,
    &[aria-disabled=true] {
      border: 1px solid map-get($color-background, bg-04);
      background-color: map-get($color-background, bg-04);
      color: map-get($color-type, gray-02);
    }
    &[aria-disabled=true] {
      pointer-events: none;
    }
    &.plan_sale {
      &:disabled,
      &[aria-disabled=true] {
        color: $color-black;
      }
    }
  }
  &.btn_secondary {
    border: 1px solid $color-black;
    background-color: $color-black;
    color: $color-white;
    &:hover,
    &:focus {
      border: 1px solid map-get($color-type, black-01);
      background-color: map-get($color-type, black-01);
    }
    &:disabled,
    &[aria-disabled=true] {
      border: 1px solid map-get($color-background, bg-04);
      background-color: map-get($color-background, bg-04);
      color: map-get($color-type, gray-02);
    }
    &[aria-disabled=true] {
      pointer-events: none;
    }
    &:first-child:last-child>span>br{ display:none }
    &:not(:first-child):last-child {
      >span {
        font-size: 14px;
        line-height: 18px;
        >br { display:block }
      }
    }
  }
  &.btn_ghost {
    border: 1px solid map-get($color-line, line-04);
    background-color: $color-white;
    color: $color-black;
    &:hover,
    &:focus {
      border-color: $color-black;
      background-color: map-get($color-background, bg-01);
    }
    &:disabled,
    &[aria-disabled=true] {
      border-color: map-get($color-line, line-04);
      background-color: $color-white;
      color: map-get($color-type, gray-02);
    }
    &[aria-disabled=true] {
      pointer-events: none;
    }
  }
  &.btn_ghost_secondary {
    border: 1px solid $color-black;
    background-color: $color-white;
    color: $color-black;
    &:hover,
    &:focus {
      border-color: $color-black;
      background-color: map-get($color-background, bg-01);
    }
    &:disabled,
    &[aria-disabled=true] {
      border-color: map-get($color-line, line-04);
      background-color: $color-white;
      color: map-get($color-type, gray-02);
    }
    &[aria-disabled=true] {
      pointer-events: none;
    }
  }
  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    border: 1px solid map-get($color-background, bg-04);
    background-color: map-get($color-background, bg-04);
    color: $color-black;
    &:disabled,
    &[aria-disabled=true] {
      color: map-get($color-type, gray-02);
      pointer-events: none;
    }
  }
  &.btn_small {
    height: 30px;
    font-size: 13px;
    line-height: 1.38;
  }
  &.btn_medium {
    height: 42px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
  }
  &.btn_large {
    height: 52px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.06em;
  }
}
.btn_hyperlink {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.33;
  color: $color-gray;
  &::after {
    flex: none;
    width: 5px;
    height: 5px;
    margin-left: 3px;
    border: 1px solid $color-gray;
    border-width: 1px 1px 0 0;
    transform: rotate(45deg);
    content: '';
  }
  &:hover,
  &:focus {
    color: $color-black;
    &::after {
      border-color: $color-black;
    }
  }
  &:disabled,
  &[aria-disabled=true] {
    color: map-get($color-type, gray-02);
    pointer-events: none;
    &::after {
      border-color: map-get($color-type, gray-02);
    }
  }
}
.dropdown_wrap {
  font-size: 0;
  .dropdown_title {
    display: inline-block;
    position: relative;
    margin-right: 2px;
    padding: 8px 10px 8px 6px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.33;
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      height: 12px;
      background: map-get($color-type, gray-01);
      transform: translateY(-50%);
      content: '';
    }
  }
}
.btn_dropdown {
  position: relative;
  padding: 8px 16px 8px 6px;
  font-size: 12px;
  line-height: 1.33;
  color: $color-black;
  &::after {
    position: absolute;
    top: 50%;
    right: 4px;
    margin-top: -2px;
    border-style: solid;
    border-color: transparent;
    border-top-color: $color-gray;
    border-width: 4.5px 3.5px;
    content: '';
  }
}
.btn_sort {
  position: relative;
  padding: 8px 16px 8px 6px;
  font-size: 12px;
  line-height: 1.33;
  color: $color-black;
  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -8px;
    @include useSvg-common('sort', 16px);
    content: '';
  }
}
.btn_coupon_info {
  &::after {
    margin-left: 2px;
    @include useSvg-common('download', 16px);
    content: '';
  }
}
