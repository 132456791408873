body {
  background: #fff;
  &.no-scroll {
    overflow: hidden;
  }
}
.m_wrap {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.m_container {
  flex: 1;
  @include safeArea(false, padding, 120px bottom);
  .m_header ~ & {
    padding-top: 52px;
  }
}