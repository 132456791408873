.popup_btn_wrap {
  .btn_tooltip {
    position: relative;
    &[aria-expanded="true"] {
      & + .common_tooltip_wrap {
        display: block;
      }
      .icon_tooltip_arrow {
        position: absolute;
        bottom: -10px;
        left: 50%;
        width: 17px;
        height: 8px;
        transform: translateX(-50%);
        z-index: 51;
        &::before,
        &::after {
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 0;
          border: 8px solid transparent;
          border-bottom-color: $color-black;
          border-width: 8px 8.5px;
          content: '';
        }
        &::after {
          bottom: -2px;
          border-bottom-color: $color-white;
        }
      }
    }
  }
}

.tooltip_btn_wrap {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  vertical-align: top;
}
.btn_tooltip {
  display: inline-flex;
  vertical-align: top;
  &::after {
    display: block;
    @include useSvg-common('info', 16px);
    content: '';
  }
  &[aria-expanded=true] + .common_tooltip_wrap {
    display: block;
  }
}
.common_tooltip {
  &_wrap {
    display: none;
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    min-width: 320px;
    width: 100%;
    z-index: map-get($z-index, tooltip);
    transform: translateX(-50%);
    .common_tooltip {
      border: 1px solid $color-black;
      border-radius: 2px;
      box-sizing: border-box;
      background: $color-white;
    }
  }
  &_content {
    position: relative;
    min-height: 58px;
    font-size: 13px;
    line-height: 1.46;
    color: $color-gray;
    letter-spacing: -0.04em;
    box-sizing: border-box;
  }
  &_title {
    display: block;
    margin: 0 20px;
    padding: 17px 0;
    border-bottom: 1px solid map-get($color-line, line-01);
    color: $color-black;
    font-size: 15px;
    line-height: 20px;
  }
  &_text {
    display: block;
    padding: 18px 20px;
    color: $color-gray;
    font-size: 13px;
    line-height: 20px;
  }
  &_close {
    position: absolute;
    top: 7px;
    right: 10px;
    padding: 10px;
    &::before {
      display: block;
      margin: 0 auto;
      @include useSvg-common('close', 24px);
      content: '';
    }
  }
}