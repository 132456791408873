.checkbox_item,
.radio_item,
.switch_item {
  display: block;
  font-size: 0;
  line-height: 0;
}
.input_checkbox,
.input_radio,
.input_switch {
  @include blind;
}
.label_checkbox, 
.label_radio {
  position: relative;
  display: inline-block;
  padding-left: 32px;
  font-size: 14px;
  line-height: 22px;
  color: $color-black;
  vertical-align: middle;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    border: 1px solid map-get($color-type, gray-01);
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $color-white;
    box-sizing: border-box;
    content: '';
    .input_checkbox:checked + &,
    .input_radio:checked + & {
      border-color: $color-black;
      background-color: $color-black;
    }
    .input_checkbox:disabled + &,
    .input_radio:disabled + & {
      border-color: map-get($color-line, line-04);
      background-color: map-get($color-background, bg-01);
    }
  }
  .input_checkbox:disabled + &,
  .input_radio:disabled + & {
    color: map-get($color-type, gray-02);
  }
}
.label_checkbox {
  &::before {
    .input_checkbox:checked + & {
      background-size: 12px 10px;
      background-image: svg-load('../../img/svg/common/checkbox_inner.svg', fill=$color-white);
    }
    .input_checkbox:checked:disabled + & {
      background-image: svg-load('../../img/svg/common/checkbox_inner.svg', fill=map-get($color-type, gray-02));
    }
  }  
}
.label_radio {
  &::before {
    .input_radio:checked + & {
      background-size: 10px 10px;
      background-image: svg-load('../../img/svg/common/radio_inner.svg', fill=$color-white);
    }
    .input_radio:checked:disabled + & {
      background-image: svg-load('../../img/svg/common/radio_inner.svg', fill=map-get($color-type, gray-02));
    }
  }  
}
.input_switch {
  &:checked + .label_switch {
    .switch_shape {
      background-color: $color-black;
      &::after {
        left: 24px;
      }
    }
  }
  &:disabled + .label_switch {
    .switch_text {
      color: map-get($color-type, gray-02);
    }
    .switch_shape {
      &::after {
        background-color: map-get($color-background, bg-01);
      }
    }
  }
}
.label_switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.switch_text {
  flex: 1;
  font-size: 13px;
  line-height: 1.615;
  color: $color-black;
}
.switch_shape {
  position: relative;
  width: 50px;
  height: 28px;
  margin-left: 10px;
  border-radius: 28px;
  background-color: map-get($color-background, bg-03);
  &::after {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: $color-white;
    content: '';
  }
}
.input_caption {
  font-size: 0;
  line-height: 0;
  .checkbox_item + & {
    padding-left: 32px;
  }
  .input_caption_desc {
    display: inline-block;
    font-size: 14px;
    line-height: 22px;
    color: $color-black;
    vertical-align: middle;
  }
}
.btn_question_box {
  margin-left: 7px;
  width: 20px;
  height: 20px;
  border: 1px solid map-get($color-line, line-04);
  background: $color-white;
  line-height: 0;
  vertical-align: middle;
  &::before {
    display: inline-block;
    @include useSvg-common('question_mark', 16px);
    vertical-align: middle;
    content: '';
  }
}
.common_select_wrap {
  font-size: 0;
}
.common_select_label {
  display: inline-block;
  position: relative;
  margin-right: 2px;
  padding: 8px 10px 8px 6px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.33;
  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 12px;
    background: map-get($color-type, gray-01);
    transform: translateY(-50%);
    content: '';
  }
  &.blind {
    position: absolute;
    padding: 0;
  }
}
.common_select_box {
  display: inline-block;
  position: relative;
  &::after {
    position: absolute;
    top: 50%;
    right: 4px;
    margin-top: -2px;
    border-style: solid;
    border-color: $color-black transparent transparent transparent;
    border-width: 4.5px 3.5px;
    content: '';
  }
  .common_select {
    padding: 8px 16px 8px 6px;
    font-size: 12px;
    line-height: 1.33;
    color: $color-black;
  }
}
.input_number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  height: 40px;
  margin-left: auto;
  padding: 10px 0;
  border: 1px solid map-get($color-type, gray-03);
  border-radius: 6px;
  box-sizing: border-box;
  .common_input {
    width: 40px;
    @include font-family('Roboto');
    text-align: center;
    &:disabled {
      color: map-get($color-type, gray-02);
    }
  }
}
.input_number_btn {
  position: relative;
  width: 30px;
  height: 40px;
  font-size: 0;
  &.btn_minus {
    border-radius: 6px 0 0 6px;
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 11px;
      height: 1px;
      background-color: $color-black;
      content: '';
    }
  }
  &.btn_plus {
    border-radius: 0 6px 6px 0;
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 11px;
      height: 1px;
      background-color: $color-black;
      content: '';
    }
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 1px;
      height: 11px;
      background-color: $color-black;
      content: '';
    }
  }
  &:disabled {
    &::before, &::after {
      background-color: map-get($color-type, gray-02);
    }
  }
}