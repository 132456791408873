// 플랫폼 개편용 Old Sprite
// 추후 운영하며 각 scss 안으로 흡수될 예정
.bx_input_rdo.type2 input[type='radio'] + label:before,
.team_reserve input[type='checkbox'],
.chkbx input[type="checkbox"],
.rdobx input[type="radio"],
.inp_chk input[type="checkbox"],
.inp_rdo input[type="radio"],
.radio_box .ipt_radio,
.radio_box input[type="radio"],
.btn_select_all:before,
.personal_info_area input[type="checkbox"],
.place .bx_input input[type="checkbox"] {
  width: 22px;
  height: 22px;
  border: 1px solid map-get($color-type, gray-01);
  border-radius: 50%;
  background-color: $color-white;
  box-sizing: border-box;
}
.chkbx input[type="checkbox"]:disabled,
.rdobx input[type="radio"]:disabled,
.inp_chk.disabled input[type="checkbox"],
.inp_rdo.disabled input[type="radio"],
.radio_box.disabled .ipt_radio,
.radio_box.disabled input[type="radio"],
.btn_select_all.is_selected:before {
  border-color: map-get($color-line, line-04);
  background-color: map-get($color-background, bg-01);
}
.team_reserve input[type='checkbox']:checked,
.chkbx input[type="checkbox"]:checked,
.inp_chk.checked input[type="checkbox"],
.place .bx_input input[type="checkbox"]:checked {
  border-color: $color-black;
  background-color: $color-black;
  background-position: center;
  background-size: 12px 10px;
  background-repeat: no-repeat;
  background-image: svg-load('../../img/svg/common/checkbox_inner.svg', fill=$color-white);
}
.chkbx input[type="checkbox"]:checked:disabled,
.inp_chk.checked.disabled input[type="checkbox"] {
  border-color: map-get($color-line, line-04);
  background-color: map-get($color-background, bg-01);
  background-image: svg-load('../../img/svg/common/checkbox_inner.svg', fill=map-get($color-type, gray-02));
}
.bx_input_rdo.type2 input[type='radio']:checked + label:before,
.rdobx input[type="radio"]:checked,
.inp_rdo.checked input[type="radio"],
.radio_box .ipt_radio:checked,
.radio_box input[type="radio"]:checked
.radio_box .ipt_radio:checked,
.radio_box input[type="radio"]:checked,
.btn_select_all.is_selected:before {
  border-color: $color-black;
  background-color: $color-black;
  background-position: center;
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-image: svg-load('../../img/svg/common/radio_inner.svg', fill=$color-white);
}
.rdobx input[type="radio"]:checked:disabled,
.inp_rdo.checked.disabled input[type="radio"],
.radio_box.checked.disabled .ipt_radio,
.radio_box.checked.disabled input[type="radio"] {
  border-color: map-get($color-line, line-04);
  background-color: map-get($color-background, bg-01);
  background-image: svg-load('../../img/svg/common/radio_inner.svg', fill=map-get($color-type, gray-02));
}
.paging a.first,
.paging a.prev,
.paging a.next,
.paging a.end {
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
  }
}
.paging a.first {
  &::before {
    @include useSvg-old('paging_first', 16px);
  }
}
.paging a.prev {
  &::before {
    @include useSvg-old('paging_prev', 16px);
  }
}
.paging a.next {
  &::before {
    @include useSvg-old('paging_next', 16px);
  }
}
.paging a.end {
  &::before {
    @include useSvg-old('paging_end', 16px);
  }
}

.layer_main_pop_wrap  .main_pop_item .link {
  background: url(../img/svg/common/img_default.svg) center / 140px no-repeat map-get($color-background, bg-01);
}
.layer_main_pop_wrap .btn_noshow::before  {
  display: inline-block;
  width: 22px;
  height: 22px;
  border: 1px solid map-get($color-type, gray-01);
  border-radius: 50%;
  vertical-align: middle;
  box-sizing: border-box;
  content: '';
}

.layer .btn_close .sp,
.ly_point_wrap .ly_header .btn_close .sp,
.ly_pop .btn_cls .sp,
.ly_pop .btn_close .sp,
.ly_pop2 .btn_cls .sp,
.ly_pop3 .btn_close .sp,
#wrap_member.ly_pop #header .btn_close .sp,
.ly_pop2.type3 .btn_close .sp,
.ly_pay_notice .btn_cls .sp,
.point_wrap .header .btn_close .sp,
.ly_pop_full .btn_cls .sp {
  @include useSvg-old('close', 24px);
}
.bx_card_num .btn_reset .sp,
.btn_delete .sp,
.ly_smartticket2 .ly_content .btn_clear > .sp {
  @include useSvg-old('close', 16px);
}
.ly_clip_wrap .btn_close {
  @include useSvg-old('close_white', 24px);
}
.ly_banner_video .btn_close,
.ly_clip_wrap.type_only .btn_close {
  @include useSvg-old('close_white', 6.667vw);
}
.barcode_area .btn .sp {
  @include useSvg-old('search_white', 24px);
}
.mypage_barcode_area .confirm_area .btn_search .sp,
.prdt_box .btn_detail .ico_detail {
  @include useSvg-old('search_white', 16px);
}
.mypage_barcode_area .barcode_area .bx_info .ico_time.sp,
.ly_season_card .seat_info .ico_time {
  @include useSvg-old('clock', 16px);
}
.error_waiting .tit:before {
  @include useSvg-old('clock_gray', 32px);
}
.btn_qa .sp,
.coupon_info_table .btn_reserver_detail,
.personal_info_area .btn_info .sp,
.bugs_box .agree_box .btn_detail .sp {
  @include useSvg-old('btn_question_outlined', 20px);
}
.mobile_ticket.type2 .tit:before {
  @include useSvg-old('smart_ticket', 24px);
}
.mobile_ticket.type3 .tit:before {
  @include useSvg-old('smart_ticket_gray', 24px);
}
.bx_smart_ticket .smart_ticket .bx_tit:before {
  @include useSvg-old('smart_ticket_white', 24px);
}
.bx_smart_ticket .btn_ticket_more:after {
  @include useSvg-old('arrow_right_white', 16px);
}
.tbl_form .ico.phone_number {
  @include useSvg-old('icon_phone', 16px);
}
.tbl_form .ico.email {
  @include useSvg-old('icon_email', 16px);
}
.tbl_form .ico.address {
  @include useSvg-old('icon_home', 16px);
}
.tbl_form .ico.name {
  @include useSvg-old('icon_name', 16px);
}
.m_container.place #menu .menu_wrap .top_content .sp {
  @include useSvg-old('icon_name', 18px);
}
.user_infobx .ico_name,
.m_container.place .sp.ico_name {
  @include useSvg-old('icon_name', 24px);
}
.user_infobx .ico_bank,
.m_container.place .sp.ico_bank {
  @include useSvg-old('icon_bank', 24px);
}
.user_infobx .ico_account,
.m_container.place .sp.ico_account {
  @include useSvg-old('icon_money', 24px);
}
.season_card .lst_seat .ico_entrance {
  @include useSvg-old('icon_entrance', 24px);
}
.ly_pop_facenter .ly_con_dsc:before {
  @include useSvg-old('icon_face_black', 70px);
}
.mypage_barcode_area .facenter_check_area .txt:before,
.mypage_barcode_area .companion_facenter_check_area .txt:before,
.ly_pop_coachmark .facenter_check_area .txt:before {
  @include useSvg-old('face', 16px);
}
.mypage_facenter_confirm .notice_contents:before {
  @include useSvg-old('icon_lock', 70px);
}
.mypage_barcode_area .btn_tickets .sp {
  @include useSvg-old('dot', 16px);
}
.team_reserve .area_notice .tit_noti .ico_notice,
.place .bx_notice .ico_notice,
.mypage_barcode_area .bx_info_product .notice_info > .ico_notice,
.inquiry_select_wrap .notice_box:before,
.btn_reserve_wait.type4:before,
.bx_toggle_notice .ico_notice,
.reserve_notice > span:before,
.reserve_notice .inform_txt:before,
.coupon_detail_wrap .coupon_notice_box .notice_title:before,
.ly_coupon_code .code_cont_wrap .coupon_notice_box .notice_title:before,
.season_card .bx_noti .ico_noti,
.ly_season_info .bx_notice .txt:before,
.seat_lst_area .btn_info .sp,
.ly_waiting .noti_box:before,
.section3 .notice_tit:before,
.ly_point_wrap.point_type2 .tbl_wrap + .notice_area .title:before {
  @include useSvg-old('info', 16px);
}
.filter_area .slct:after,
.info_cash .ico_bu,
.inp_selt:after,
.seat_tit .tit span:after,
.seat_tit .tit.type2 span:after,
.seat_tit .btn_select_seat.type2:after,
.seat_tit .btn_select_seat:after,
.seat_tit .tit span:after,
.seat_tit .tit.close span:after,
.seat_tit .tit.type3.open span:after,
.seat_tit .tit.type3.close span:after,
.select_count_input .open,
.select_count_input span,
.select_box .btn_select::before,
.inp_selt:after {
  @include useSvg-old('int_dropdown', 16px);
}
.ly_smartticket2 .bx_two_input .bx_inp_txt .btn_search_contact > .sp,
#wrap_member .address_info .btn_srch  {
  @include useSvg-old('search', 16px);
}
.calendar_wrap .calendar_box:after {
  @include useSvg-old('calendar_gray', 16px);
}
.calendar_wrap .calendar_box.active:after {
  @include useSvg-old('calendar_black', 16px);
}
.show_info_wrap .btn_share span,
.linkon .top_cont .prdt_sum_area .btn_share {
  @include useSvg-old('btn_share', 24px);
}
.additional_info .notice_bx .btn_notice:after,
.additional_info .notice_bx.is_open .btn_notice:after,
.btn_review_toggle .sp,
.review_event .btn_event_toggle .sp,
.mypage_info_area .notice_bx .btn_notice:after,
.user_infobx .selt_bx .ico_bu,
.selt_bx .bu,
.selt_bx.type2 .bu,
.btn_toggle .sp,
.btn_toggle.open .sp,
.select_box .seat_box .btn_seat:before,
.place .user_infobx .ico_arrow,
.waiting_wrap .info_box .info_item .item_cont .btn_blank::after,
.bx_toggle_notice .btn_toggle .ico {
  @include useSvg-old('arrow_black', 16px);
}
.additional_info .notice_bx.is_open .btn_notice:after,
.review_txt_area.on ~ .btn_review_toggle .sp,
.review_event.off .btn_event_toggle .sp,
.mypage_info_area .notice_bx.is_open .btn_notice:after {
  transform: rotate(180deg);
}
.linkon .bx_info .btn_more:before {
  @include useSvg-old('arrow_black', 4.4444vw);
}
.linkon .bx_info .item_info .ico_mark {
  @include useSvg-old('large_quotes', 7.5vw);
}
.linkon .bx_info .item_info .ico_clock {
  @include useSvg-old('large_clock', 7.5vw);
}
.linkon .bx_info .item_info .ico_date {
  @include useSvg-old('large_calendar', 7.5vw);
}
.linkon .top_cont .detail_info_tbl .btn_more:after,
.mypage_barcode_area .bx_category .bx_lst li a:before,
.btn_reserve_wait.type3:after,
.board_rule .sp,
.btn_reserve_wait.type4:after,
.place .chk_lst li .btn_view::after {
  @include useSvg-old('arrow_right', 16px);
}
#wrap_member .srch_area .paginate .pre,
#wrap_member .srch_area .paginate .next {
  @include useSvg-old('large_prev', 24px);
}
#wrap_member .input_txt_area .btn_del .sp,
.ly_coupon_code .code_cont_wrap .input_box .btn_delete .sp {
  @include useSvg-old('close_gray', 16px);
}
#wrap_member #header h1 {
  background: url(../img/svg/common/logo_black_kr.svg) center / 100% 100% no-repeat;
}
.etc_content .btn_refresh::before {
  @include useSvg-old('btn_retry', 16px);
}
.btn_retry_black .sp {
  @include useSvg-old('btn_retry_black', 12px);
}
.tbl_reserve .btn_area .sp {
  @include useSvg-old('btn_down_box', 20px);
}
.tbl_reserve .btn_area.open .sp {
  @include useSvg-old('btn_up_box', 20px);
}
.reserve_wrap .point_wrap .btn_wrap .btn.btn_refresh .sp,
.captcha_info .btn_refresh .sp,
.seat_tit .btn_refresh .sp {
  @include useSvg-old('btn_refresh', 17px);
}
.facenter_enroll_info .chk_area .btn_view {
  @include useSvg-old('icon_arrow_facenter_r', 16px);
}
.mypage_device .list_terms .btn_view {
  @include useSvg-old('icon_arrow_facenter_r', 16px);
}
.season_card .lst_seat .ico_barcode {
  @include useSvg-old('barcode_facenter', 24px);
}
.ly_waiting.ly_non_designate .select_count_input button.btn_minus .sp {
  @include useSvg-old('minus', 16px);
}
.ly_waiting.ly_non_designate .select_count_input button.btn_plus .sp {
  @include useSvg-old('plus', 16px);
}
.member_info .ico_card {
  @include useSvg-old('payment', 24px);
}
.m_container.place #menu .menu_wrap .top_content .check_cancel .sp_area .sp{
  @include useSvg-old('icon_ticket_login', 24px);
}
.m_container.place #menu .menu_wrap .top_content .mycoupon .sp_area .sp{
  @include useSvg-old('icon_mycoupon', 24px);
}
.lst_match .clean_reserve_type .ico_cleanreserve::before{
  @include useSvg-old('icon_point_star', 12px);
}
.m_container.place #menu .menu_wrap .content .smart .sp{
  @include useSvg-old('icon_smartticket', 24px);
}
.m_container.place #menu .menu_wrap .content .member .sp{
  @include useSvg-old('icon_membership_card', 24px);
}
.m_container.place #menu .menu_wrap .content .season .sp{
  @include useSvg-old('icon_seasoncard', 24px);
}
.m_container.place #menu .menu_wrap .content .coupon .sp{
  @include useSvg-old('icon_discount_coupon', 24px);
}
.m_container.place #menu .menu_wrap .content .reserve_game .sp{
  @include useSvg-old('icon_game_ticket', 24px);
}