.page_category {

}
.section_category_sort {
  padding: 0 20px;
}
.category_sort_title {
  margin: 40px 0 2px;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.38;
  color: map-get($color-type, gray-01);
  &:first-child {
   margin-top: 20px; 
  }
}
.category_group {
  position: relative;
  padding: 12px 0;
  border-bottom: 1px solid map-get($color-line, line-01);
  &:last-child {
    border-bottom: none;
  }
}
.category_title {
  & + .btn_hyperlink {
    position: absolute;
    top: 12px;
    right: 0;
    padding: 13px 5px;
  }
}
.category_title_link {
  display: block;
  padding: 8px 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.44;
  color: $color-black;
  .text_primary {
    color: $color-primary;
  }
}
.category_list {
  display: flex;
  flex-wrap: wrap;
}
.category_item {
  width: 50%;
  .category_title {
    margin: 0;
  }
  .category_link {
    display: inline-block;
    padding: 8px 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: $color-black;
  }
  .link_season {
    position: relative;
    &::after{
      width: 4px;
      height: 4px;
      background-color: #fa2828;
      display: block;
      content: "";
      position: absolute;
      top: 9px;
      right: -7px;
      border-radius: 50%;
    }
}


}
.section_category_etc {
  margin-top: 36px;
  padding: 0 20px;
}
.category_benefit_list {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: map-get($color-background, bg-01);
}
.category_benefit_item {
  position: relative;
  flex: 1;
  text-align: center;
  & + .category_benefit_item {
    &::before {
      position: absolute;
      top: 15px;
      left: 0;
      bottom: 15px;
      width: 1px;
      background: map-get($color-line, line-01);
      content: '';
    }
  }
  .category_benefit_link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    font-size: 13px;
    line-height: 1.38;
    color: $color-black;
    &::before {
      flex: none;
      margin-right: 3px;
      content: '';
    }
    &.benefit_coupon {
      &::before {
        @include useSvg-common('discount_primary', 24px);
      }
    }
    &.benefit_plan {
      &::before {
        @include useSvg-common('exhibition_primary', 24px);
      }
    }
    &.benefit_event {
      &::before {
        @include useSvg-common('event_primary', 24px);
      }
    }
  }
}
.category_guide_list {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 42px;
  &::before,
  &::after {
    position: absolute;
    left: -20px;
    right: -20px;
    height: 1px;
    background-color: map-get($color-line, line-01);
    content: '';
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
}
.category_guide_item {
  position: relative;
  width: 50%;
  text-align: center;
  & + .category_guide_item {
    &::before {
      position: absolute;
      top: 22px;
      left: 0;
      bottom: 22px;
      width: 1px;
      background: map-get($color-line, line-01);
      content: '';
    }
  }
  .category_guide_link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-size: 13px;
    line-height: 1.38;
    color: $color-gray;
    &::before {
      flex: none;
      margin-right: 8px;
      content: '';
    }
    &.guide_notice {
      &::before {
        @include useSvg-common('notice_16_gray', 16px);
      }
    }
    &.guide_cs {
      &::before {
        @include useSvg-common('cs_16_gray', 16px);
      }
    }
    &.guide_ticketing {
      &::before {
        @include useSvg-common('ticketing_16_gray', 16px);
      }
    }
    &.guide_cheating {
      &::before {
        @include useSvg-common('cheating_16_gray', 16px);
      }
    }
  }
}
