/* 기획전 */
@font-face {
	font-family: 'ONE-Mobile-Title';
	font-weight: 400;
	src: url(../font/ONE-Mobile-Title.woff) format('woff'),
		url(../font/ONE-Mobile-Title.woff2) format('woff2');
}
.special_exhibit {
    .header_tab_wrap {
        position: sticky;
        top: 52px;
        z-index: 10;
        background-color: #fff;

        .common_tab {
            &_list {
                padding: 16px 0 14px;
            }
            &_item {
                position: relative;
                &_badge {
                    position: absolute;
                    top: -10px;
                    right: 0;
                    width: 42px;
                    height: 20px;
                    background: url(../img/plan/bg_badge.png) no-repeat;
                    background-size: 42px auto;
                }
                &:last-child {
                    .common_tab_item_badge {
                        right: 20px;
                    }
                }
            }
            &_btn {
                &:disabled {
                    background: $color-white;
                }
            }
        }
        .tab_add_view_btn {
            position: absolute;
            top: 50%;
            right: 6px;
            z-index: 2;
            width: 32px;
            height: 32px;
            border: 1px solid map-get($color-line, line-04);
            border-radius: 16px;
            background: $color-white;
            transform: translateY(-50%);
            &::after {
                display: block;
                margin: 0 auto;
                @include useSvg-common('arrow_down_20', 20px);
                content: ""
            }
        }
        &.active {
            padding-right:54px;
            &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                width: 78px;
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20px);
                content: "";
            }
        }
    }

    .section_plan_detail {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        min-height: calc(100vh - 114px - 120px);
        background: $color-white;
    }

    .section_bottom_btns {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 0;
        padding-bottom: env(safe-area-inset-bottom);
        padding-bottom: constant(safe-area-inset-bottom);
        background: $color-white;
        z-index: 300;
        .btn_reserve {
            display: flex;
            flex-flow: column;
            align-items: center;
            padding: 12px 0;
            width: 100%;
            height: 72px;
            color: $color-black;
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            &::after {
                display: block;
                margin: 4px auto 0;
                @include useSvg-common('arrow_reserve', 16px);
                animation: arrowUpDown 1.2s 100;
                content: "";
            }
        }
        .toolbar_util {
            @include safeArea(false, bottom, 90px bottom);
            .toolbar_coupon {
                position: relative;
                margin-top: 14px;
                width: 40px;
                height: 40px;
                border: solid 1px map-get($color-line, line-01);
                border-radius: 50%;
                background: rgba($color: $color-white, $alpha: 0.95);
                &::before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    @include useSvg-common('discount_star', 24px);
                    content: '';
                }
            }
            .toolbar_top {
                display: none;
                opacity: 0;
            }
            &.fadein {
                .toolbar_top {
                    display: block;
                    animation: fadeIn .5s forwards;
                }
            }
        }
    }

    .main_banner_wrap {
        overflow: hidden;
        position: relative;
        .dimmed {
            position: absolute;
            z-index: 1;
            background: rgba(0,0,0,.6);
            &_notice {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                color: $color-white;
                font-weight: 700;
                font-size: 24px;
            }
        }
        .bg {
            >a {
                display: block;
            }
            img {
                width: 100%;
            }
        }
    }
    .product_share_btn {
        position: absolute;
        top: 4px;
        right: 10px;
        z-index: 1;
        padding: 10px;
        &::after {
            display: block;
            @include useSvg-common('share', 24px);
            @include color-filter($color-white);
            content: "";
        }
    }

    .agreement {
        &_frame {
            background: #313131;
        }
        &_wrap {
            display:flex;
            gap: 0 4px;
            padding: 17px 20px;

            .checkbox_item {
                position: relative;
                .label_checkbox {
                    padding-left: 24px;
                    color: $color-white;
                    font-size: 12px;
                    line-height:18px;
                    &::before {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
        &_detail {
            flex: 0 0 auto;
            align-self: flex-start;
            color: $color-primary;
            font-size: 12px;
            line-height: 18px;
        }
    }

    .notice {
        &_frame {
            padding: 30px 20px;
            background: #f5f5f5;
        }
        &_wrap {
            padding: 0;
            border: 0;
            h3 {
                color: $color-black;
                font-size: 14px;
                line-height:18px;
            }
        }
        &_list {
            display: flex;
            flex-flow: column;
            gap: 8px 0;
            margin-top: 14px;
            li {
                padding-left: 0;
                color: $color-black;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                text-indent: 0;
            }
        }
    }

    .coupon_list_wrap {
        padding: 40px 20px 25px;
        .coupon {
            &_heading {
                margin-bottom: 20px;
                color: $color-black;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
            }
            &_noti {
                margin: 20px 4px 0;
                color: #727984;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                &_line {
                    color: #727984;
                    text-decoration: underline;
                }
            }
        }
    }

    .coupon_schedule {
        &_wrap {
            padding: 25px 20px 50px;
            text-align: center;
            .progress {
                display: flex;
                flex-flow: column-reverse;
                gap: 5px 0;
                padding-top: 30px;
            
                &_tit {
                    display: block;
                    align-self: flex-start;
                    color:map-get($color-type, black-01);
                    font-size: 12px;
                    line-height: 14px;
                }
                &_bg {
                    position: relative;
                    margin: 0 auto;
                    width: 100%;
                    height: 6px;
                    background: #f4f4f4;
                }
                &_active {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 6px;
                    background: map-get($color-type, black-01);
                    &::before {
                        display: block;
                        position: relative;
                        top: -10px;
                        left: calc(100% - 4px);
                        width: 8px;
                        height: 8px;
                        background: url(../img/plan/bg_tail.png) no-repeat;
                        background-size: 8px 8px;
                        content: "";
                    }
                }
                &_board {
                    position: absolute;
                    top: -26px;
                    left: 100%;
                    width: 108px;
                    height: 19px;
                    box-sizing: border-box;
                    border-radius: 2px;
                    color: $color-white;
                    @include font-family('Roboto');
                    font-weight: 800;
                    font-size: 10px;
                    line-height: 19px;
                    letter-spacing: 1px;
                    background: map-get($color-type, black-01);
                    transform: translateX(-50%);
                }
            }
            .countdown {
                display: flex;
                justify-content: center;
                gap: 0 22px;
                &_bx {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    padding: 10px;
                    width: 60px;
                    height: 60px;
                    box-sizing: border-box;
                    border-radius: 10px;
                    color: $color-white;
                    @include font-family('Roboto');
                    background: $color-black;
                    
                    &:not(:first-child)::before {
                        position: absolute;
                        top: calc(50% - 10px);
                        left: -14px;
                        width: 6px;
                        height: 6px;
                        border-radius: 3px;
                        background: $color-black;
                        box-shadow: 0 14px 0 $color-black;
                        font-size:0;
                        content: ":";
                    }
                    
                    .number {
                        font-weight: 600;
                        font-size:28px;
                        line-height:30px;
                    }
                    .txt {
                        position: absolute;
                        bottom: 5px;
                        left: 3.5px;
                        right: 0;
                        font-weight: 500;
                        font-size: 7px;
                        line-height: 12px;
                        letter-spacing: 2px;
                    }
                }
            }
        }
        &_tit {
            display: block;
            font-weight: 500;
            font-size:18px;
            line-height:24px;
        }
        &_date {
            margin: 5px 0 20px;
            @include font-family('Roboto');
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 2px;
        }
    }

    .product_list_wrap {
        padding: 40px 0;
        &.bg_gray { 
            background: #f6f6f6;
        }
        .section_heading {
            .section_title {
                flex: 0 0 auto;
                position: relative;
                z-index: 1;
                font-size: 18px;
                line-height: 24px;
                &::before {
                    position: absolute;
                    bottom: 2px;
                    left: 0;
                    right: -5px;
                    z-index: -1;
                    height: 12px;
                    background: var(--bgColor, transparent);
                    content: "";
                }
            }
        }
        .product_grid_unit {
            .product_region {
                color: #707070;
            }
            .product_period {
                &.d_day {
                    color:$color-primary;
                    font-weight: 400;
                    @include font-family('Notosans');
                    letter-spacing: -1px;
                }
            }
            .product_flag {
                .flag {
                    border-radius: 2px;
                    border-color: #797979;
                    line-height: 1.6;
                }
            }
        }
        .event_link {
            display: block;
            overflow: hidden;
            position: relative;
            margin-top: 8px;
            padding: 4px 22px 4px 10px;
            min-height: 24px;
            box-sizing: border-box;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            background: #E5EEFB;
            
            &.gift {
				background: #FBF5E5;
			}
			&.invite {
				background: #E5EEFB;
			}
			&.entry {
				background: #EDE1F2;
			}
            .flag_tit {
                font-weight: normal;
                @include ellipsis;
                > span {
                    color: $color-primary;
                    font-weight: 700;
                }
            }
            &::after {
                position: absolute;
                top: 6px;
                right: 6px;
                @include useSvg-common('arrow_right', 16px);
                content: "";
            }
        }

        .addview_btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 60px auto 0;
            padding: 9px 20px;
            min-width: 114px;
            border: 1px solid #c6c6c6;
            border-radius: 100px;
            color: $color-black;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.43;
            &::after {
                margin-left: 2px;
                @include useSvg-common('arrow_down', 16px);
                content: '';
            }
        }
        .match_card_list {
            padding: 0 20px;
            .match_card_item+.match_card_item {
                margin-top: 34px;
            }
            .match_card_btnarea {
                .common_btn {
                    cursor: pointer;
                }
            }
        }
    }
    .type_html {
        position: relative;
        img {
			display: block;
			width: 100%;
		}
    }

    .bottom_banner_wrap {
        >a {
            display: block;
        }
        img { 
            width:100%;
        }
    }
    .no_content {
        margin: auto;
		padding: 25px 0 50px;
		color: #62676c;
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -.5px;
		text-align: center;
		.tit {
			display: block;
			font-weight: normal;
		}
		.txt {
			margin-top: 18px;
		}
	}
}

.coupon_img_lst {
    .coupon_img_item {
        position: relative;
        margin: 0 10px;
        border: 1px solid $color-black;
        border-radius: 20px;
        color: $color-white;
        text-align: center;
        background: $color-black;
        & + .coupon_img_item {
            margin-top: 18px;
        }
        &::before,
        &::after {
            position: absolute;
            bottom: 44px;
            width: 20px;
            height: 40px;
            background: url(../img/plan/bg_coupon.png) no-repeat;
            background-size: 40px auto;
            content: "";
        }
        &::before {
            left: -1px;
            background-position: 0 -40px;
        }
        &::after {
            right: -1px;
            background-position: -20px -40px;
        }

        .coupon_txt_box {
            display: flex;
            flex-flow: column;
            align-items: center;
            gap: 10px 0;
            padding: 20px 20px 15px;
            p {
                display: block;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                opacity: 0.6;
            }
            .price_wrap {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 0 6px;
                em {
                    @include font-family('Roboto');
                    font-weight: 700;
                    font-size: 50px;
                    line-height: 50px;
                    word-break: break-all;
                }
                .txt {
                    @include font-family('ONE-Mobile-Title');
                    font-weight: 400;
                    font-size: 28px;
                    text-align: left;
                }
                .unit {
                    display: inline-flex;
                    margin-top: 2px;
                    width: 32px;
                    height: 32px;
                    background-repeat: no-repeat;
                    background-size: 32px auto;
                    @include color-filter($color-white);
                    &.won {
                        background-image: url(../img/plan/bg_won.png);
                    }
                    &.per {
                        background-image: url(../img/plan/bg_per.png);
                    }
                }
            }
        }
        
        .coupon_download_btn {
            display: block;
            width: 100%;
            background: url(../img/plan/bg_coupon_line.png) repeat-x;
            background-size: auto 2px;
            &::after {
                display: block;
                margin: 20px auto;
                @include useSvg-common('download_24', 24px);
                @include color-filter($color-white);
                content: "";
            }
        }
    }
    &.coupon_type_02 {
        .coupon_img_item {
            color: #242428;
            border-color: #c9c9c9;
            background: $color-white;

            .coupon_txt_box .unit,
            .coupon_download_btn:after {
                filter: none;
            }
            &::before {
                background-position: 0 0;
            }
            &::after {
                background-position: -20px 0;
            }
        }
    }
}


.bottom_sheet_wrap {
    .bottom_check_list {
        .radio_item {
            .flag_black_bg {
                margin-top: 3px;
                vertical-align: top;
            }
        }
    }
    .coupon_img_lst {
        .coupon_img_item {
            display: flex;
            margin: 0;
            border-radius: 10px;
            & + .coupon_img_item {
                margin-top: 12px;
            }
            .coupon_txt_box {
                flex: 1;
                align-items: flex-start;
                p {
                    text-align: left;
                }
            }
            &::before,
            &::after {
                content: none;
            }
           .coupon_download_btn {
                flex: 0 0 auto;
                position: relative;
                margin: 6px 0;
                width: 74px;
                background: url(../img/plan/bg_coupon_line2.png) repeat-y;
                background-size: 2px auto;
            }
        }
    }
}

@keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
}

@keyframes arrowUpDown {
    0% {transform:translate(0, 0)}
    40% {transform:translate(0, 6px)}
    100% {transform:translate(0, 0)}
}