// common main
.m_header.type_main {
  position: static;
  height: auto;
  .header_inner {
    padding: 8px 20px;
    justify-content: space-between;
  }
}
.main_nav_list {
  display: flex;
  margin-left: -8px;
}
.main_nav_link {
  display: block;
  position: relative;
  padding: 12px 8px;
  &[aria-current="page"] {
    &::before {
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 20px;
      height: 2px;
      margin-left: -10px;
      background-color: $color-black;
      content: '';
    }
    .main_nav_text {
      color: $color-black;
    }
  }
}
.main_nav_text {
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.44;
  color: map-get($color-type, gray-02);
}
.main_nav_linkon {
  display: block;
  padding: 0 13px;
  border: 1px solid map-get($color-line, line-03);
  border-radius: 30px;
  height: 30px;
  font-size: 0;
  line-height: 1.38;
  color: $color-black;
  box-sizing: border-box;
  &::before {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    content: '';
  }
  .text {
    font-size: 13px;
    font-weight: 500;
    vertical-align: middle;
  }
  .text_point {
    font-size: 13px;
    font-weight: 700;
    color: $color-primary;
    line-height: 1.69;
    vertical-align: middle;
  }
}
.section_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0 20px;
  .section_title_wrap {
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
    .section_title {
      flex: none;
    }
    .popup_btn_wrap {
      margin-left: 3px;
      font-size: 0;
      line-height: 0;
    }
  }
  .section_title {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: $color-black;
    .text_primary {
      color: $color-primary;
      line-height: 1;
      &.text_number {
        @include font-family('Roboto');
        font-weight: 500;
      }
    }
  }
  .common_tab.type_dot {
    margin-right: -6px;
  }
  .m_section:first-child & {
    margin-top: 12px;
  }
}
.m_container.page_main,
.m_container.page_main_sport,
.m_container.page_main_trip {
  padding-top: 0;
  padding-bottom: 80px;
  .section_heading {
    margin-bottom: 20px;
  }
  .section_title {
    font-size: 18px;
    line-height: 1.44;
  }
}

// culture
.page_main {
  .common_tab.type_title .common_tab_btn {
    font-size: 18px;
    line-height: 1.44;
  }
  .section_ranking {
    margin-top: 30px;
    .common_tab.type_dot {
      margin-top: 8px;
      padding: 0 20px;
    }
    .product_grid {
      padding-top: 16px;
      border-top: 1px solid map-get($color-line, line-01);
    }
  }
  .section_open_notice {
    margin-top: 56px;
  }
  .section_banner_middle_02 {
    margin-top: 32px;
  }
  .section_banner_plan {
    margin-top: 40px;
    .common_banner.type_slide {
      margin: 0 20px;
      border-radius: 6px;
      .common_banner_link {
        padding-top: 31.25%;
      }
      .swiper-pagination {
        display: flex;
        gap: 0 4px;
        bottom: 20px;
        left: 20px;
        &-bullet {
          margin: 0;
          width: 5px;
          height: 5px;
          border-radius: 100px;
          background: $color-white;
          &-active {
            width: 10px;
          }
        }
      }
    }
  }
  .section_planshop {
    margin-top: 40px;
    & + .section_planshop {
      margin-top: 56px;
    }
  }
  .section_banner_bottom {
    margin-top: 40px;
  }
}
.main_category {
  padding: 24px 20px 32px;
}
.main_category_list {
  display: flex;
  justify-content: space-between;
}
.main_category_link {
  display: block;
  line-height: 0;
  text-align: center;
}
.main_category_icon {
  $categorys: musical, play, concert, classic, exhibit, package, beach, station, golf;
  display: block;
  width: 14.444vw;
  height: 14.444vw;
  background: map-get($color-background, bg-01);
  border-radius: 50%;
  @each $category in $categorys {
    &.icon_#{$category} {
      position: relative;
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        @include useSvg-common(#{$category}, 8.889vw);
        transform: translate(-50%, -50%);
        content: '';
      }
    }
  }
}
.main_category_text {
  display: block;
  margin-top: 6px;
  font-size: 12px;
  line-height: 1.33;
  color: $color-gray;
}
.ticket_open_notice {
  padding: 0 20px;
  .open_notice_item {
    & + .open_notice_item {
      margin-top: 10px;
    }
  }
  .open_notice_link {
    display: flex;
    align-items: center;
  }
  .open_notice_imgbox {
    overflow: hidden;
    position: relative;
    flex: none;
    margin-right: 10px;
    width: 50px;
    height: 61px;
    border-radius: 6px;
    background: map-get($color-background, bg-01);
    @include img-border;
  }
  .open_notice_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .open_notice_info {
    flex: 1;
  }
  .open_notice_title {
    @include multi-ellipsis(1);
    font-size: 13px;
    line-height: 1.38;
    color: $color-black;
  }
  .open_notice_date {
    margin-top: 3px;
    font-size: 0;
    line-height: 1.33;
    color: map-get($color-type, gray-01);
    .date_text {
      margin-right: 3px;
      font-size: 12px;
    }
    .date_time {
      font-size: 12px;
    }
  }
}

// sport
.page_main_sport {
  .section_category {
    margin-top: 24px;
    .common_tab.type_list {
      .common_tab_list {
        overflow: hidden;
        flex-wrap: wrap;
        gap: 10px 6px;
        padding: 0 20px;
        .common_tab_item {
          margin: 0;
          padding: 0;
        }
      }
    }
    .main_sport_banner {
      margin-top: 20px;
      .sport_banner {
        &_list {
          display: flex;
          flex-flow: column nowrap;
          gap: 8px 0;
          margin: 0 20px;
        }
        &_link {
          display: block;
          overflow: hidden;
          position: relative;
          padding-top: 25vw;
          border-radius: 8px;
        }
        &_img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .common_btn_box {
        margin: 10px 20px 0;
        .btn_more {
          color: #242424;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: -0.03em;
          &::after {
              display: inline-block;
              margin-left: 2px;
              width: 17px;
              height: 17px;
              background: url(../img/svg/icons/arrow_down_17.svg);
              content: ""
          }
        }
      }
    }
    .main_sport_team:not([style="display: none;"])+.main_sport_banner {
      margin-top: 4px;
    }
  }
  .section_schedule {
    position: relative;
    margin-top: 40px;
    &::before {
      position: absolute;
      top: 0;
      left: 20px;
      right: 20px;
      height: 1px;
      background-color:map-get($color-line, line-01);
      content: '';
    }
  }
}
.main_sport_team {
  .sport_team_list {
    display: flex;
    padding: 16px 0;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .sport_team_item {
    flex-shrink: 0;
    & + .sport_team_item {
      margin-left: 8px;
    }
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
    &.esports {
      .sport_team_imgbox {
        @include useSvg-common('esports_category', 56px);
      }
    }
  }
  .sport_team_link {
    display: block;
    padding: 8px 10px 10px 10px;
    width: 86px;
    height: 104px;
    border-radius: 10px;
    background-color: $color-white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    box-sizing: border-box;
  }
  .sport_team_imgbox {
    margin: 0 auto;
    width: 56px;
    height: 56px;
    .sport_team_img {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      vertical-align: top;
    }
  }
  .sport_team_name {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    font-size: 12px;
    line-height: 1.33;
    color: $color-gray;
    .text {
      @include multi-ellipsis(2);
      word-break: keep-all;
      word-wrap: break-word;
    }
  }
  .common_data_none {
    position: static;
    padding: 59px 20px;
    height: 136px;
    transform: none;
  }
}
.section_quick_reserve {
  margin-top: 32px;
  .sport_quick_emblem {
    .section_heading {
      margin-bottom: 11px;
    }
  }
}
.sport_quick_banner {
  .quick_banner_list {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .quick_banner_item {
    flex-shrink: 0;
    & + .quick_banner_item {
      margin-left: 8px;
    }
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
  .quick_banner_link {
    display: block;
    overflow: hidden;
    position: relative;
    width: 136px;
    height: 60px;
    border-radius: 10px;
    background-color: map-get($color-background, bg-01);
  }
  .quick_banner_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.sport_quick_emblem {
  .quick_emblem_list {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .quick_emblem_item {
    flex-shrink: 0;
    & + .quick_emblem_item {
      margin-left: 8px;
    }
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
  .quick_emblem_link {
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 7px 17px 7px 8px;
    width: 133px;
    height: 60px;
    border-radius: 10px;
    background-color: map-get($color-background, bg-01);
    white-space: normal;
    box-sizing: border-box;
  }
  .quick_emblem_imgbox {
    position: relative;
    flex: none;
    margin-right: 4px;
    width: 46px;
    height: 46px;
  }
  .quick_emblem_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .quick_emblem_name {
    @include ellipsis;
    flex: 1;
    font-size: 12px;
    line-height: 1.33;
    color: $color-black;
    text-align: center;
  }
}
.sport_schedule {
  display: flex;
  flex-wrap: wrap;
  &.is-sticky {
    .section_heading {
      background-color: $color-white;
    }
  }
  .section_heading {
    position: sticky;
    top: 0;
    margin-bottom: 0;
    width: 100%;
    padding: 40px 20px 14px;
    z-index: map-get($z-index, sticky);
  }
  .spread_calendar {
    position: sticky;
    top: 90px;
    padding: 0 12px 0 20px;
    width: 44px;
    height: calc(100vh - 150px);
    height: calc(100vh - 150px - env(safe-area-inset-bottom));
    height: calc(100vh - 150px - constant(safe-area-inset-bottom));
    overflow-y: auto;
    z-index: 5;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .spread_calendar_list {
    display: block;
    overflow: visible;
  }
  .spread_calendar_item {
    padding: 0;
    & + .spread_calendar_item {
      margin-left: 0;
      margin-top: 2px;
    }
  }
  .match_card_list {
    padding-right: 20px;
    flex: 1;
  }
  .match_versus {
    margin: 0 2.777vw;
  }
  .match_schedule_none {
    flex: 1;
  }
}
// team
.page_main_team {
  &.m_container {
    padding-top: 0;
  }
  .m_section {
    background-color: $color-white;
    &.section_team_spotlight {
      background-color: map-get($color-type, gray-01);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: auto 248px;
    }
  }
  .team_banner_link {
    padding-top: 28.13%;
  }
}
.section_team_spotlight {
  padding-top: 52px;
  height: 248px;
  text-align: center;
  box-sizing: border-box;
}
.team_spotlight {
  margin-top: 12px;
  .team_spotlight_imgbox {
    margin: 0 auto 4px;
    width: 72px;
    height: 72px;
    .team_spotlight_img {
      width: 100%;
      height: 100%;
      vertical-align: top;
    }
  }
  .team_spotlight_btn {
    position: relative;
    padding: 0 26px 0 4px;
    &::before {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -10px;
      width: 20px;
      height: 20px;
      border: 1px solid map-get($color-type, gray-03);
      border-radius: 50%;
      opacity: 0.8;
      box-sizing: border-box;
      content: '';
    }
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      margin: -3px 4px 0 0;
      width: 5px;
      height: 5px;
      border: 1px solid $color-white;
      border-width: 0 0 1px 1px;
      transform: rotate(-45deg);
      transform-origin: 0 100%;
      content: '';
    }
  }
  .team_spotlight_name {
    padding: 4px 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    color: $color-white;
  }
}
.section_team_info {
  margin: -24px 0 -10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.team_info_box {
  position: relative;
  top: -24px;
  margin: 0 20px;
  border-radius: 10px;
  background-color: $color-white;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  .team_info_list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    height: 100%;
    box-sizing: border-box;
  }
  .team_info_item {
    position: relative;
    flex: 0 0 auto;
    max-width: 100%;
    & + .team_info_item {
      &::before {
        position: absolute;
        top: 50%;
        left: -1px;
        margin-top: -1px;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: $color-gray;
        content: '';
      }
    }
  }
  .team_info_link {
    padding: 7px 9px;
    font-size: 13px;
    line-height: 1.38;
    @include ellipsis;
  }
}
.section_team_coupon {
  padding: 20px;
}
.section_team_banner {
  padding-bottom: 24px;
  .common_btn_box {
    padding: 24px 20px 0;
    &:first-child {
      padding-top: 10px;
    }
  }
}
.section_team_btn_jeju {
  display: flex;
  padding: 0 20px 30px;
  .btn_medium {
    flex: 1 1 50%;
    + .btn_medium {
      margin-left: 10px;
    }
  }
  .btn_primary {
    background-color: #f36a00;
    &:hover, &:focus {
      border-color: #f36a00;
      background-color: #f36a00;
    }
  }
  .btn_ghost {
    color: #f36a00;
    border-color: #f36a00;
  }
}
.section_team_btn {
  display: flex;
  padding: 0 20px 30px;
  .btn_medium {
    flex: 1 1 50%;
    + .btn_medium {
      margin-left: 10px;
    }
    &.btn_membership {
      justify-content: flex-start;
      padding: 0 12px;
      text-align: left;
      &:after {
        margin-left: auto;
        width: 16px;
        height: 16px;
        background: url(../img/svg/icons/arrow_right_16.svg);
        content: "";
      }
    }
  }
  $teamColor: (
    default: (color: #F36A00),
    jeju: (color: #F36A00),
    daegufc: (color: #0068B7),
    fcanyang: (color: #4B237A),
    gimpofc: (color: #18383B),
    seoulelandfc: (color: #00032F),
  );
  @each $team, $value in $teamColor {
    &.#{$team} { 
     .btn_primary {
        border-color: map-get($value, 'color'); 
        background-color: map-get($value, 'color'); 
        &:hover, &:focus {
          border-color: map-get($value, 'color'); 
          background-color: map-get($value, 'color'); 
        }
      }
      .btn_ghost {
        color: map-get($value, 'color'); 
        border-color: map-get($value, 'color'); 
      }
    }
  }
}
.team_schedule {
  &.is-sticky {
    .team_schedle_sticky {
      background-color: $color-white;
    }
  }
  .team_schedle_sticky {
    position: sticky;
    padding: 10px 0;
    top: 52px;
    z-index: map-get($z-index, sticky);
  }
  .section_heading {
    margin-bottom: 10px;
  }
  .team_schedle_info{
    margin: -2px 30px 12px;
    color: $color-gray;
    font-size: 13px;
    line-height: 20px;
  }
  .team_onlyhome_btn {
    padding: 5px 5px 5px 7px;
    font-size: 12px;
    line-height: 1.33;
    color: $color-black;
    &::before {
      display: inline-block;
      width: 8px;
      height: 6px;
      border: 1px solid map-get($color-type, gray-02);
      border-width: 0 0 1px 1px;
      transform: rotate(-45deg);
      transform-origin: 0 100%;
      content: '';
    }
    &[aria-pressed=true] {
      &::before {
        border-color: $color-primary;
      }
    }
  }
  .match_card_list {
    padding: 8px 20px 0;
  }
}
// e-sports 스와이프&배너
.section_e_sports {
  margin-top: 40px;
  .thmbnail_wrap {
    .sport_team_list {
      margin:20px auto;
      padding:0;
    }
    .sport_team_link {
      display: block;
      overflow: hidden;
      width: 100%;
      height: 82px;
      border-radius: 6px;
      box-shadow: none;
      padding:0;
      .sport_team_imgbox {
        width:100%;
        height:100%;
      }
    }
    .sport_team_imgbox {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .list_type04 {
    .sport_team_link {
      width: 134px;
    }
  }
  .list_type02 {
    .sport_team_item {
      width: calc(50% - 24px);
    }
  }
  .list_type01 {
    .sport_team_item {
      width: calc(100% - 40px);
    }
  }
}

// trip
.page_main_trip {
  .hero_banner {
    .swiper-slide {
      width: 90vw;
    }
    .hero_banner_text {
      bottom: 5.555vw;
    }
    .hero_banner_maintxt {
      opacity: 0.8;
    }
    .hero_banner_flag {
      margin-bottom: 1.111vw;
    }
  }
  .main_category {
    padding: 24px 32px 32px;
  }
  .filter_current{
    padding: 0 15px 0 10px;
  }
  .selt_lst {
    min-width:90px;
    .select a {
      background-color: #f5f6f9;
    }
  }
}

.section_trip_pick {
  padding: 39px 0 24px;
  .product_grid_list {
    margin-top: -12px;
  }
  .product_grid_item {
    margin-top: 20px;
  }
  .product_grid_unit {
    .product_depart {
      display: block;
      margin-bottom: 5px;
      color: $color-primary;
      font-size: 10px;
    }
    .product_title {
      font-size: 16px;
      font-weight: 500;
    }
    .product_price {
      margin-top: 12px;
      line-height: 1;
      .product_price_number {
        @include font-family('Roboto');
        font-size: 16px;
        font-weight: 500;
      }
      .product_price_text {
        margin-left: -2px;
        font-size: 12px;
        &::after {
          margin-left: 4px;
          font-size: 16px;
          content: '-';
        }
      }
    }
  }
}
.trip_pick_filter {
  padding-bottom: 24px;
  text-align: center;
}
.filter_type {
  margin-top: 8px;
}
.filter_txt {
  font-size: 22px;
  vertical-align: top;
}
.filter_btn {
  position: relative;
  margin-left: 8px;
  padding: 0 20px 0 2px;
  font-size: 22px;
  font-weight: 700;
  border-bottom: 2px solid map-get($color-type, black-01);
  &::after {
    position: absolute;
    top: 50%;
    right: -2px;
    @include useSvg-common('arrow_down_22', 22px);
    transform: translateY(-44%);
    content: '';
  }
  .selt_lst {
    top:34px;
  }
}

.section_trip_video {
  padding: 24px 0 32px;
  .trip_video {
    display: flex;
    &.type_scroll {
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      margin-top: 0;
      padding-left: 20px;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
    } 
  }
}
.trip_video_link {
  display: inline-block;
  flex: none;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
    padding-right: 20px;
  }
}
.trip_video_box {
  position: relative;
  width: 90vw;
  height: 50vw;
  border-radius: 2.222vw;
  overflow: hidden;
  .cover {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}
