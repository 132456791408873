.common_tab {
  &.type_capsule {
    .common_tab_list {
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .common_tab_item {
      & + .common_tab_item {
        margin-left: 6px;
      }
      &:first-child {
        padding-left: 20px;
      }
      &:last-child {
        padding-right: 20px;
      }
    }
    .common_tab_btn {
      padding: 5px 14px 7px;
      min-height: 32px;
      border: 1px solid map-get($color-type, gray-03);
      border-radius: 32px;
      background: $color-white;
      font-size: 13px;
      line-height: 1.38;
      color: $color-black;
      &[aria-selected="true"] {
        border-color: $color-black;
        background: $color-black;
        color: $color-white;
      }
      &:disabled {
        border-color: map-get($color-background, bg-04);
        background: map-get($color-background, bg-04);
        color: map-get($color-type, gray-02);
      }
    }
  }
  &.type_dot {
    .common_tab_item {
      & + .common_tab_item {
        margin-left: 6px;
      }
    }
    .common_tab_btn {
      position: relative;
      padding: 8px 6px;
      min-height: 32px;
      font-size: 12px;
      line-height: 1.33;
      color: $color-gray;
      &[aria-selected="true"] {
        font-weight: 700;
        color: $color-black;
        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          width: 2px;
          height: 2px;
          margin-top: -1px;
          border-radius: 50%;
          background: $color-black;
          content: '';
        }
      }
    }
  }
  &.type_title {
    .common_tab_item {
      & + .common_tab_item {
        margin-left: 8px;
      }
    }
    .common_tab_btn {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: map-get($color-type, gray-02);
      &[aria-selected="true"] {
        color: $color-black;
      }
    }
  }
  &.type_underline {
    position: relative;
    background: $color-white;
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background: map-get($color-type, gray-03);
      content: '';
    }
    .common_tab_list {
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .common_tab_item {
      &:first-child {
        padding-left: 10px;
      }
      &:last-child {
        padding-right: 10px;
      }
      &:first-child:nth-last-child(2),
      &:first-child:nth-last-child(2) ~ .common_tab_item {
        width: 50%;
        text-align: center;
      }
      &:first-child:nth-last-child(2) {
        padding-left: 30px;
      }
      &:first-child:nth-last-child(2) ~ .common_tab_item {
        padding-right: 30px;
      }
    }
    .common_tab_btn {
      position: relative;
      padding: 17px 10px 12px;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.47;
      color: map-get($color-type, gray-02);
      letter-spacing: normal;
      &[aria-selected="true"] {
        color: $color-black;
        &::before {
          position: absolute;
          bottom: 0;
          left: 10px;
          right: 10px;
          height: 2px;
          background: $color-black;
          content: '';
        }
        .text_number {
          color: $color-primary;
        }
      }
      .text_number {
        @include font-family('Roboto');
        font-weight: 700;
      }
    }
    .blur_start,
    .blur_end {
      position: absolute;
      top: 0;
      width: 43px;
      height: 100%;
      z-index: 1;
    }
    .blur_start {
      left: 0;
      background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
    }
    .blur_end {
      right: 0;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 100%);
    }
  }
  &.type_brick {
    padding: 0 20px;
    .common_tab_list {
      flex-wrap: wrap;
      overflow: hidden;
      position: relative;
      border: 1px solid map-get($color-line, line-01);
      border-radius: 6px;
      &::before, &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        background: map-get($color-line, line-01);
        content: '';
      }
      &::before {
        left: 33.33%;
      }
      &::after {
        right: 33.33%;
      }
    }
    .common_tab_item {
      width: 33.333%;
      &:nth-child(3n+1) {
        &::before {
          position: absolute;
          left: 0;
          right: 0;
          height: 1px;
          background: map-get($color-line, line-01);
          content: '';
        }
      }
      &:nth-child(3n+1):first-child {
        &::before {
          display: none;
        }
      }
    }
    .common_tab_btn {
      padding: 0 10px;
      width: 100%;
      height: 40px;
      font-size: 14px;
      line-height: 1.43;
      color: $color-black;
      &[aria-selected="true"] {
        background: $color-black;
        font-weight: 500;
        color: $color-white;
      }
    }
  }
  &.type_rectangle {
    .common_tab_item {
      flex: 1;
      &:last-child {
        .common_tab_btn {
          &::before {
            border-right: 0;
          }
        }
      }
    }
    .common_tab_btn {
      position: relative;
      width: 100%;
      height: 43px;
      background: $color-white;
      font-size: 14px;
      color: map-get($color-type, gray-02);
      &[aria-selected="true"] {
        background: $color-black;
        color: $color-white;
        &::before {
          left: 0;
          border-color: $color-black;
        }
      }
      &::before {
        position: absolute;
        top: 0;
        left: -1px;
        bottom: 0;
        right: 0;
        border: 1px solid map-get($color-type, gray-03);
        box-sizing: border-box;
        content: '';
      }
    }
  }
}
.common_tab_list {
  display: flex;
}