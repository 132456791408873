// spread calenar (스포츠메인/구단메인)
.spread_calendar_list {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}
.spread_calendar_item {
  & + & {
    margin-left: 11px;
  }
  &:first-child {
    padding-left: 20px;
  }
  &:last-child {
    padding-right: 20px;
  }
}
.spread_calendar_btn {
  width: 44px;
  height: 66px;
  border-radius: 44px;
  background-color: $color-white;
  .all {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.38;
    color: $color-black;
  }
  .date {
    display: block;
    @include font-family('Roboto');
    font-size: 18px;
    font-weight: 700;
    color: $color-black;
  }
  .day {
    display: block;
    font-size: 12px;
    line-height: 1.33;
    color: map-get($color-type, gray-01);
  }
  &.type_sat {
    .date,
    .day {
      color: #3d85b9;
    }
  }
  &.type_sun {
    .date,
    .day {
      color: #dd6045;
    }
  }
  &:disabled {
    .date,
    .day {
      color: map-get($color-type, gray-02);
    }
  }
  &.is-active {
    background-color: $color-black;
    .all,
    .date,
    .day {
      color: $color-white;
    }
  }
  &.all_btn {
    position: relative;
    &::before {
      position: absolute;
      top: 13px;
      bottom: 13px;
      right: -6px;
      width: 1px;
      background-color: map-get($color-line, line-01);
      content: '';
    }
  }
}

// react-datepicker library custom
.react-datepicker {
  display: block;
  border: 0;
  border-radius: 0;
}
.react-datepicker__month-container {
  float: none;
}
.react-datepicker__header {
  padding: 0;
  border: 0;
  border-radius: 0;
  background: $color-white;
  .calendar_custom_header {
    padding: 15px 0;
    .calendar_nav {
      display: inline-block;
      position: relative;
    }
    .calendar_title {
      display: inline-block;
      min-width: 60px;
      font-family: 'Roboto', 'Notosans', -apple-system, BlinkMacSystemFont, Apple SD Gothic Neo, Roboto, Arial, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      text-align: center;
      color: $color-black;
    }
    .calendar_nav_btn {
      position: absolute;
      top: 50%;
      width: 30px;
      height: 30px;
      transform: translateY(-50%);
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 5.5px;
        height: 5.5px;
        border: 1px solid $color-black;
        border-width: 1px 1px 0 0;
        content: '';
      }
      &:disabled {
        &::before {
          border-color: map-get($color-type, gray-02);
        }
      }
    }
    .calendar_prev {
      left: -37px;
      &::before {
        transform: translate(-50%, -50%) rotate(-135deg);
      }
    }
    .calendar_next {
      right: -37px;
      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}
.react-datepicker__day-names, 
.react-datepicker__week {
  display: flex;
  justify-content: space-around;
}
.react-datepicker__day-names {
  margin: 8px 0 12px;
}
.react-datepicker__day-name {
  margin: 0;
  font-size: 12px;
  line-height: 1.33;
  color: $color-black;
  &:first-child {
    color: map-get($color-type, red-02);
  }
  &:last-child {
    color: map-get($color-type, blue-02);
  }
}
.react-datepicker__month {
  margin: 0;
}
.react-datepicker__week {
  & + & {
    margin-top: 4px;
  }
}
.react-datepicker__day {
  margin: 0;
  width: 44px;
  height: 44px;
  border: 1px solid $color-white;
  border-radius: 50%;
  background: $color-white;
  @include font-family('Roboto');
  font-size: 14px;
  font-weight: 500;
  line-height: 42px;
  color: $color-black;
  box-sizing: border-box;
  &:hover,
  &.react-datepicker__day--keyboard-selected {
    background: $color-white;
    border-radius: 50%;
  }
  &:first-child {
    color: map-get($color-type, red-02);
  }
  &:last-child {
    color: map-get($color-type, blue-02);
  }
  &.react-datepicker__day--today {
    border-color: $color-black;
  }
  &.react-datepicker__day--selected,
  &.react-datepicker__day--in-range {
    border-color: $color-black;
    background: $color-black;
    color: white;
  }
  &.react-datepicker__day--disabled,
  &.react-datepicker__day--outside-month {
    color: map-get($color-type, gray-02);
  }
  @media screen and (max-width: 359px) {
    width: 38px;
    height: 38px;
    line-height: 36px;
  }
}
.react-datepicker__month-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  & + & {
    margin-top: 6px;
  }
}
.react-datepicker__month {
  .react-datepicker__month-text {
    margin: 0;
    width: 31.25%;
    height: 44px;
    border: 1px solid $color-white;
    border-radius: 6px;
    background: $color-white;
    font-size: 14px;
    line-height: 42px;
    color: $color-black;
    box-sizing: border-box;
    &.react-datepicker__month-text--today {
      font-weight: 400;
    }
    &.react-datepicker__month--selected {
      border-color: $color-black;
      background: $color-white;
      font-weight: 500;
    }
    &.react-datepicker__month--disabled {
      color: map-get($color-type, gray-02);
    }
  }
}
.react-datepicker__year-wrapper {
  margin-top: 2px;
  max-width: none;
}
.react-datepicker__year {
  margin: 0;
  .react-datepicker__year-text {
    margin: 6px 0 0 3.125%;
    width: 31.25%;
    height: 44px;
    border: 1px solid $color-white;
    border-radius: 6px;
    background: $color-white;
    font-size: 14px;
    line-height: 42px;
    color: $color-black;
    box-sizing: border-box;
    &:nth-child(3n+1) {
      margin-left: 0;
    }
    &.react-datepicker__year-text--today {
      font-weight: 400;
    }
    &.react-datepicker__year-text--selected {
      border-color: $color-black;
      background: $color-white;
      font-weight: 500;
    }
    &.react-datepicker__year-text--disabled {
      display: none;
    }
  }
}