// hero banner (메인big배너)
.hero_banner {
  &.swiper-container {
    padding: 18px 0 0 20px;
  }
 .swiper-slide {
    width: 67.22vw;
    margin-right: 2.22vw;
 }
 .swiper-slide-prev {
   transform: translateX(-20px);
  }
  .swiper-pagination {
    position: absolute;
    left: calc(5.555vw + 20px);
    bottom: 5.555vw;
    width: auto;
    line-height: 0;
  }
  .swiper-pagination-bullet {
    background: $color-white;
  }
}
.hero_banner_link {
  display: block;
}
.hero_banner_card {
  overflow: hidden;
  position: relative;
  padding-top: 85vw;
  border-radius: 2.777vw;
  background-color: map-get($color-background, bg-01);
}
.hero_banner_imgbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include img-border;
  &::before {
    border-radius: 2.777vw;
  }
}
.hero_banner_img {
  width: 100%;
  height: 100%;
}
.hero_banner_text {
  position: absolute;
  bottom: 11.111vw;
  left: 0;
  padding: 0 5.555vw;
  color: $color-white;
  &.text_black {
    color: $color-black;
  }
}
.hero_banner_title {
  @include multi-ellipsis(2);
  font-size: 6.111vw;
  font-weight: 700;
  line-height: 1.36;
  letter-spacing: -0.183vw;
  white-space: pre-line;
}
.hero_banner_maintxt {
  @include multi-ellipsis(1);
  @include font-family('Roboto');
  margin-top: 2.222vw;
  font-size: 3.333vw;
  line-height: 1.33;
}
.hero_banner_subtxt {
  @include multi-ellipsis(1);
  margin-top: 0.555vw;
  font-size: 3.333vw;
  line-height: 1.33;
  letter-spacing: -0.1vw;
}
// common banner (띠배너)
.common_banner_link {
  display: block;
  position: relative;
  padding-top: 20.833%;
  .common_banner_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
// team banner (구단 배너)
.team_banner {
 .swiper-slide {
    padding: 0 20px;
    box-sizing: border-box;
 }
  .swiper-pagination {
    position: absolute;
    left: calc(5.555vw + 20px);
    bottom: 5.555vw;
    width: auto;
    line-height: 0;
  }
  .swiper-pagination-bullet {
    background: $color-white;
  }
}
.team_banner_link {
  display: block;
  overflow: hidden;
  position: relative;
  border-radius: 2.777vw;
  padding-top: 54.687%;
  background-color: map-get($color-background, bg-01);
  .team_banner_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
// ranking banner
.ranking_banner {
  .swiper-slide {
    width: 89.166vw;
  }
  .swiper-pagination {
    position: absolute;
    left: 5.555vw;
    bottom: 0;
    width: auto;
    line-height: 0;
  }
  .swiper-pagination-bullet {
    background: $color-black;
  }
}
.ranking_banner_link {
  display: block;
}
.ranking_banner_card {
  overflow: hidden;
  position: relative;
  padding-top: 46.388vw;
}
.ranking_banner_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40.833vw;
  background-color: map-get($color-background, bg-01);
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: map-get($color-type, black-01);
    opacity: 0.4;
    content: '';
  }
}
.ranking_banner_info {
  position: absolute;
  top: 3.333vw;
  left: 5.555vw;
  width: 40.277vw;
  color: $color-white;
  box-sizing: border-box;
}
.ranking_banner_rank {
  @include font-family('Roboto');
  font-size: 8.888vw;
  font-weight: 700;
  line-height: 1.13;
  letter-spacing: -0.266vw;
}
.ranking_banner_title {
  margin-top: 1.666vw;
  @include multi-ellipsis(3);
  font-size: 3.888vw;
  line-height: 1.45;
  letter-spacing: -0.116vw;
}
.ranking_banner_txt {
  @include multi-ellipsis(1);
  @include font-family('Roboto');
  margin-top: 1.111vw;
  font-size: 3.333vw;
  font-weight: 300;
  line-height: 1.33;
  letter-spacing: -0.1vw;
}
.ranking_banner_imgbox {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 4.444vw;
  width: 33.333vw;
  height: 41.388vw;
  border-radius: 1.666vw;
  background-color: map-get($color-background, bg-01);
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 22.222vw;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    content: '';
  }
}
.ranking_banner_img {
  width: 100%;
  height: 100%;
}
.ranking_banner_rate {
  display: inline-flex;
  align-items: center;
  position: absolute;
  bottom: 1.666vw;
  left: 2.222vw;
  @include font-family('Roboto');
  font-size: 3.888vw;
  line-height: 1.43;
  letter-spacing: -0.116vw;
  color: $color-white;
  z-index: 1;
  .rate_icon {
    margin-right: 1.111vw;
  }
  .icon_ticket {
    &::before {
      display: block;
      @include useSvg-common('ticket_rate', 4.444vw);
      content: '';
    }
  }
  .icon_seat {
    &::before {
      display: block;
      @include useSvg-common('seat', 4.444vw);
      content: '';
    }
  }
}
// bugs banner
.bugs_banner {
  .swiper-slide {
     width: 100%;
  }
  .swiper-pagination {
    position: absolute;
    bottom: 5.555vw;
    width: 100%;
    line-height: 0;
    text-align: center;
  }
  .swiper-pagination-bullet {
    background: $color-black;
  }
}
.bugs_banner_link {
  display: block;
}
.bugs_banner_card {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-color: map-get($color-background, bg-01);
}
.bugs_banner_imgbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bugs_banner_img {
  width: 100%;
  height: 100%;
}
.bugs_banner_text {
  position: absolute;
  bottom: 12.222vw;
  left: 0;
  width: 100%;
  padding: 0 5.555vw;
  text-align: center;
  color: $color-white;
  box-sizing: border-box;
  &.text_black {
    color: $color-black;
  }
}
.bugs_banner_maintxt {
  @include multi-ellipsis(2);
  font-size: 6.111vw;
  font-weight: 500;
  line-height: 1.454;
}
.bugs_banner_subtxt {
  @include multi-ellipsis(2);
  margin-top: 1.944vw;
  font-size: 3.333vw;
  line-height: 1;
  opacity: 0.7;
}
// product detail banner
.product_detail_banner {
  .swiper-pagination-fraction {
    left: auto;
    right: 15px;
    bottom: 25px;
    width: auto;
    height: 16px;
    padding: 3px 8px 0 26px;
    color: $color-white;
    @include font-family('Roboto');
    font-size: 10px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    &::before {
      position: absolute;
      top: 50%;
      left: 6px;
      @include useSvg-common('camera_white', 12px);
      transform: translateY(-50%);
      content: '';
    }
  }
}
// common indicator media query
.hero_banner,
.team_banner,
.ranking_banner,
.bugs_banner {
  .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      margin: 0;
    }
  }
  .swiper-pagination-bullet {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 100px;
    opacity: 0.2;
  }
  .swiper-pagination-bullet-active {
    width: 10px;
    opacity: 1;
  }
  .swiper-pagination-bullet + .swiper-pagination-bullet {
    margin-left: 4px;
  }
  @media screen and (min-width: 512px) {
    .swiper-pagination-bullet {
      width: 7px;
      height: 7px;
    }
    .swiper-pagination-bullet + .swiper-pagination-bullet {
      margin-left: 5px;
    }
    .swiper-pagination-bullet-active {
      width: 14px;
    }
  }
  @media screen and (min-width: 768px) {
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
    }
    .swiper-pagination-bullet + .swiper-pagination-bullet {
      margin-left: 8px;
    }
    .swiper-pagination-bullet-active {
      width: 20px;
    }
  }
  @media screen and (min-width: 1024px) {
    .swiper-pagination-bullet {
      width: 14px;
      height: 14px;
    }
    .swiper-pagination-bullet + .swiper-pagination-bullet {
      margin-left: 11px;
    }
    .swiper-pagination-bullet-active {
      width: 28px;
    }
  }
}
