.common_modal_wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: map-get($z-index, popup);
  .dimmed {
    position: static;
    width: 100%;
    height: 100%;
    background: rgba($color: #000, $alpha: 0.5);
  }
}
.common_modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  margin: 0 auto;
  background-color: $color-white;
  border-radius: 10px;
  transform: translate(-50%, -50%);
}
.common_modal_header {
  padding: 15px 20px;
  .common_modal_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: $color-black;
  }
}
.common_modal_content {
  overflow-y: auto;
  min-height: 100px;
  max-height: 250px;
  margin: 20px 0 12px;
  padding: 0 20px;
  box-sizing: border-box;
  .common_modal_header + & {
    margin-top: 8px;
  }
  img {
    width: 100%;
    vertical-align: top;
  }
}
.common_modal_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .checkbox_item {
    padding: 16px 20px;
  }
  .common_modal_close {
    padding: 17px 20px;
    margin-left: auto;
    font-size: 14px;
    line-height: 1.43;
    color: $color-black;
  }
  .common_btn_box {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
}
.modal_notice {
  font-size: 13px;
  line-height: 1.38;
  color: $color-gray;
}
.modal_notice_item {
  position: relative;
  padding-left: 10px;
  font-size: 13px;
  line-height: 1.38;
  color: $color-gray;
  &::before {
    position: absolute;
    top: 8px;
    left: 0;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: $color-gray;
    content: '';
  }
  & + & {
    margin-top: 18px;
  }
}