.common_popup_wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color-white;
  z-index: map-get($z-index, popup);
}
.common_popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @include safeArea(false, padding, bottom);
  background-color: $color-white;
  border-radius: 10px 10px 0 0;
}
.common_popup_header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 52px;
  padding: 0 50px;
  background-color: $color-white;
  text-align: center;
  box-sizing: border-box;
  z-index: map-get($z-index, nav-fixed);
  .common_popup_title {
    @include ellipsis;
    font-size: 18px;
    font-weight: 500;
    line-height: 52px;
    color: $color-black;
  }
  .common_popup_close {
    position: absolute;
    top: 0;
    right: 6px;
    width: 52px;
    height: 52px;
    &::before {
      display: block;
      margin: 0 auto;
      @include useSvg-common('close', 24px);
      content: '';
    }
  }
}
.common_popup_content {
  flex: 1;
  margin-top: 52px;
  padding-bottom: 80px;
  overflow-y: auto;
  & > div:first-child ,
  & > div > div:first-child {
    & > .popup_main_title {
      margin-top: 12px;
    }
  }
  img {
    width: 100%;
    vertical-align: top;
  }
}
.common_popup_footer {
  padding: 0 20px 20px;
  .checkbox_item {
    margin-bottom: 24px;
  }
}
.popup_title {
  margin: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
.popup_top_notice {
  padding: 12px 0 16px;
  background-color: map-get($color-background, bg-02);
  .popup_bullet_list {
    .popup_bullet_item {
      color: $color-gray;
      &::before {
        background-color: $color-gray;
      }
      & + .popup_bullet_item {
        margin-top: 12px;
      }
    }
  }
  .popup_desc {
    color: $color-gray;
  }
}
.popup_main_title {
  margin: 40px 20px 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid $color-black;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: $color-black;
}
.popup_sub_title {
  margin: 10px 20px 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.43;
  color: $color-black;
}
.popup_bullet_list {
  margin: 0 20px;
  .popup_bullet_item {
    position: relative;
    padding-left: 8px;
    font-size: 13px;
    line-height: 1.38;
    color: $color-black;
    & + .popup_bullet_item {
      margin-top: 4px;
    }
    &::before {
      position: absolute;
      top: 8px;
      left: 0;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: $color-black;
      content: '';
    }
    &.text_primary {
      color: $color-primary;
      &::before {
      background-color: $color-primary;
      }
      strong {
        font-weight: normal;
      }
    }
    .text_primary {
      color: $color-primary;
    }
    .text_gray {
      color: $color-gray;
    }
    .text_emphasis {
      font-weight: 500;
    }
    .popup_sub_title + & {
      color: $color-gray;
      &::before {
        background-color: $color-gray;
      }
    }
  }
  .popup_dash_list {
    margin: 4px 0 0 0;
    .popup_dash_item {
      font-size: 13px;
      line-height: 1.38;
      color: $color-gray;
    }
  }
}
.popup_dash_list {
  margin: 0 20px;
  .popup_dash_item {
    position: relative;
    padding-left: 10px;
    font-size: 12px;
    line-height: 1.33;
    color: map-get($color-type, gray-01);
    &::before {
      position: absolute;
      top: 8px;
      left: 2px;
      width: 3px;
      height: 1px;
      border-radius: 50%;
      background-color: map-get($color-type, gray-01);
      content: '';
    }
    &.text_primary {
      color: $color-primary;
      &::before {
      background-color: $color-primary;
      }
    }
    .text_primary {
      color: $color-primary;
    }
    & + .popup_dash_item {
      margin-top: 4px;
      .popup_table + & {
        margin-top: 2px;
      }
    }
    .btn_hyperlink {
      margin: 4px 0;
    }
  }
}
.popup_define_list {
  margin: 0 20px;
  &.type_col2 {
    display: flex;
    flex-wrap: wrap;
    .popup_define_item {
      position: relative;
      padding: 13px 0 14px;
      width: 50%;
      border-bottom: none;
      box-sizing: border-box;
      &:nth-child(odd) {
        padding-right: 8px;
        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 200%;
          height: 1px;
          background-color: map-get($color-line, line-01);
          content: '';
        }
        &::after {
          position: absolute;
          top: 14px;
          right: 0;
          bottom: 14px;
          width: 1px;
          background-color: map-get($color-line, line-01);
          content: '';
        }
      }
      &:nth-child(even) {
        padding-left: 8px;
      }
      &:only-child {
        width: 100%;
        &::before {
          width: 100%;
        }
        &::after {
          display: none;
        }
        .popup_define_item {
          padding-right: 0;
        }
        .popup_define_title {
          max-width: 62.5%;
        }
      }
      .popup_define_title {
        max-width: 46.05%;
      }
    }
  }
  .popup_main_title + & {
    margin-top: -16px;
  }
  .popup_define_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 0;
    border-bottom: 1px solid map-get($color-line, line-01);
    font-size: 13px;
    line-height: 1.38;
    color: $color-black;
  }
  .popup_define_title {
    max-width: 62.5%;
    color: $color-gray;
  }
  .popup_define_desc {
    flex: none;
    display: inline-flex;
    align-items: baseline;
    .text_number.text_emphasis {
      margin-right: 1px;
      @include font-family('Roboto');
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      letter-spacing: normal;
    }
  }
}
.popup_table_title {
  margin: 24px 20px 8px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.38;
  color: $color-black;
  & + .popup_table {
    margin-top: 0;
  }
}
.popup_table {
  margin: 17px 20px 8px;
  &.type_scroll {
    overflow-x: auto;
    margin: 17px 0 5px 20px;
    padding-bottom: 10px;
    white-space: nowrap;
    table {
      white-space: normal;
    }
  }
  &.type2 {
    table {
      border-top-color: $color-black;
      border-bottom-color: map-get($color-line, line-01);
      tbody {
        th,
        td {
          padding: 17px 0 16px;
          text-align: left;
        }
        th {
          color: $color-gray;
        }
      }
    }
  }
  table {
    width: 100%;
    table-layout: fixed;
    text-align: center;
    border-top: 1px solid map-get($color-line, line-02);
    border-bottom: 1px solid map-get($color-type, gray-03);
    thead {
      th {
        padding: 10px 0 12px;
        background-color: map-get($color-background, bg-01);
        font-size: 12px;
        font-weight: normal;
        line-height: 1.33;
        color: $color-gray;
      }
    }
    tbody {
      th,
      td {
        padding: 12px 0 14px;
        border-top: 1px solid map-get($color-line, line-01);
        font-size: 13px;
        line-height: 1.38;
        color: $color-black;
      }
      tr:first-child {
        th,
        td {
          border-top: 0;
        }
      }
      th {
        font-weight: normal;
      }
    }
  }
  .text_primary {
    color: $color-primary;
  }
  .text_gray {
    color: $color-gray;
  }
  .text_medium {
    font-weight: 500;
  }
  .data_none {
    padding: 31px 0 45px;
    color: $color-gray;
  }
  & + .popup_caption {
    margin-top: 8px;
  }
}
.popup_imgbox {
  margin: 16px 20px;
  background-color: map-get($color-background, bg-01);
  .popup_img_link {
    display: block;
  }
  .popup_img {
    width: 100%;
    vertical-align: top;
  }
}
.popup_desc {
  margin: 0 20px;
  font-size: 13px;
  line-height: 1.38;
  color: $color-black;
  & + & {
    margin-top: 8px;
  }
  .text_gray {
    color: $color-gray;
  }
  & + .btn_hyperlink {
    margin: 4px 20px;
  }
  .popup_title ~ & {
    color: $color-gray;
  }
}
.popup_caption {
  margin: 0 20px;
  font-size: 12px;
  line-height: 1.33;
  color: map-get($color-type, gray-01);
}
.popup_notice_list {
  .notice_toggle_btn {
    position: relative;
    padding: 16px 48px 16px 20px;
    width: 100%;
    font-size: 0;
    text-align: left;
    &::after {
      position: absolute;
      top: 18px;
      right: 20px;
      @include useSvg-common('arrow_down', 16px);
      content: '';
    }
    &[aria-expanded="true"] {
      &::after {
        transform: rotate(180deg);
      }
      .notice_title {
        font-weight: 500;
      }
      & + .notice_cont {
        display: block;
      }
    }
    .notice_title {
      @include multi-ellipsis(2);
      margin-bottom: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.43;
      color: $color-black;
    }
    .notice_type,
    .notice_date {
      display: inline-block;
      font-size: 12px;
      line-height: 1.33;
      color: $color-gray;
    }
    .notice_type {
      position: relative;
      padding-right: 14px;
      &::after {
        position: absolute;
        top: 50%;
        right: 6px;
        margin-top: -1px;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: $color-gray;
        content: '';
      }
    }
  }
  .notice_cont {
    display: none;
    padding: 20px 20px 32px 20px;
    border-top: 1px solid map-get($color-line, line-01);
    background-color: map-get($color-background, bg-02);
    font-size: 13px;
    line-height: 1.38;
    color: $color-gray;
  }
}
.popup_card_list {
  margin: 0 20px;
  .popup_main_title + & {
    margin-top: -16px;
  }
  .popup_card_item {
    border-bottom: 1px solid map-get($color-line, line-01);
    .popup_banner_img {
      max-width: 310px;
    }
  }
}
.popup_banner_link {
  display: block;
  .popup_banner_img {
    width: 100%;
    vertical-align: top;
  }
}
.popup_seat {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
  .popup_seat_list {
    width: 46.875%;
    box-sizing: border-box;  
  }
  .popup_seat_item {
    position: relative;
    margin-bottom: 6px;
    font-size: 0;
  }
  .seat_color {
    position: absolute;
    top: 3px;
    left: 0;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    vertical-align: middle;
  }
  .seat_title {
    display: inline-block;
    padding-left: 18px;
    font-size: 12px;
    line-height: 1.5;
    color: $color-gray;
    vertical-align: middle;
  }
  & + .popup_dash_list {
    padding-top: 8px;
    border-top: 1px solid map-get($color-line, line-01);
  }
}
.popup_search_box {
  position: relative;
  margin: 0 20px;
}
.popup_search_input {
  padding: 7px 36px 9px 9px;
  width: 100%;
  border: 1px solid map-get($color-type, gray-03);
  border-radius: 6px;
  background: $color-white;
  font-size: 13px;
  line-height: 1.38;
  color: $color-black;
  letter-spacing: -0.04em;
  box-sizing: border-box;
  caret-color: $color-primary;
  &::placeholder {
    color: map-get($color-type, gray-02);
  }
  &::-webkit-search-cancel-button {
    display: none;
  }
}
.popup_search_btn {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 0;
  padding: 9px 10px 11px 10px;
  &::before {
    display: inline-block;
    @include useSvg-common('search_16', 16px);
    content: '';
  }
}
.team_notice {
  margin-top: 12px;
}
.team_guide {
  .team_stadium { 
    & + .team_stadium {
      margin-top: 20px;
    }
  }
  .team_transport {
    position: relative;
    padding-top: 6px;
    & + .team_transport {
      margin-top: 24px;
      &::before {
        position: absolute;
        top: 0;
        left: 20px;
        right: 20px;
        height: 1px;
        background-color: map-get($color-line, line-01);
        content: '';
      }
    }
    .popup_sub_title {
      $items: bus, subway;
      [class^="icon_"] {
        display: inline-block;
        margin: 2px 4px 0 0;
        vertical-align: top;
      }
      @each $item in $items {
      .icon_#{$item} {
          @include useSvg-common(#{$item}, 16px);
        }
      }
    }
    .team_transport_list {
      margin: 0 20px 0 40px;
    }
    .team_transport_item {
      font-size: 13px;
      line-height: 1.38;
      + .team_transport_item {
        margin-top: 24px;
      }
    }
    .transport_station {
      display: block;
      font-weight: 500;
      color: $color-black;
    }
    .transport_route {
      overflow: hidden;
      color: $color-gray;
      margin-top: 2px;
    }
    .transport_line {
      float: left;
      margin: 4px 8px 0 0;
    }
    .transport_detail {
      display: block;
      overflow: hidden;
      margin-top: 4px;
    }
  }
}
.team_price {
  .team_price_guide {
    .popup_table {
      .text_gray {
        color: $color-gray;
      }
      .text_number {
        @include font-family('Roboto');
      }
    }
  }
  .team_benefit {
    & + .team_benefit {
      margin-top: 24px;
    }
  }
}
.privacy_company_search {
  margin-top: 12px;
  &::before {
    display: block;
    margin: 0 auto;
    @include useSvg-common('company', 70px);
    content: '';
  }
  .popup_title {
    margin-top: 20px;
    text-align: center;
  }
  .popup_search_box {
    margin-top: 20px;
  }
}
.privacy_company_result {
  .popup_title {
    margin: 40px 20px 8px;
    padding-top: 24px;
    border-top: 1px solid map-get($color-line, line-01);
  }
  .popup_table {
    overflow-y: auto;
    margin-top: 8px;
    max-height: 202px;
    border-top: 1px solid $color-black;
    table {
      position: relative;
      border-top: 0;
      border-bottom-color: map-get($color-line, line-02);
      thead {
        th {
          position: sticky;
          top: 0;
          padding: 10px;
          font-size: 13px;
          line-height: 1.38;
        }
      }
      tbody {
        tr:last-child {
          td {
            border-bottom-color: map-get($color-line, line-02);
          }
        }
        td {
          padding: 11px 5px;
        }
      }
    }
  }
}
.popup_thirdparty_agree {
  .popup_main_title {
    margin: 10px 20px 32px;
    padding-bottom: 44px;
    font-size: 18px;
    line-height: 1.44;
    border-color: map-get($color-line, line-01);
  }
  .popup_sub_title {
    margin: 24px 20px 4px;
    font-size: 13px;
    line-height: 1.38;
    color: $color-gray;
  }
}
.cs_ticketing {
  .common_tab_cont_area {
    display: none;
    &.active {
      display: block;
    }
  }
  .popup_dash_item.delivery {
    margin-bottom: 0;
  }
  .popup_top_notice {
    margin-top: 22px;
  }
  .popup_main_title {
    color: #fa2828;
    &.sport {
      margin-top: 8px;
    }
    & > span {
      margin-left: 9px;
      color : #242428;
    }
  }
  .popup_img_bx {
    margin: 16px 20px 0;
    &.type2 {
      margin-top: 8px;
    }
    &.type3 {
      margin-top: 40px;
    }
  }
  .popup_img_bx_title {
    line-height: 18px;
    margin: 28px 0 5px;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    &.type2 {
      margin-top: 8px;
    }
    &.type3 {
      margin-top: 40px;
    }
  }
  .popup_img_bx_desc {
    line-height: 18px;
    padding: 0 24px;
    font-size: 12px;
    text-align: center;
    & > .badge {
      display: inline-block;
      width: 16px;
      line-height: 17px;
      height: 16px;
      margin: 0 2px 0 4px;
      border-radius: 50%;
      background-color: #fa2828;
      font-family: "Roboto",-apple-system,BlinkMacSystemFont,Apple SD Gothic Neo,Roboto,Arial,sans-serif;
      font-size: 11px;
      color: #fff;
    }
  }
  .payco {
    display: inline-block;
    width: 54px;
    height: 14px;
    background-image: url('https://github.nhnent.com/pages/ticketlink/ticketlink-markup/web/trunk/92_webview/img/sp.png');
    background-position: -111px -884px;
    background-size: 222px auto;
    vertical-align: middle;
  }
}
.cs_cheating {
  font-size: 13px;
  .popup_inpo {
    margin: 20px 20px 0 20px;
    &.type2 {
      margin-top: 40px;
    }
  }
  .common_btn {
    width: 100%;
    height: 42px;
    line-height: 20px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
  }
  .popup_cheating_list {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  .popup_cheating_item {
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    &:first-of-type {
      margin-right: 10px;
    }
  }
  .popup_cheating_item_title {
    line-height: 18px;;
    margin: 20px 0 10px;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
  }
  .popup_cheating_img_bx {
    padding: 0 26px;
  }
  .popup_cheating_desc {
    line-height: 14px;
    padding: 12px 14px 15px;
    font-weight: 500;
    font-size: 9px;
    text-align: center;
  }
  .popup_cheating_title {
    margin-bottom: 12px;
    padding: 0 20px;
    font-size: 13px;
    font-weight: 500;
  }
}