.page_search {
  .common_data_none {
    position: static;
    margin: 64px 0 80px;
    transform: none;
  }
  .list_filter_count {
    padding: 7px 0;
  }
  .section_team_banner {
    padding: 0 20px;    
    &.is-active {
      ul {
        max-height: none;
      }
      .team_banner_btnmore {
        &::after {
          transform: rotate(180deg);
        }
      }
    }
    ul {
      overflow: hidden;
    }
    li + li {
      margin-top: 12px;
    }
    .team_banner_btnmore {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 15px 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      color: $color-black;
      &::after {
        margin-left: 2px;
        @include useSvg-common('arrow_down', 16px);
        content: '';
      }
    }
  }
  .section_schedule {
    margin-top: 30px;
    .section_heading {
      margin-bottom: 20px;
    }
    ul {
      padding: 0 20px;
    }
  }
}
.section_search_popular {
  margin-top: 24px;
  & + .m_section {
    margin-top: 33px;
  }
}
.search_popular_list {
  margin-top: -7px;
  padding: 0 20px;
}
.search_popular_item {
  .search_popular_link {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: 7px 0;
    color: $color-black;
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      @include useSvg-common('arrow_right', 16px);
      transform: translateY(-50%);
      content: '';
    }
  }
  .search_popular_rank {
    margin-right: 12px;
    @include font-family('Roboto');
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    letter-spacing: -0.01em;
  }
  .search_popular_word {
    flex: 1;
    font-size: 14px;
    line-height: 1.43;
  }
}
.section_search_banner {
  margin-top: 40px;
}
.section_search_recommend {
  margin-top: 40px;
}
.search_recommend_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -24px;
  padding: 0 20px;
}
.search_recommend_item {
  width: 48.75%;
  margin-top: 24px;
  .search_recommend_link {
    display: block;
  }
  .search_recommend_imgbox {
    overflow: hidden;
    position: relative;
    padding-top: 54.487%;
    border-radius: 10px;
    background-color: map-get($color-background, bg-01);
  }
  .search_recommend_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .search_recommend_info {
    margin-top: 10px;
  }
  .search_recommend_phrase {
    display: block;
    font-size: 12px;
    line-height: 1.33;
    color: $color-primary;
  }
  .search_recommend_title {
    @include multi-ellipsis(1);
    margin-top: 5px;
    font-size: 14px;
    line-height: 1.43;
    color: $color-black;
  }
}

.page_search {
  .search_notice {
    &_title {
      padding: 16px 20px 8px;
      color: #242428;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.02em;
    }
    &_list {
      overflow: hidden;
      max-height: 317px;
      .common_btn {
        position: absolute;
        right: 20px;
        bottom: 16px;
        min-width: 68px;
      }
      &.open {
        max-height: none;
        &+.btn_more::after {
          transform: rotate(-180deg);
        }
      }
    }
    &_item {
      position: relative;
      &+.search_notice_item {
        border-top: 1px solid #ECEDF2;
      }
    }
    &_link {
      display: flex;
      gap: 0 10px;
      padding: 11px 20px;
      .imgbox {
        flex: 0 0 auto;
        width: 60px;
        height: 83px;
        >img {
          width: 100%;
        }
      }
      .info {
        overflow: hidden;
        flex: 1;
        .title {
          display: flex;
          min-height: 40px;
          align-items: center;
          .ellipsis {
            @include multi-ellipsis(2);
          }
          margin-bottom: 6px;
          color: #242428;
          font-size: 14px;
          line-height: 20px;
        }
        .text {
          @include ellipsis;
          padding-right: 80px;
          color: #62676C;
          font-size: 12px;
          line-height: 16px;
          &>strong {
            margin-right: 4px;
          }
        }
      }
    }
    .btn_more {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0 5px;
      padding: 15px;
      width: 100%;
      color: #242428;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.03em;
      text-align: center;
      &::after {
        display: inline-block;
        width: 10px;
        height: 6px;
        vertical-align: top;
        background: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.99986 5.5C4.93428 5.5008 4.86926 5.48787 4.80898 5.46204C4.7487 5.4362 4.6945 5.39801 4.64986 5.34998L0.649857 1.34998C0.557031 1.25715 0.504883 1.13128 0.504883 1C0.504883 0.868725 0.557031 0.74285 0.649857 0.650024C0.742682 0.557199 0.86858 0.505005 0.999855 0.505005C1.13113 0.505005 1.25703 0.557199 1.34985 0.650024L4.99986 4.28998L8.64986 0.650024C8.74268 0.557199 8.86858 0.505005 8.99986 0.505005C9.13113 0.505005 9.25703 0.557199 9.34985 0.650024C9.44268 0.74285 9.49483 0.868725 9.49483 1C9.49483 1.13128 9.44268 1.25715 9.34985 1.34998L5.34985 5.34998C5.30521 5.39801 5.25101 5.4362 5.19073 5.46204C5.13045 5.48787 5.06543 5.5008 4.99986 5.5Z' fill='%23242428'/%3E%3C/svg%3E%0A") no-repeat;
        transform: rotate(0);
        content:"";
      }
    }
    &+.list_filter_depth {
      position: sticky;
      top: 104px;
      z-index: 10;
      background: #fff;
    }
  }
}