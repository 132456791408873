.match_card_item {
  & + & {
    margin-top: 12px;
  }
}
.match_card {
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
.match_card_visual {
  position: relative;
  padding-top: 39.39%;
  .match_thmbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .match_team_group {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .match_team {
    width: 22.222vw;
    height: 100%;
    text-align: center;
  }
  .match_team_imgbox {
    margin: 5vw auto 0;
    width: 14.444vw;
    height: 14.444vw;
    .team_schedule & {
      margin: 8.055vw auto 0;
    }
  }
  .match_team_img {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
  .match_team_name {
    @include multi-ellipsis(2);
    font-size: 3.333vw;
    line-height: 1.33;
    color: $color-gray;
  }
  .match_versus {
    margin: 0 5vw;
    &::before {
      display: block;
      @include useSvg-common('versus', 11.111vw);
      content: '';
    }
  }
}
.match_card_info {
  position: relative;
  padding: 12px 50px 16px 16px;
  border-top: 1px solid map-get($color-line, line-01);
  color: $color-black;
}
.match_card_icon {
  $items: baseball, basketball, soccer, volleyball, esports, pingpong;
  position: absolute;
  top: 12px;
  right: 16px;
  @each $item in $items {
    &.icon_#{$item} {
      @include useSvg-common(#{$item}, 24px);
    }
  }
  &.icon_etc {
    @include useSvg-common('sports_etc', 24px);
  }
}
.match_card_flag {
  margin-bottom: 8px;
}
.match_card_title {
  @include multi-ellipsis(2);
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
//매치 텍스트 인라인 설정
.recolor {
  background:none !important;
}
.title_inline {
  display:inline;
}

.match_card_date {
  display: block;
  font-size: 14px;
  line-height: 1.43;
}
.match_card_place {
  display: block;
  margin-top: 2px;
  font-size: 12px;
  line-height: 1.33;
  color: $color-gray;
}
.match_card_gate {
  margin-top: 8px;
  margin-right: -34px;
  font-size: 0;
  .btn_guide_gate {
    display: inline-flex;
    align-items: center;
    padding: 3px 1px 3px 3px;
    border: 1px solid map-get($color-line, line-01);
    border-radius: 6px;
    background: $color-white;
    font-size: 12px;
    line-height: 12px;
    color: $color-gray;
    &::before {
      margin-right: 2px;
      content: '';
    }
    &::after {
      @include useSvg-common('arrow_right_gray', 16px);
      content: '';
    }
    & + .btn_guide_gate {
      margin-left: 3px;
    }
  }
  .btn_clean_reserve {
    &::before {
      @include useSvg-common('clean', 16px);
    }
  }
  .btn_waiting_reserve {
    &::before {
      @include useSvg-common('waiting', 16px);
    }
  }
  .btn_device_auth {
    &::before {
      @include useSvg-common('device_auth', 16px);
    }
  }
}
.match_card_btnarea {
  padding: 0 16px 16px 16px;
}
.match_schedule_none {
  margin-top: 22px;
  text-align: center;
  &::before {
    display: block;
    margin: 0 auto;
    @include useSvg-common('schedule_none', 120px);
    content: '';
  }
  .text {
    display: block;
    margin-top: 6px;
    font-size: 13px;
    line-height: 1.38;
    color: $color-gray;
  }
}