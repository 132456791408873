body,
input,
select,
textarea,
button,
pre {
  font-family: 'Notosans', -apple-system, BlinkMacSystemFont, Apple SD Gothic Neo, 'Malgun Gothic', Arial, sans-serif;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: $color-black;
}

.blind,
caption span,
legend {
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
}

.skip_navi { 
  display:block;
  overflow:hidden;
  position:absolute;
  top:0;
  left:0;
  width:1px;
  height:1px;
  color:#fff;
  text-align:center;
  z-index: map-get($z-index, popup);
  &:focus,
  &:active {
    width:100%;
    height:auto;
    padding:5px 0;
    background: #000;
  }
}

// [개발요청] 테스트용 CSS
#app {
  display: block !important;
}