.toast_wrap {
  position: fixed;
  @include safeArea(false, bottom, 76px bottom);
  left: 20px;
  right: 20px;
  z-index: map-get($z-index, toast);
  text-align: center;
  .toast {
    display: inline-block;
    margin: 0 auto;
    padding: 12px 20px;
    background: rgba(36, 36, 40, 0.8);
    border-radius: 100px;
    font-size: 13px;
    line-height: 1.38;
    color: $color-white;
  }
}