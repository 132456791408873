.product_grid {
  .common_tab.type_capsule + & {
    margin-top: 23px;
  }
}
.product_grid_list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -36px;
  padding: 0 16px;
  &.type_scroll {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    .product_grid_item {
      flex: none;
      margin-top: 0;
      padding: 0 4px;
      width: 134px;
      box-sizing: content-box;
      &:first-child {
        padding-left: 20px;
      }
      &:last-child {
        padding-right: 20px;
      }
      &.type_more {
        width: 116px;
        padding: 0 4px 0 0;
        .product_imgbox {
          padding-top: 139.655%;
          background: none;
          &::before {
            display: none;
          }
        }
      }
    }
    .product_grid_unit {
      .product_imgbox {
        padding-top: 120.895%;
      }
      .product_info {
        margin-top: 8px;
        padding-right: 8px;
      }
      .product_flag {
        margin-bottom: 1px;
      }
    }
  }
  &.type_card {
    .product_grid_item {
      display: block;
      width: 100%;
    }
    .product_grid_unit {
      border: 1px solid map-get($color-line, line-01);
      border-radius: 10px;
      overflow: hidden;
      .product_imgbox {
        padding-top: 66.875%;
        border-radius: 0;
        &::before {
          display: none;
        }
      }
      .product_info {
        margin-top: 0;
        padding: 14px 12px 16px;
      }
    }
  }
}
.product_grid_item {
  display: inline-block;
  margin-top: 36px;
  padding: 0 4px;
  width: 50%;
  box-sizing: border-box;
  &.type_banner {
    padding: 0;
    width: calc(100% + 32px);
    margin: 40px -16px 4px;
  }
  &.type_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .refresh_loading {
      margin: 0;
    }
  }
}
.product_grid_unit {
  .product_link {
    display: block;
  }
  .product_imgbox {
    overflow: hidden;
    position: relative;
    padding-top: 121.154%;
    border-radius: 6px;
    background: url(../img/svg/common/img_default.svg) center / 54.5% no-repeat map-get($color-background, bg-01);
    @include img-border;
    .product_img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .product_ranking {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 34px 5px 2px;
      width: 100%;
      height: 80px;
      @include font-family('Roboto');
      font-size: 40px;
      font-weight: 700;
      line-height: 1.1;
      color: $color-white;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      box-sizing: border-box;
      z-index: 1;
    }
    .product_more_view {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      font-size: 12px;
      line-height: 1.33;
      color: $color-gray;
      text-align: center;
      transform: translate(-50%, -50%);
      &::before {
        display: block;
        margin: 0 auto 6px;
        width: 40px;
        height: 40px;
        border: 1px solid map-get($color-line, line-01);
        border-radius: 50%;
        background: $color-white;
        box-sizing: border-box;
        content: '';
      }
      &::after {
        position: absolute;
        top: 8px;
        left: 50%;
        margin-left: -12px;
        @include useSvg-common('move_top', 24px);
        transform: rotate(90deg);
        content: '';
      }
    }
  }
  .product_info {
    margin-top: 10px;
    padding-right: 16px;
    font-size: 12px;
    line-height: 1.33;
  }
  .product_region {
    display: block;
    margin-bottom: 5px;
    color: $color-primary;
  }
  .product_title {
    @include multi-ellipsis(2);
    font-size: 14px;
    line-height: 1.43;
    color: $color-black;
  }
  .product_sideinfo {
    margin-top: 5px;
    color: $color-gray;
  }
  .product_place {
    @include ellipsis;
  }
  .product_period {
    display: block;
    margin-top: 2px;
    @include font-family('Roboto');
    font-weight: 300;
  }
  .product_flag {
    margin-top: 8px;
  }
}