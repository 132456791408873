.page_detail {
  &.m_container { 
    padding-top: 0;
    @include safeArea(false, padding, 136px bottom);
  }
  .m_section {
    background-color: $color-white;
  }
}
.section_product_image {
  .product_detail_imgbox {
    position: relative;
    padding-top: 121.666%;
    background-color: map-get($color-background, bg-01);
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 110px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      content: '';
    }
  }
  .product_detail_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.section_product_info {
  position: relative;
  padding: 24px 20px 40px;
  margin-top: -10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .button_share {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    &::before {
      display: block;
      @include useSvg-common('share', 24px);
      content:"";
    }
  }
  .flag_area {
    margin-bottom: 14px;
    padding-right: 44px;
    min-height: 10px;
  }
  .product_title {
    @include multi-ellipsis(2);
    font-size: 22px;
    font-weight: 700;
    line-height: 1.45;
    color: $color-black;
  }
  .product_location {
    margin-top: 6px;
    font-weight: 500;
    font-size: 0;
    line-height: 1.5;
    color: $color-black;
  }
  .product_region {
    font-size: 16px;
  }
  .product_place {
    position: relative;
    padding-left: 12px;
    font-size: 16px;
    &::before {
      position: absolute;
      top: 50%;
      left: 5px;
      margin-top: -1px;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: $color-gray;
      content: '';
    }
  }
  .btn_coupon_info {
    display: flex;
    align-items: center;
    padding: 5px 10px 7px;
    border-radius: 6px;
    background-color: $color-black;
    font-size: 13px;
    line-height: 1.38;
    color: $color-white;
    &::after {
      margin-left: 2px;
      @include useSvg-common('download', 16px);
      content: '';
    }
  }
  .product_coupon {
    margin-top: 16px;
  }
  .product_subinfo {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid map-get($color-line, line-01);
    font-size: 13px;
    line-height: 1.38;
  }
  .product_subinfo_item {
    display: flex;
    position: relative;
    & + .product_subinfo_item {
      margin-top: 10px;
    }
  }
  .product_subinfo_title {
    width: 90px;
    padding-right: 7px;
    color: $color-gray;
  }
  .product_subinfo_desc {
    flex: 1;
    color: $color-black;
    .period {
      @include font-family('Roboto');
    }
    .viewing {
      font-size: 0;
    }
    .time {
      font-size: 13px;
      & + .rating {
        position: relative;
        padding-left: 10px;
        &::after {
          position: absolute;
          top: 50%;
          left: 4px;
          margin-top: -1px;
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background-color: $color-gray;
          content: '';
        }
      }
    }
    .rating {
      font-size: 13px;
    }
    .btn_tooltip {
      margin: 2px 0 0 2px;
    }
  }
  .product_guide_gate {
    margin-top: 24px;
    & + .product_banner_01 {
      margin-top: 16px;
    }
  }
  .btn_guide_gate {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid map-get($color-line, line-01);
    background: $color-white;
    color: $color-black;
    &::before {
      margin-right: 3px;
      content: '';
    }
    &::after {
      margin-left: auto;
      @include useSvg-common('arrow_right', 16px);
      content: '';
    }
    & + .btn_guide_gate {
      margin-top: 6px;
    }
  }
  .guide_gate_title {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.38;
  }
  .guide_gate_desc {
    flex: 1;
    margin-left: 5px;
    font-size: 12px;
    line-height: 1.33;
  }
  .btn_waiting_reserve {
    &::before {
      @include useSvg-common('waiting', 16px);
    }
  }
  .btn_clean_reserve {
    border-color: #f4f9fc;
    background-color: #f4f9fc;
    color: map-get($color-type, clean-01);
    &::before {
      @include useSvg-common('clean', 16px);
    }
    &::after {
      @include useSvg-common('arrow_right_clean', 16px);
    }
  }
  .btn_device_auth {
    &::before {
      @include useSvg-common('device_auth', 16px);
    }
  }
  .product_banner_01 {
    margin-top: 24px;
  }
  .product_banner_02 {
    margin: 32px -20px 0 -20px;
  }
}
.product_banner {
  .product_banner_link {
    display: block;
  }
  .product_banner_img {
    width: 100%;
    vertical-align: top;
  }
  .product_banner_01 & {
    border-top: 1px solid map-get($color-line, line-01);
  }
  .section_product_content & {
    margin-bottom: 8px;
    padding: 0 20px;
  }
}
.section_product_content {
  .section_heading {
    margin-top: 40px;
  }
  h2.blind + .section_heading {
    margin-top: 0;
  }
  .section_title {
    display: inline-flex;
    align-items: baseline;
    .text_primary {
      margin-left: 4px;
    }
  }
  .section_sub_title {
    margin: 16px 0 7px;
    padding: 0 20px;
    font-size: 14px;
    line-height: 1.43;
    font-weight: 500;
    color: $color-black;
  }
  .product_editor {
    padding: 0 20px;
    font-size: 13px;
    line-height: 1.38;
    color: $color-gray;
  }
  .section_heading + .product_editor {
    margin-top: -8px;
  }
  img {
    width: 100%;
    vertical-align: top;
  }
}
.product_detail_tab {
  position: sticky;
  top: 52px;
  z-index: map-get($z-index, sticky);
  .common_tab_item {
    position: relative;
  }
  .bubble {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 6px;
    border-radius: 16px;
    background-color: $color-primary;
    color: $color-white;
    &::before {
      position: absolute;
      bottom: -7px;
      right: 50%;
      margin-right: -3px;
      border-style: solid;
      border-color: $color-primary transparent transparent transparent;
      border-width: 4px 3px;
      content: '';
    }
    &::after {
      display: block;
      @include useSvg-common('event_text', 29px);
      content: '';
    }
  }
}
.product_action_bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  @include safeArea(false, padding, bottom);
  background: $color-white;
  z-index: map-get($z-index, nav-fixed);
  .common_btn_box {
    position: absolute;
    @include safeArea(false, bottom, bottom);
    left: 0;
    width: 100%;
    padding: 44px 20px 12px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.93) 40%, #fff 100%);
    box-sizing: border-box;
    flex-flow: wrap;
    gap: 6px 0;
    .btn_ghost {
      >span {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .btn_primary:nth-child(3):last-child {
      margin: 0;
      flex: 0 0 100%;
    }
  }
  .toolbar_util {
    @include safeArea(false, bottom, 80px bottom);
  }
}
.product_detail_toggle {
  .detail_toggle_btn {
    position: relative;
    padding: 16px 46px 16px 20px;
    width: 100%;
    text-align: left;
    &::after {
      position: absolute;
      top: 50%;
      right: 20px;
      @include useSvg-common('arrow_down', 16px);
      transform: translateY(-50%);
      content: '';
    }
    &[aria-expanded="true"] {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
      & + .detail_toggle_content {
        display: block;
      }
    }
  }
  .detail_toggle_title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: $color-black;
  }
  .detail_toggle_content {
    display: none;
    padding: 0 20px 16px;
  }
  .detail_toggle_heading {
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: $color-black;
  }
  .detail_toggle_section {
    padding: 17px 24px 24px 16px;
    border-radius: 6px;
    background-color: map-get($color-background, bg-02);
    & + .detail_toggle_heading {
      margin-top: 14px;
    }
    .toggle_section_title {
      margin-bottom: 9px;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.38;
      color: $color-black;
    }
    .toggle_section_list {
      & + .toggle_section_title{
        margin-top: 21px;
      }
    }
    .toggle_section_item {
      position: relative;
      padding-left: 8px;
      font-size: 12px;
      line-height: 1.33;
      color: $color-gray;
      &::before {
        position: absolute;
        top: 8px;
        left: 0;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: $color-gray;
        content: '';
      }
      & + .toggle_section_item {
        margin-top: 10px;
      }
    }
  }
}
.section_product_detail {
  padding-top: 32px;
}
.product_casting_list {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 20px;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}
.product_casting_item {
  width: 80px;
  & + & {
    margin-left: 16px;
  }
  .product_casting_link {
    display: block;
  }
  .casting_profile_imgbox {
    overflow: hidden;
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: map-get($color-background, bg-01);
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      @include useSvg-common('profile', 46px);
      transform: translate(-50%, -50%);
      content: '';
    }
  }
  .casting_profile_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .casting_name {
    display: block;
    margin-top: 8px;
    font-size: 13px;
    line-height: 1.38;
    color: $color-black;
  }
  .casting_role {
    @include multi-ellipsis(2);
    margin-top: 2px;
    font-size: 12px;
    line-height: 1.33;
    color: map-get($color-type, gray-01)
  }
}
.product_info_box {
  margin: 0 20px;
  padding: 16px 24px 24px 16px;
  border-radius: 6px;
  background: map-get($color-background, bg-02);
  .info_box_item {
    font-size: 13px;
    line-height: 1.38;
    + .info_box_item {
      margin-top: 8px;
    }
  }
  .info_box_title {
    color: $color-gray;
  }
  .info_box_desc {
    color: $color-black;
    .text_number {
      @include font-family('Roboto');
      &.text_emphasis {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.14;
      }
    }
  }

}
.product_templete {
  &.is-active {
    .product_templete_content {
      max-height: none;
    }
    .product_templete_btnmore {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
  .product_templete_content {
    overflow: hidden;
    max-height: 600px;
  }
  .product_templete_img {
    width: 100%;
    vertical-align: top;
  }
  .product_templete_btnmore {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: $color-black;
    &::after {
      margin-left: 2px;
      @include useSvg-common('arrow_down', 16px);
      content: '';
    }
  }
}
.product_additional {
  padding: 0 20px;
  .product_additional_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 16px;
  }
  .product_additional_item {
    position: relative;
    width: 32.8125%;
  }
  .product_additional_imgbox {
    padding-top: 100%;
    background-color: map-get($color-background, bg-01);
  }
  .product_additional_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .product_additional_info {
    margin-top: 4px;
    font-size: 14px;
    line-height: 1.43;
    color: $color-black;
  }
  .product_additional_desc {
    display: inline-block;
    color: map-get($color-type, gray-01);
  }
  .product_additional_notice {
    margin-top: 16px;
    border-top: 1px solid map-get($color-line , line-01);
    .notice_item {
      position: relative;
      margin-top: 6px;
      padding-left: 8px;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.33;
      color: map-get($color-type, gray-01);
      &::before {
        position: absolute;
        top: 4px;
        left: 0;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: map-get($color-type, gray-01);
        content: '';
      }
    }
  }
}
.product_video {
  padding: 0 20px;
  .section_heading + & {
    margin-top: -8px;
  }
  .product_video_box {
    position: relative;
    padding-top: 56.25%;
    background-color: map-get($color-background, bg-01);
    & + .product_video_box {
      margin-top: 8px;
    }
    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
.product_info_table {
  padding: 0 20px;
  font-size: 13px;
  line-height: 1.38;
  color: $color-black;
  text-align: left;
  .section_heading + & {
    margin-top: -8px;
  }
  table {
    width: 100%;
    border-top: 1px solid $color-black;
  }
  th,
  td {
    padding: 17px 0 16px;
    border-bottom: 1px solid map-get($color-line, line-01);
  }
  th {
    padding-right: 24px;
    width: 60px;
    font-weight: normal;
    color: $color-gray;
    vertical-align: top;
  }
  .text_gray {
    color: map-get($color-type, gray-01);
  }
  .text_emphasis {
    font-weight: 500;
  }
}
.section_product_review {
  padding-top: 32px;
}
.product_review_event {
  margin-top: -16px;
  .review_event_title {
    display: inline-block;
    position: relative;
    padding: 2px 12px 3px;
    border-radius: 25px;
    background-color: $color-black;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: $color-white;
    & + .review_event_box {
      margin-top: -12px;
    }
  }
  .review_event_box {
    padding: 26px 20px 20px;
    border-radius: 6px;
    background-color: map-get($color-background, bg-01);
    .review_event_prize {
      font-size: 14px;
      line-height: 1.43;
      color: $color-black;
    }
    .review_event_desc {
      display: block;
      margin-top: 8px;
      font-size: 12px;
      line-height: 1.33;
      color: $color-gray;
    }
    .review_event_info {
      margin: 16px -20px 0;
      padding: 16px 20px 0;
      border-top: 1px solid map-get($color-line, line-01);
    }
    .review_info_item {
      font-size: 12px;
      line-height: 1.33;
      color: $color-black;
      & + .review_info_item {
        margin-top: 5px;
      }
    }
    .review_info_title {
      margin-right: 8px;
    }
    .review_info_desc {
      @include font-family('Roboto');
      font-weight: 300;
    }
  }
  .review_event_notice {
    margin-top: 8px;
  }
  .review_notice_item {
    position: relative;
    padding-left: 8px;
    font-size: 12px;
    line-height: 1.33;
    color: map-get($color-type, gray-01);
    &::before {
      position: absolute;
      top: 8px;
      left: 0;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: $color-gray;
      content: '';
    }
    & + .review_notice_item {
      margin-top: 8px;
    }
  }
  .review_event_img {
    width: 100%;
    vertical-align: top;
  }
  .review_event_agree {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid map-get($color-line, line-01);
  }
}
.product_star10 {
  @include useSvg-common('star_10_gray', 58px);
}
.product_star10_per {
  display: inline-block;
  @include useSvg-common('star_10_black', 58px);
  vertical-align: top;
}
.product_star16 {
  @include useSvg-common('star_10_gray', 92px);
}
.product_star16_per {
  display: inline-block;
  @include useSvg-common('star_10_black', 92px);
  vertical-align: top;
}
.product_star_rate {
  display: flex;
  align-items: center;
  .product_star_score {
    margin: 2px 0 0 6px;
    @include font-family('Roboto');
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.02em;
    color: $color-black;
  }
}
.product_comment_write {
  padding: 0 20px;
}
.product_comment_entry {
  position: relative;
  .comment_entry_desc {
    position: absolute;
    top: 50%;
    left: 14px;
    font-size: 13px;
    line-height: 1.38;
    color: map-get($color-type, gray-02);
    transform: translateY(-50%);
  }
  .comment_entry_btn {
    position: relative;
    padding: 12px 13px;
    width: 100%;
    border: 1px solid map-get($color-type, gray-03);
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: map-get($color-type, gray-01);
    text-align: right;
  }
}
.product_comment_form {
  border: 1px solid map-get($color-type, gray-03);
  border-radius: 6px;
  &.is-focus {
    border-color: $color-black;
  }
  .comment_star_rate {
    padding: 16px 14px 18px;
    border-bottom: 1px solid map-get($color-type, gray-03);
  }
  .comment_star_desc {
    font-size: 13px;
    line-height: 1.38;
    text-align: center;
    color: $color-black;
  }
  .comment_star_select {
    margin-top: 6px;
    font-size: 0;
    text-align: center;
    &.is-active {
      .star_radio {
        &:checked ~ .star_label {
          @include useSvg-common('star_gray', 24px);
        }
        &:checked + .star_label {
          @include useSvg-common('star_black', 24px);
        }
      }
      .star_label {
        @include useSvg-common('star_black', 24px);
      }
    }
    .star_radio {
      @include blind;
    }
    .star_label {
      display: inline-block;
      margin: 0 4px;
      @include useSvg-common('star_gray', 24px);
    }
  }
  .comment_input_box {
    position: relative;
    padding: 14px 7px 13px 13px;
  }
  .comment_textarea {
    overflow-y: auto;
    padding-right: 6px;
    width: 100%;
    height: 93px;
    border: 0;
    font-size: 13px;
    line-height: 1.38;
    color: $color-black;
    vertical-align: top;
    resize: none;
    outline: none;
    caret-color: $color-primary;
    &::placeholder {
      font-size: 13px;
      line-height: 1.38;
      color: map-get($color-type, gray-02);
    }
  }
  .comment_length {
    margin-top: 12px;
    @include font-family('Roboto');
    font-size: 0;
    line-height: 1.33;
  }
  .current_length {
    display: inline-block;
    font-size: 12px;
    color: $color-black;
  }
  .limit_length {
    display: inline-block;
    font-size: 12px;
    color: map-get($color-type, gray-02);
  }
  .comment_btn_box {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 0;
    .comment_cancle_btn, 
    .comment_submit_btn {
      padding: 8px 9px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
    }
    .comment_cancle_btn {
      color: map-get($color-type, gray-01);
    }
    .comment_submit_btn {
      color: $color-black;
    }
  }
}
.product_comment_rule {
  margin-top: 10px;
  line-height: 0;
}
.product_comment_list {
  margin-top: 12px;
  padding: 0 20px;
}
.product_comment_item {
  position: relative;
  border-bottom: 1px solid map-get($color-line, line-01);
  padding: 17px 0 16px;
  .product_star10 {
    margin-bottom: 12px;
  }
  .product_comment_desc {
    font-size: 13px;
    line-height: 1.38;
    color: $color-black;
  }
  .product_comment_info {
    font-size: 0;
    margin-top: 10px;
    padding-right: 80px;
  }
  .comment_id,
  .comment_date {
    display: inline-block;
    @include font-family('Roboto');
    font-size: 12px;
    font-weight: 300;
    line-height: 1.33;
    color: $color-gray;
  }
  .comment_date {
    position: relative;
    padding-left: 10px;
    &::before {
      position: absolute;
      top: 50%;
      left: 4px;
      margin-top: -1px;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: $color-gray;
      content: '';
    }
  }
  .comment_purchaser {
    display: inline-block;
    margin-left: 4px;
    padding: 2px 5px;
    border: 1px solid $color-primary;
    border-radius: 16px;
    font-size: 10px;
    line-height: 1;
    color: $color-primary;
  }
  .product_comment_modify {
    position: absolute;
    bottom: 7px;
    right: -9px;
    font-size: 0;
  }
  .product_comment_edit, 
  .product_comment_delete {
    padding: 9px;
    font-size: 12px;
    line-height: 1.33;
    color: $color-gray;
  }
  .product_comment_delete {
    position: relative;
    &::before {
      position: absolute;
      top: 50%;
      left: -1px;
      margin-top: -1px;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: $color-gray;
      content: '';
    }
  }
}
.product_comment_empty {
  padding: 32px 20px 64px;
  font-size: 13px;
  line-height: 1.38;
  text-align: center;
  color: $color-gray;
}
.section_product_expect {
  padding-top: 32px;
}
.section_product_place {
  padding-top: 32px;
  .product_place_info {
    margin-top: 24px;
    padding: 0 20px;
  }
  .place_info_item {
    display: flex;
    font-size: 14px;
    line-height: 1.43;
    & + .place_info_item {
      margin-top: 8px;
    }
    .place_info_title {
      width: 87px;
      padding-right: 7px;
      color: $color-gray;
    }
    .place_info_desc {
      flex: 1;
      color: $color-black;
    }
  }
  .product_place_map {
    position: relative;
    margin-top: 18px;
    padding-top: 100%;
    .product_map_area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .product_map {
      width: 100%;
      height: 100%;
    }
  }
}
.section_product_addition {
  padding-top: 16px;
}

// trip
.page_detail_trip {
  &.m_container { 
    padding-top: 0;
    @include safeArea(false, padding, 86px bottom);
  }
  .m_section {
    background-color: $color-white;
  }
  .section_product_image {
    .product_detail_imgbox {
      padding-top: 72.222%;
    }
  }
  .section_product_info {
    z-index: 2;
    .product_depart {
      margin-bottom: 6px;
      font-size: 11px;
    }
    .product_state {
      margin-left: 9px;
      color: $color-primary;
    }
    .product_title {
      @include multi-ellipsis(3);
    }
    .product_subinfo_item {
      &.type_column {
        flex-direction: column;
        .product_subinfo_title {
          width: auto;
          padding-right: 0;
          padding-bottom: 10px;
        }
      }
    }
    .product_info_table {
      margin-top: 10px;
      padding: 0;
      table {
        border-top: 0;
        color: $color-black;
      }
      thead {
        th {
          padding: 11px 10px;
          background: map-get($color-background, bg-01);
          color: $color-gray;
          font-size: 12px;
          white-space: nowrap;
        }
      }
      tbody tr {
        th:first-child {
          padding-left: 15px;
          color: $color-black;
        }
        td:last-child {
          padding-right: 15px;
        }
      }
      th, td {
        padding: 14px 6px; 
        text-align: center;
      }
      th {
        width: auto;
        color: map-get($color-type, gray-02);
      }
      td {
        @include font-family('Roboto');
        strong {
          font-weight: 500;
        }
        span {
          @include font-family('Roboto');
        }
      }
    }
  }
  .product_another_depart {
    margin-top: 16px;
  }
  .product_manager_info {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .manager_profile {
      margin-left: 20px;
      font-size: 12px;
      line-height: 18px;
    }
    .manager_name {
      display: block;
      dt, dd {
        display: inline-block;
      }
      dd {
        font-weight: 700;
      }
    }
    .manager_phone, .manager_mail {
      display: inline-block;
      @include font-family('Roboto');
      letter-spacing: 0;
    }
    .manager_mail {
      margin-left: 10px;
    }
  }
  .product_manager_img {
    width: 52px;
    height: 52px;
    border-radius: 100%;
  }
}

.trip_ticket_box {
  position: relative;
  margin: 20px 0;
  border: 1px solid map-get($color-type, gray-03);
  border-radius: 6px;
  box-sizing: border-box;
  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    width: 100%;
    height: 1px;
    border-top: 1px dashed map-get($color-type, gray-03);
    content: '';
  }
  &.type_flight {
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      @include useSvg-common('airplain', 15px);
      transform: translate(-50%, -50%);
      content: '';
    }
  }
}
.trip_ticket_info {
  padding: 16px;
  > span {
    display: block;
    line-height: 16px;
  }
  .state {
    margin-bottom: 10px;
    color: $color-gray;
    font-size: 13px;
  }
  .date, .date_sub {
    @include font-family('Roboto');
  }
  .date_sub {
    color: map-get($color-type, gray-01);
    font-size: 11px;
  }
  .vehicle {
    font-size: 11px;
  }
  &.depart {
    .date_sub {
      margin-top: 4px;
    }
  }
  &.arrive {
    .date_sub {
      margin-bottom: 2px;
    }
  }  
}

.section_product_detail_trip {
  padding-top: 32px;
  .product_benefit_imgbx {
    padding: 0 20px;
    & > img {
      border-radius: 10px;
    }
  }
  .product_benefit {
    margin: 20px 20px 25px;
    padding: 20px 15px;
    border: 1px solid map-get($color-type, gray-03);
    border-radius: 10px;
  }
  .product_benefit_title {
    color: $color-gray;
    font-size: 13px;
  }
  .product_benefit_list {
    margin-top: 18px;
  }
  .product_benefit_item {
    display: flex;
    + .product_benefit_item {
      margin-top: 11px;
    }
    .flag_area {
      position: relative;
      top: 1px;
      flex-shrink: 0;
    }
  }
  .product_benefit_txt {
    margin-left: 7px;
    color: $color-gray;
    font-size: 13px;
  }
  .product_check_point {
    padding: 0 20px 25px;
  }
  .product_check_box {
    margin-top: 20px;
    &:first-child {
      margin-top: 40px;
    }
  }
  .product_check_title {
    font-size: 14px;
    font-weight: 500;
  }
  .product_check_list {
    margin-top: 10px;
    padding: 20px;
    border-radius: 10px;
    background: map-get($color-background, bg-02);
  }
  .product_check_item {
    color: $color-gray;
    font-size: 13px;
    + .product_check_item {
      margin-top: 8px;
    }
    &:nth-child(2) {
      font-size: 10px
    }
    &.sub_desc {
      font-size: 11px;
    }
  }
}

.product_select {
  .selt_bx {
    display: block;
    > a {
      position: relative;
      display: block;
      height: 36px;
      padding: 8px 32px 8px 10px;
      color: $color-black;
      font-size: 13px;
      box-sizing: border-box;
    }
    .ico_bu {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }
  .selt_lst {
    top: -2px;
    z-index: 11;
  }
}

.product_reserve_input_item {
  display: flex;
  margin-top: 10px;
}
.product_reserve_input {
  margin-left: auto;
}
.product_reserve_type {
  .type_desc {
    font-size: 13px;
    span {
      color: map-get($color-type, gray-02);
      font-size: 11px;
    }
  }
  .type_price {
    span {
      @include font-family('Roboto');
      font-size: 14px;
    }
  }
}
.product_reserve_total {
  display: flex;
  margin: 10px 0;
  padding: 14px 0;
  font-size: 13px;
  .total_title {
    font-weight: 700;
  }
  .total_price {
    margin-left: auto;
    color: $color-primary;
    span {
      @include font-family('Roboto');
      font-size: 16px;
      font-weight: 700;
    }
  }
}
.product_reserve_notice {
  .notice_item {
    position: relative;
    padding-left: 5px;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.42;
    color: map-get($color-type, gray-01);
    + .notice_item {
      margin-top: 3px;
    }
    &::before {
      position: absolute;
      top: 6px;
      left: 0;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: map-get($color-type, gray-01);
      content: '';
    }
  }
}

.m_container {
  .product_traffic, .product_price, .product_tourism, .product_shopping, .product_notice {
    margin-top: 40px;
    .product_check_title {
      margin-bottom: 14px;
      font-weight: 500;
    }
  }
  .product_recommend {
    margin-top: 25px;
    padding: 0 20px;
  }
  .recommend_schedule_area {
    margin: 10px 0 20px;
    border-radius: 10px;
    font-size: 12px;
    background-color: #fff7f7;
    .product_banner {
      padding: 0;
    }
    p {
      padding: 12px 18px 11px 12px;
      color: #7d3b3b;
    }
    span {
      color: #eb3434;
      font-weight: 500;
    }
  }
  .product_schedule_template {
    .product_schedule_title {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
    }
    .schedule_box {
      margin-top: 10px;
      padding-bottom: 40px;
      border: 1px solid map-get($color-type, gray-03);
      border-radius: 10px;
      .schedule_heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 18px 0;
        background-color: map-get($color-background, bg-01);
        .schedule_title_wrap {
          margin-bottom: 10px;
          .schedule_badge {
            margin-right: 10px;
            padding: 4px 18px;
            border-radius: 100px;
            font-size: 12px;
            font-weight: 500;
            background-color: $color-black;
            color: $color-white;
          }
          .schedule_title {
            font-size: 16px;
            font-weight: 700;
          }
        }
        .schedule_desc_wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          line-height: 18px;
          color: $color-gray;
          font-size: 12px;
        }
      }
      .product_editor {
        margin-top: 18px;
        .product_editor_item {
          margin-bottom: 30px;
          color: $color-black;
          &:nth-child(2) >p:first-child {
            margin-bottom: 5px;
          }
          h6 {
            font-size: 13px;
            font-weight: 700;
          }
          dl dt {
            margin-bottom: 5px;
          }
          p {
            line-height: 22px;
          }
        }
      }
      .product_schedule_imgbox {
        padding: 0 20px;
      }
      &:last-child {
        h6 {
          margin-bottom: 30px;
        }
        .product_editor_item {
          margin: 0;
        }
        .product_schedule_imgbox {
          margin: 10px 0;
        }
        .product_editor_recommend {
          margin-top: 20px;
          padding: 0 20px;
          color: $color-primary;
          font-weight: 700; 
        }
      }
    }
  }
  .product_traffic, .product_price {
    .product_check_title {
      padding-left: 20px;
      & > span {
        margin-left: 10px;
        font-size: 11px;
        font-weight: 400;
        color: map-get($color-type, gray-01);
      }
    }
    .basic_tbl {
      table {
        width: 100%;
        thead {
          border-top: 1px solid map-get($color-type, gray-03);
          th {
            padding: 10px 0;
            font-size: 11px;
            font-weight: 400;
            color: map-get($color-type, gray-01);
            &:last-child {
              padding-right: 20px;
            }
          }
        }
        tbody {
          th, td {
            padding: 16px 0;
          }
          th {
            font-size: 12px;
          }
          td {
            font-size: 13px;
            text-align: center;
          }
          tr:nth-child(2) > th {
            padding-bottom: 35px;
          }
          th:last-child, td:last-child {
            padding-right: 20px;
          }
        }
        th, td {
          border-bottom: 1px solid map-get($color-type, gray-03);
        }
        th:first-child {
          padding-left: 20px;
          text-align: left;
          font-weight: 400;
          color: map-get($color-type, gray-01);
        }
      }
    }
  }
  .product_price {
    .basic_tbl {
      thead {
        tr th:last-child {
          padding-right: 14px;
        }
      }
      tbody {
        tr td {
          font-size: 12px;
        }
        tr td:first-child {
          padding-left: 17px;
          font-size: 11px;
          text-align: left;
          color: map-get($color-type, gray-01);
        }
        tr:first-child td {
          text-align: center;
          font-size: 12px;
          color: $color-gray;
        }
        tr td:last-child {
          padding-right: 17px;
        }
      }
    }
    .product_price_detail_frame {
      padding: 0 20px;
      .product_price_notice_area {
        margin: 14px 0;
        padding: 11px 0;
        text-align: center;
        background-color: #fff7f7;
        border-radius: 10px;
        & > p {
          line-height: 18px;
          font-size: 12px;
          font-weight: 500;
          color: #eb3434;
        }
      }
      .product_price_detail_wrap {
        li {
          position: relative;
          line-height: 15px;
          padding-left: 10px;
          font-size: 11px;
          color: $color-gray;
          &:first-child {
            margin-bottom: 5px;
          }
          &::before {
            content: '';
            position: absolute;
            top: 7px;
            left: 0;
            width: 2px;
            height: 2px;
            background-color: $color-gray;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .product_tourism, .product_shopping {
    .product_check_title {
      padding-left: 20px;
    }
    .product_accordion_frame {
      padding: 18px 20px;
      background-color: map-get($color-background, bg-01);
      .product_title_wrap {
        & > h5 {
          font-size: 13px;
        }
        & > p {
          font-size: 11px;
          color: $color-gray;
        }
      }
      .product_accordion_wrap {
        line-height: 18px;
        margin-top: 18px;
        ul > li {
          margin-bottom: 10px;
          padding: 8px 10px 10px;
          border-radius: 6px;
          background-color: $color-white;
          .product_accordion_title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            & > span {
              font-size: 13px;
              font-weight: 500;
            }
            & > button {
              @include useSvg-common('rank_down', 8px);
              width: 8px;
              height: 6px;
              transition: 0.3s;
              &.active {
                transform: rotate(-180deg);
              }
            }
          }
          .product_accordion_cont {
            display: none;
            padding-top: 18px;
            font-size: 12px;
            dl:nth-child(2) {
              margin: 25px 0;
              dd {
                padding-left: 10px;
              }
            }
            .product_accordion_price_wrap {
              color: #ff5a0f;
              font-weight: 700;
              ul > li {
                margin: 0;
                padding: 0;
              }
            }
            &.active {
              display: block;
            }
            .basic_tbl_v4 tbody{
              th, td {
                padding-bottom: 5px;
              }
              th {
                padding-top: 2px;
                font-size: 11px;
                font-weight: 400;
              }
              td {
                font-size: 12px;
              }
              tr:last-child th,tr:last-child td {
                padding-bottom: 0;
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .product_shopping_detail {
      padding: 0 20px 18px;
      font-size: 11px;
      color: $color-gray;
      background-color: map-get($color-background, bg-01);
      li {
        position: relative;
        margin-bottom: 5px;
        padding-left: 10px;
        line-height: 15px;
        &::before {
          content: '';
          position: absolute;
          top: 7px;
          left: 0;
          width: 2px;
          height: 2px;
          background-color: $color-gray;
          border-radius: 50%;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .product_notice {
    font-size: 13px;
    & > h4 {
      padding-left: 20px;
    }
    .product_notice_item {
      margin-bottom: 30px;
      padding: 0 20px;
      & > h6 {
        margin-bottom: 10px;
        font-size: 13px;
      }
      .include {
        margin-bottom: 5px;
      }
      & > p {
        line-height: 22px;
      }
      & > ul > li {
        line-height: 22px;
      }
      &:last-child {
        padding: 0;
        & > h6 {
          padding: 0 20px;
        }
        p {
          padding: 0 20px;
          line-height: 15px;
          font-size: 11px;
          color: $color-gray;
        }
        table {
          width: 100%;
          margin-top: 15px;
          thead {
            border-top: 1px solid map-get($color-type, gray-03);
            tr {
              th {
                padding: 12px 0;
                font-size: 11px;
                font-weight: 400;
                color: map-get($color-type, gray-01);
                &:last-child {
                  padding-right: 20px;
                }
              }
            }
          }
          th, td {
            border-bottom: 1px solid map-get($color-type, gray-03);
          }
          td {
            padding: 24px 0 20px;
            font-size: 12px;
            color: $color-gray;
            &:first-child {
              padding-left: 20px;
            }
            &:last-child {
              padding-right: 20px;
            }
            & > .tourism_badge {
              margin-right: 10px;
              padding: 4px 18px 4px 16px;
              border: 1px solid;
              font-size: 10px;
              font-weight: 700;
              &_navy {
                color: #0f41a9;
                border-color: #0f41a9;
              }
              &_yellow {
                color: #ebb102;
                border-color: #ebb102;
              }
              &_red {
                color: #F32727;
                border-color: #F32727;
              }
            }
          }
        }
      }
    }
  }
}

// reserve_trip 
.m_wrap {
  .reserve_trip {
    width: 90%;
    margin: 0 auto;

    // 대제목
    &_title {
      @include multi-ellipsis(3);
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
    }

    &_subinfo {
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid map-get($color-line, line-01);
      font-size: 13px;
      line-height: 1.38;
    }

    &_subinfo_item {
      display: flex;
      & + .reserve_trip_subinfo_item {
        margin-top: 10px;
      }
    }

    &_subinfo_title {
      width: 90px;
      padding-right: 7px;
      color: $color-gray;
    }

    &_subinfo_desc {
      flex: 1;
      color: $color-black;
    }

    &_ticket_box {
      position: relative;
      margin: 20px 0;
      border: 1px solid map-get($color-type, gray-03);
      border-radius: 6px;
      box-sizing: border-box;
      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: block;
        width: 100%;
        height: 1px;
        border-top: 1px dashed map-get($color-type, gray-03);
        content: '';
      }
      &.type_flight {
        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          @include useSvg-common('airplain', 15px);
          transform: translate(-50%, -50%);
          content: '';
        }
      }
    }

    &_ticket_info {
      padding: 16px;
      > span {
        display: block;
        line-height: 16px;
      }
      .state {
        margin-bottom: 10px;
        color: $color-gray;
        font-size: 13px;
      }
      .date, .date_sub {
        @include font-family('Roboto');
      }
      .date_sub {
        color: map-get($color-type, gray-02);
        font-size: 11px;
      }
      .vehicle {
        font-size: 11px;
      }
      &.depart {
        .date_sub {
          margin-top: 4px;
        }
      }
      &.arrive {
        .date_sub {
          margin-bottom: 2px;
        }
      }  
    }

    &_subinfo_item {
      &.type_column {
        flex-direction: column;
        margin-bottom: 40px;
      }
      .reserve_trip_title {
        padding-bottom: 5px;
        font-size: 13px;
        font-weight: 400;
      }
    }

    &_select .inp_txt > button {
      color: #242428;
    }

    &_info_table {
      width: 100%;
      font-size: 13px;
      line-height: 16px;
      color: $color-black;
      text-align: left;
      margin-top: 10px;

      table {
        width: 100%;
      }

      thead {
        th {
          padding: 11px 10px;
          background: map-get($color-background, bg-01);
          font-size: 12px;
          color: #62676c;
        }
        th,
        td {
          border-bottom: none;
        }
      }

      th,
      td {
        padding: 14px 6px 13px;
        text-align: center;

        & > strong {
          font-weight: 500;
        }
        
      }

      td {
        font-family: "Roboto",-apple-system,BlinkMacSystemFont,Apple SD Gothic Neo,Roboto,Arial,sans-serif;
      }

      th {
        font-weight: normal;
        vertical-align: top;
      }

      tbody {
        tr {
          border-bottom: 1px solid map-get($color-line, line-01);
          &:last-child {
            border-bottom: none;
          }
        }
      }

      .type_left {
        padding-left: 12px;
        text-align: left;
      }

      .type_right {
        padding-right: 16px;
        text-align: right;
      }
    }

    &_check_box {
      margin-top: 20px;
    }

    &_check_list {
      margin-top: 10px;
      padding: 20px;
      border-radius: 10px;
      background: map-get($color-background, bg-02);

      & > li {
        font-size: 13px;
        line-height: 20px;
        color: #62676c;

        & ~ li {
          margin-top: 5px;
        }

        & > span {
          font-size: 10px
        }
      }
    }

  }
}