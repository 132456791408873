$common-svg-origin: (
  total-width: 380px,
  total-height: 336px,
  padding: 4px,
  imageSrc: '../img/common-svg.svg'
);

$common-svg-vars: (
  'airplain': (
    offset-x: -356px,
    offset-y: -192px,
    width: 15px,
    height: 15px
  ),
  'alarm': (
    offset-x: -152px,
    offset-y: -206px,
    width: 24px,
    height: 24px
  ),
  'alarm_setting': (
    offset-x: -184px,
    offset-y: -206px,
    width: 24px,
    height: 24px
  ),
  'answer': (
    offset-x: -324px,
    offset-y: -158px,
    width: 18px,
    height: 20px
  ),
  'arrow_down': (
    offset-x: -324px,
    offset-y: -242px,
    width: 16px,
    height: 16px
  ),
  'arrow_down_20': (
    offset-x: -324px,
    offset-y: -186px,
    width: 20px,
    height: 20px
  ),
  'arrow_down_22': (
    offset-x: -324px,
    offset-y: -128px,
    width: 19px,
    height: 22px
  ),
  'arrow_left': (
    offset-x: -324px,
    offset-y: -266px,
    width: 16px,
    height: 16px
  ),
  'arrow_reserve': (
    offset-x: -292px,
    offset-y: -256px,
    width: 16px,
    height: 16px
  ),
  'arrow_right': (
    offset-x: -268px,
    offset-y: -166px,
    width: 16px,
    height: 16px
  ),
  'arrow_right_clean': (
    offset-x: -198px,
    offset-y: -146px,
    width: 16px,
    height: 16px
  ),
  'arrow_right_gray': (
    offset-x: 0px,
    offset-y: -312px,
    width: 16px,
    height: 16px
  ),
  'arrow_up': (
    offset-x: -24px,
    offset-y: -312px,
    width: 16px,
    height: 16px
  ),
  'back': (
    offset-x: -216px,
    offset-y: -206px,
    width: 24px,
    height: 24px
  ),
  'baseball': (
    offset-x: -248px,
    offset-y: -206px,
    width: 24px,
    height: 24px
  ),
  'basketball': (
    offset-x: -68px,
    offset-y: -246px,
    width: 24px,
    height: 24px
  ),
  'beach': (
    offset-x: 0px,
    offset-y: -246px,
    width: 27px,
    height: 26px
  ),
  'bus': (
    offset-x: -48px,
    offset-y: -312px,
    width: 16px,
    height: 16px
  ),
  'calendar': (
    offset-x: -72px,
    offset-y: -312px,
    width: 16px,
    height: 16px
  ),
  'camera': (
    offset-x: -100px,
    offset-y: -246px,
    width: 24px,
    height: 24px
  ),
  'camera_white': (
    offset-x: -356px,
    offset-y: -255px,
    width: 9px,
    height: 8px
  ),
  'cancel': (
    offset-x: -132px,
    offset-y: -246px,
    width: 24px,
    height: 24px
  ),
  'cheating': (
    offset-x: -164px,
    offset-y: -246px,
    width: 24px,
    height: 24px
  ),
  'cheating_16_gray': (
    offset-x: -96px,
    offset-y: -312px,
    width: 16px,
    height: 16px
  ),
  'classic': (
    offset-x: -228px,
    offset-y: -166px,
    width: 32px,
    height: 32px
  ),
  'clean': (
    offset-x: -356px,
    offset-y: -215px,
    width: 12px,
    height: 12px
  ),
  'clock': (
    offset-x: -120px,
    offset-y: -312px,
    width: 16px,
    height: 16px
  ),
  'close': (
    offset-x: -196px,
    offset-y: -246px,
    width: 24px,
    height: 24px
  ),
  'close_gray': (
    offset-x: -144px,
    offset-y: -312px,
    width: 16px,
    height: 16px
  ),
  'company': (
    offset-x: -128px,
    offset-y: -48px,
    width: 70px,
    height: 70px
  ),
  'concert': (
    offset-x: -78px,
    offset-y: -146px,
    width: 32px,
    height: 32px
  ),
  'cs_16_gray': (
    offset-x: -168px,
    offset-y: -312px,
    width: 16px,
    height: 16px
  ),
  'device_auth': (
    offset-x: -192px,
    offset-y: -312px,
    width: 16px,
    height: 16px
  ),
  'discount': (
    offset-x: -228px,
    offset-y: -246px,
    width: 24px,
    height: 24px
  ),
  'discount_primary': (
    offset-x: -260px,
    offset-y: -246px,
    width: 24px,
    height: 24px
  ),
  'discount_star': (
    offset-x: -292px,
    offset-y: 0px,
    width: 24px,
    height: 24px
  ),
  'download': (
    offset-x: -216px,
    offset-y: -312px,
    width: 16px,
    height: 16px
  ),
  'download_24': (
    offset-x: -292px,
    offset-y: -32px,
    width: 24px,
    height: 24px
  ),
  'error': (
    offset-x: 0px,
    offset-y: -128px,
    width: 70px,
    height: 70px
  ),
  'esports': (
    offset-x: -292px,
    offset-y: -64px,
    width: 24px,
    height: 24px
  ),
  'esports_category': (
    offset-x: -228px,
    offset-y: 0px,
    width: 56px,
    height: 56px
  ),
  'event_primary': (
    offset-x: -292px,
    offset-y: -96px,
    width: 24px,
    height: 24px
  ),
  'event_text': (
    offset-x: -78px,
    offset-y: -186px,
    width: 29px,
    height: 7px
  ),
  'exhibit': (
    offset-x: -118px,
    offset-y: -146px,
    width: 32px,
    height: 32px
  ),
  'exhibition_primary': (
    offset-x: -292px,
    offset-y: -128px,
    width: 24px,
    height: 24px
  ),
  'fee': (
    offset-x: -292px,
    offset-y: -160px,
    width: 24px,
    height: 24px
  ),
  'golf': (
    offset-x: -40px,
    offset-y: -206px,
    width: 31px,
    height: 30px
  ),
  'info': (
    offset-x: -240px,
    offset-y: -312px,
    width: 16px,
    height: 16px
  ),
  'input_delete': (
    offset-x: -292px,
    offset-y: -192px,
    width: 24px,
    height: 24px
  ),
  'list_16_white': (
    offset-x: -264px,
    offset-y: -312px,
    width: 16px,
    height: 16px
  ),
  'menu': (
    offset-x: -292px,
    offset-y: -224px,
    width: 24px,
    height: 24px
  ),
  'move_top': (
    offset-x: 0px,
    offset-y: -280px,
    width: 24px,
    height: 24px
  ),
  'movie_white': (
    offset-x: -35px,
    offset-y: -246px,
    width: 25px,
    height: 25px
  ),
  'musical': (
    offset-x: -158px,
    offset-y: -146px,
    width: 32px,
    height: 32px
  ),
  'notice_16_gray': (
    offset-x: -288px,
    offset-y: -312px,
    width: 16px,
    height: 16px
  ),
  'package': (
    offset-x: -117px,
    offset-y: -206px,
    width: 27px,
    height: 24px
  ),
  'payment': (
    offset-x: -32px,
    offset-y: -280px,
    width: 24px,
    height: 24px
  ),
  'pingpong': (
    offset-x: -64px,
    offset-y: -280px,
    width: 24px,
    height: 24px
  ),
  'play': (
    offset-x: 0px,
    offset-y: -206px,
    width: 32px,
    height: 32px
  ),
  'plus_gray': (
    offset-x: -356px,
    offset-y: -271px,
    width: 8px,
    height: 8px
  ),
  'profile': (
    offset-x: -228px,
    offset-y: -64px,
    width: 46px,
    height: 46px
  ),
  'question': (
    offset-x: -324px,
    offset-y: -214px,
    width: 18px,
    height: 20px
  ),
  'question_mark': (
    offset-x: -312px,
    offset-y: -312px,
    width: 16px,
    height: 16px
  ),
  'rank_down': (
    offset-x: -356px,
    offset-y: -287px,
    width: 8px,
    height: 8px
  ),
  'rank_up': (
    offset-x: -356px,
    offset-y: -303px,
    width: 8px,
    height: 8px
  ),
  'receipt': (
    offset-x: -96px,
    offset-y: -280px,
    width: 24px,
    height: 24px
  ),
  'refresh': (
    offset-x: -128px,
    offset-y: -280px,
    width: 24px,
    height: 24px
  ),
  'refund': (
    offset-x: -160px,
    offset-y: -280px,
    width: 24px,
    height: 24px
  ),
  'schedule_none': (
    offset-x: 0px,
    offset-y: 0px,
    width: 120px,
    height: 120px
  ),
  'search': (
    offset-x: -192px,
    offset-y: -280px,
    width: 24px,
    height: 24px
  ),
  'search_16': (
    offset-x: -356px,
    offset-y: 0px,
    width: 16px,
    height: 16px
  ),
  'seat': (
    offset-x: -356px,
    offset-y: -24px,
    width: 16px,
    height: 16px
  ),
  'share': (
    offset-x: -224px,
    offset-y: -280px,
    width: 24px,
    height: 24px
  ),
  'soccer': (
    offset-x: -256px,
    offset-y: -280px,
    width: 24px,
    height: 24px
  ),
  'sort': (
    offset-x: -356px,
    offset-y: -48px,
    width: 16px,
    height: 16px
  ),
  'sports_etc': (
    offset-x: -288px,
    offset-y: -280px,
    width: 24px,
    height: 24px
  ),
  'star_10_black': (
    offset-x: -78px,
    offset-y: -128px,
    width: 58px,
    height: 10px
  ),
  'star_10_gray': (
    offset-x: -144px,
    offset-y: -128px,
    width: 58px,
    height: 10px
  ),
  'star_16_black': (
    offset-x: -128px,
    offset-y: 0px,
    width: 92px,
    height: 16px
  ),
  'star_16_gray': (
    offset-x: -128px,
    offset-y: -24px,
    width: 92px,
    height: 16px
  ),
  'star_black': (
    offset-x: -324px,
    offset-y: 0px,
    width: 24px,
    height: 24px
  ),
  'star_gray': (
    offset-x: -324px,
    offset-y: -32px,
    width: 24px,
    height: 24px
  ),
  'station': (
    offset-x: -79px,
    offset-y: -206px,
    width: 30px,
    height: 25px
  ),
  'subway': (
    offset-x: -356px,
    offset-y: -72px,
    width: 16px,
    height: 16px
  ),
  'ticket_rate': (
    offset-x: -356px,
    offset-y: -96px,
    width: 16px,
    height: 16px
  ),
  'ticketing': (
    offset-x: -324px,
    offset-y: -64px,
    width: 24px,
    height: 24px
  ),
  'ticketing_16_gray': (
    offset-x: -356px,
    offset-y: -120px,
    width: 16px,
    height: 16px
  ),
  'today': (
    offset-x: -356px,
    offset-y: -235px,
    width: 12px,
    height: 12px
  ),
  'tooltip': (
    offset-x: -356px,
    offset-y: -144px,
    width: 16px,
    height: 16px
  ),
  'versus': (
    offset-x: -228px,
    offset-y: -118px,
    width: 40px,
    height: 40px
  ),
  'volleyball': (
    offset-x: -324px,
    offset-y: -96px,
    width: 24px,
    height: 24px
  ),
  'waiting': (
    offset-x: -356px,
    offset-y: -168px,
    width: 16px,
    height: 16px
  ),
);
@function get-ratio($width, $height) {
    @return parse-int($width / $height);
}

@function get-scale($width, $new-width) {
    @return parse-int($new-width / $width);
}

@mixin useSvg-common($image, $size: false) {
  $image-origin: $common-svg-origin;
  $image-var: map-get($common-svg-vars, $image);
  $image-path: map-get($image-origin, 'imageSrc');
  $padding: map-get($common-svg-origin, 'padding');
  $aspectRatio: calc(map-get($image-var, 'height') / map-get($image-var, 'width'));
  $sizeRatio: calc($size / map-get($image-var, 'width'));

  @if($size){
    @if(unitless($size)){
      @warn '"#{$size}" of "#{$image}" is unitless value. Please insert value with unit(px)';
    }
    @else {
      width: $size;
      height: $size * $aspectRatio;
      background-image: url($image-path);
      background-size: (map-get($image-origin, 'total-width') * $sizeRatio) (map-get($image-origin, 'total-height') * $sizeRatio);
      background-position: ((map-get($image-var, 'offset-x') - $padding) * $sizeRatio) ((map-get($image-var, 'offset-y') - $padding) * $sizeRatio);
    }
  }
  @else {
    width: map-get($image-var, 'width');
    height: map-get($image-var, 'height');
    background-image: url($image-path);
    background-size: map-get($image-origin, 'total-width') map-get($image-origin, 'total-height');
    background-position: (map-get($image-var, 'offset-x') - $padding) (map-get($image-var, 'offset-y') - $padding);
  }
  background-repeat: no-repeat;
}
