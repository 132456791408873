.page_cs {

}
.section_cs_guide {
  margin-bottom: 32px;
}
.cs_guide_list {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 18px;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}
.cs_guide_item {
  & + & {
    margin-left: 8px;
  }
  &:first-child {
    padding-left: 20px;
  }
  &:last-child {
    padding-right: 20px;
  }
  .cs_guide_link {
    display: block;
    position: relative;
    padding: 70px 14px 20px 14px;
    width: 150px;
    height: 160px;
    border-radius: 10px;
    background: map-get($color-background, bg-01);
    font-size: 14px;
    line-height: 1.43;
    color: $color-black;
    box-sizing: border-box;
    &::after {
      position: absolute;
      top: 18px;
      right: 14px;
      @include useSvg-common('arrow_right', 16px);
      content: '';
    }
  }
  .cs_icon {
    $cs-icons: payment, fee, cancel, refund, receipt, discount, ticketing, cheating;
    position: absolute;
    top: 14px;
    left: 14px;
    @each $icon in $cs-icons {
      &.icon_#{$icon} {
        @include useSvg-common(#{$icon}, 24px);
      }
    }
  }
  .cs_guide_title {
    display: block;
    font-weight: 500;
  }
  .cs_guide_desc {
    display: block;
    margin-top: 10px;
  }
}
.section_cs_personal {
  margin: 32px 20px;
  padding-top: 40px;
  border-top: 1px solid map-get($color-line, line-01);
}
.cs_faq_list {
  margin-top: 12px;
  .faq_toggle_btn {
    position: relative;
    padding: 16px 48px 16px 42px;
    width: 100%;
    text-align: left;
    &::before {
      position: absolute;
      top: 16px;
      left: 20px;
      @include useSvg-common('question', 18px);
      content: '';
    }
    &::after {
      position: absolute;
      top: 18px;
      right: 20px;
      @include useSvg-common('arrow_down', 16px);
      content: '';
    }
    &[aria-expanded="true"] {
      &::after {
        transform: rotate(180deg);
      }
      .faq_title {
        font-weight: 500;
      }
      & + .faq_cont {
        display: block;
      }
    }
    .faq_title {
      font-size: 14px;
      line-height: 1.43;
      color: $color-black;
    }
  }
  .faq_cont {
    display: none;
    position: relative;
    padding: 20px 20px 32px 42px;
    border-top: 1px solid map-get($color-line, line-01);
    background-color: map-get($color-background, bg-02);
    &::before {
      position: absolute;
      top: 20px;
      left: 20px;
      @include useSvg-common('answer', 18px);
      content: '';
    }
    img {
      width: 100%;
      vertical-align: top;
    }
  }
  .faq_desc {
    font-size: 13px;
    line-height: 1.38;
    color: $color-gray;
  }
}