// 국문
@font-face {
  font-family: 'Notosans';
  font-weight: 700;
  src: url(../font/NotoSans-Bold.woff2) format('woff2'), 
       url(../font/NotoSans-Bold.woff) format('woff');
}
@font-face {
  font-family: 'Notosans';
  font-weight: 500;
  src: url(../font/NotoSans-Medium.woff2) format('woff2'), 
       url(../font/NotoSans-Medium.woff) format('woff');
}
@font-face {
  font-family: 'Notosans';
  font-weight: 400;
  src: url(../font/NotoSans-Regular.woff2) format('woff2'), 
       url(../font/NotoSans-Regular.woff) format('woff');
}
@font-face {
  font-family: 'Pretendard';
	font-weight: 300;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-Light.woff) format("woff");
	src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-Light.woff) format("woff2");
}
@font-face {
  font-family: 'Pretendard';
	font-weight: 400;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-Regular.woff) format("woff");
	src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-Regular.woff2) format("woff2");
}
@font-face {
  font-family: 'Pretendard';
	font-weight: 500;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-Medium.woff) format("woff");
	src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-Medium.woff2) format("woff2");
}
@font-face {
  font-family: 'Pretendard';
	font-weight: 600;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-SemiBold.woff) format("woff");
	src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-SemiBold.woff2) format("woff2");
}
@font-face {
	font-family: 'Pretendard';
	font-weight: 700;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-Bold.woff) format("woff");
	src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-Bold.woff2) format("woff2");
}
// 영문+숫자 단독
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url(../font/Roboto-Bold.woff2) format('woff2'), 
       url(../font/Roboto-Bold.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url(../font/Roboto-Medium.woff2) format('woff2'), 
       url(../font/Roboto-Medium.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url(../font/Roboto-Regular.woff2) format('woff2'), 
       url(../font/Roboto-Regular.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url(../font/Roboto-Light.woff2) format('woff2'), 
       url(../font/Roboto-Light.woff) format('woff');
}