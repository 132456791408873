.page_ranking {
}
.section_weekly_best{
  .section_heading {
    margin-bottom: 18px;
  }
}
.section_ranking_detail {
  margin-top: 52px;
  .section_heading {
    margin-bottom: 20px;
  }
  .common_tab.type_dot {
    margin-top: 8px;
    .common_tab_list {
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .common_tab_item {
      &:first-child {
        padding-left: 20px;
      }
      &:last-child {
        padding-right: 20px;
      }
    }
  }
}
.filter_period {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: map-get($color-background, bg-01);
  padding: 0 20px;
  .common_tab.type_capsule + & {
    margin-top: 16px;
  }
  .common_tab.type_dot + & {
    margin-top: 4px;
  }
  .filter_period_nav {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 13px 16px;
    &::before,
    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-style: solid;
      border-color: $color-black transparent transparent transparent;
      border-width: 3.5px 4.5px;
      content: '';
    }
    &::before {
      left: 0;
      border-color: transparent $color-black transparent transparent;
    }
    &::after {
      right: 0;
      border-color: transparent transparent transparent $color-black;
    }
  }
  .period_sort {
    display: inline-block;
    font-size: 12px;
    line-height: 1.33;
    color: $color-black;
    vertical-align: middle;
  }
  .period_btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .period_calendar_btn {
    display: inline-flex;
    position: relative;
    min-height: 42px;
    padding: 12px 0 14px;
    &::after {
      margin-left: 2px;
      @include useSvg-common('calendar', 16px);
      content: '';
    }
  }
  .period_current {
    font-size: 12px;
    line-height: 1.33;
    color: $color-gray;
  }
}
.ranking_product {
}
.ranking_product_list {
  margin-top: 20px;
  padding: 0 20px;
}
.ranking_product_item {
  & + & {
    margin-top: 20px;
  }
  .common_data_none {
    position: static;
    margin: 130px 0;
    transform: none;
  }
}
.ranking_product_unit {
  .ranking_product_link {
    display: flex;
  }
  .ranking_product_imgbox {
    flex: none;
    overflow: hidden;
    position: relative;
    width: 82px;
    height: 102px;
    border-radius: 6px;
    background: map-get($color-background, bg-01);
    @include img-border;
    .ranking_product_img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .ranking_product_rank {
    flex: none;
    width: 42px;
    line-height: 0;
    text-align: center;
    .rank_number {
      display: block;
      margin-top: 1px;
      @include font-family('Roboto');
      font-weight: 700;
      font-size: 18px;
      line-height: 1;
      color: $color-black;
    }
    .rank_status {
      display: block;
      margin-top: 7px;
    }
    .rank_static {
      &::before {
        display: inline-block;
        margin: 5px 0 6px;
        width: 6px;
        height: 1px;
        background-color: map-get($color-type, gray-01);
        content: '';
      }  
    }
    .rank_up,
    .rank_down {
      display: inline-flex;
      align-items: center;
      @include font-family('Roboto');
      font-size: 12px;
      line-height: 1;
      &::before {
        margin-right: 1px;
        content: '';
      }
    }
    .rank_up {
      color: $color-primary;
      &::before {
        @include useSvg-common('rank_up', 8px);
      }
    }
    .rank_down {
      color: map-get($color-type, gray-01);
      &::before {
        @include useSvg-common('rank_down', 8px);
      }
    }
    .rank_new {
      font-size: 9px;
      line-height: 1.33;
      color: $color-primary;
    }
    .flag_area {
      margin: 3px 0 0 0;
    }
    .flag {
      margin: 0;
    }
  }
  .ranking_product_info {
    flex: 1;
    font-size: 12px;
    line-height: 1.33;
  }
  .ranking_product_title {
    @include multi-ellipsis(2);
    font-size: 14px;
    line-height: 1.43;
    color: $color-black;
  }
  .ranking_product_sideinfo {
    margin-top: 4px;
    color: $color-gray;
  }
  .ranking_product_place {
    @include multi-ellipsis(1);
  }
  .ranking_product_period {
    display: block;
    margin-top: 2px;
    @include font-family('Roboto');
    font-weight: 300;
  }
  .ranking_product_rate {
    display: inline-flex;
    margin-top: 8px;
    .rate_text {
      color: $color-black;
      margin-right: 4px;
    }
    .rate_percent {
      @include font-family('Roboto');
      color: $color-primary;
    }
  }
}
.section_ranking_notcie {
  position: relative;
  margin-top: 48px;
  padding-top: 21px;
  &::before {
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    height: 1px;
    background-color: map-get($color-line , line-01);
    content: '';
  }
  .section_heading {
    margin-bottom: 16px;
  }
  .ranking_criterion {
    padding: 0 20px;
  }
  .ranking_criterion_tit {
    margin: 16px 0 9px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.38;
    color: $color-black;
    &:first-child {
      margin-top: 0;
    }
  }
  .ranking_criterion_desc {
    position: relative;
    padding-left: 8px;
    font-size: 12px;
    line-height: 1.33;
    color: $color-gray;
    &::before {
      position: absolute;
      top: 8px;
      left: 0;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: $color-gray;
      content: '';
    }
    & + .ranking_criterion_desc {
      margin-top: 8px;
    }
  }
}