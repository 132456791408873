.m_header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 52px;
  background-color: $color-white;
  z-index: map-get($z-index, nav-fixed);
  &.type_transparent {
    background-color: transparent;
    .header_title {
      color: $color-white;
    }
  }
  &.type_search {
    .header_inner {
      position: relative;
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: $color-black;
        content: '';
      }
    }
  }
}
.header_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  height: 100%;
  text-align: center;
  &.type_align_left {
    padding-left: 20px;
    justify-content: flex-start;
  }
  .season_off {
    padding: 13px 70px;
    display:inline-block;
    position: relative;
   &::after{
    content: "";
    width: 64px;
    height: 21px;
    background: url(../../../img/@temp/season_off.png) no-repeat 0 0/100%;
    position: absolute;
    top:2px;
    right:2px;
   }
  }
}
.header_title {
  @include ellipsis;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.44;
  color: $color-black;
  .header_logo_link {
    display: block;
    width: 62px;
    height: 24px;
  }
  &.type_logo_black {
    .header_logo_link {
      background: url(../img/svg/common/logo_black_kr.svg) center / 100% 100% no-repeat;
    }
  }
  &.type_logo_charlotte {
    .header_logo_link {
      width: 66px;
      height: 24px;
      background: url(../img/svg/common/logo_charlotte.svg) center / 100% 100% no-repeat;
    }
  }
  &.type_logo_wkbl {
    .header_logo_link {
      width: 60px;
      height: 39px;
      background: url(../img/svg/common/logo_wkbl.svg) center / 100% 100% no-repeat;
    }
  }
  &.type_logo_kbl {
    .header_logo_link {
      width: 60px;
      height: 36px;
      background: url(../img/svg/common/logo_kbl.svg) center / 100% 100% no-repeat;
    }
  }
  &.type_logo_bunker {
    .header_logo_link {
      width: 88px;
      height: 22px;
      background: url(../img/svg/common/logo_bunker.svg) center / 100% 100% no-repeat;
    }
  }
  &.type_logo_coupangplay {
    .header_logo_link {
      width: 153px;
      height: 28px;
      background: url(../img/svg/common/logo_coupangplay.svg) center / 100% 100% no-repeat;
    }
  }
  &.type_logo_jbhyundai {
    .header_logo_link {
      width: 180px;
      height: 42px;
      background: url(../img/svg/common/logo_jbhyundai.svg) center / 100% 100% no-repeat;
    }
  }
  &.type_logo_ssgranders {
    .header_logo_link {
      width: 136px;
      height: 40px;
      background: url(../img/logo_ssgranders.png) center no-repeat;
      background-size: auto 40px;
    }
  }
}
.header_sub_title {
  display:block;
}
.header_sub_title {
  display:block;
}

.header_util_left {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}
.header_util_right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  .ico_lang {
    display: inline-flex;
    overflow: hidden;
    margin-right: 4px;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    background: url(../img/ico_lang.png) 0 0 no-repeat;
    background-size: 16px auto;
    &.eng {
      background-position: 0 -20px;
    }
    &.jpn {
      background-position: 0 -40px;
    }
    &.chn {
      background-position: 0 -60px;
    }
  }
  .header_dropdown_content {
    display: none;
    overflow: hidden;
    position: absolute;
    top: 36px;
    right: 18px;
    border: 1px solid $color-black;
    border-radius: 6px;
    background: $color-white;
    .header_language {
      &_item {
        &:first-child,
        &:last-child {
          .header_language_link {
            height: 39px;
          }
        }
      }
      &_link {
        display: flex;
        align-items: center;
        gap: 0 6px;
        padding: 0 23px 0 13px;
        height: 37px;
        font-size: 13px;
        line-height: 18px;
        color: map-get($color-type, gray-01);
        &[aria-selected=true],
        &:hover,
        &:focus {
          background: map-get($color-background, bg-01);
          color: $color-black;
        }
      }
    }
  }
}
.header_button {
  $utils: back, close, share, search, menu, alarm_setting, camera;
  width: 50px;
  height: 52px;
  &::before {
    display: block;
    margin: 0 auto;
    content: '';
    .header_util_left & {
      margin-left: 20px;
    }
    .header_util_right & {
      margin-right: 20px;
    }
  }
  @each $util in $utils {
    &.button_#{$util} {
      &::before {
        @include useSvg-common(#{$util}, 24px);
        .m_header.type_transparent & {
          @include color-filter($color-white);
        }
      }
    }
  }
  &.button_lang {
    display: flex;
    align-items: center;
    padding-right: 20px;
    width: auto;
    color: #62676C;
    text-decoration: underline;
    font-weight: 500;
    font-family: 'Pretendard';
    letter-spacing: -0.01em;
    &::after {
      content: none;
    }
    .m_header.type_transparent & {
      color: #fff;
    }
    &[aria-expanded="true"] + .header_dropdown_content {
      display: block;
    }
  }
}

.header_search_box {
  position: relative;
  width: 100%;
}
.header_search_input {
  padding-right: 35px;
  border: 0;
  background: none;
  width: 100%;
  height: 52px;
  font-size: 14px;
  line-height: 1.43;
  color: $color-black;
  box-sizing: border-box;
  caret-color: $color-primary;
  &::placeholder {
    color: map-get($color-type, gray-02);
  }
  &::-webkit-search-cancel-button {
    display: none;
  }
}
.header_search_clear {
  position: absolute;
  top: 0;
  right: 5px;
  width: 24px;
  height: 100%;
  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    @include useSvg-common('input_delete', 24px);
    transform: translateY(-50%);
    content: '';
  }
}
.header_search_layer {
  position: fixed;
  top: 52px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: map-get($z-index, popup);
  .dimmed {
    position: static;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000, $alpha: 0.5);
  }
}
.header_search_suggest {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 14px 0;
  background: $color-white;
  .search_suggest_link {
    display: block;
    padding: 12px 20px;
    &:focus,
    &:active {
      background: map-get($color-background, bg-02)
    }
  }
  .search_suggest_text {
    @include ellipsis;
    font-size: 13px;
    line-height: 1.38;
    color: $color-black;
  }
  .search_suggest_keyword {
    color: $color-primary;
  }
}
.header_tooltip {
  position: fixed;
  top: 39px;
  right: 20px;
  padding: 5px 10px 6px;
  border-radius: 25px;
  background: #16233d;
  font-size: 11px;
  font-weight: 700;
  line-height: 1.273;
  color: $color-white;
  box-sizing: border-box;
  &::before {
    position: absolute;
    top: -1px;
    right: 8px;
    border: 4px solid transparent;
    border-color: #16233d transparent transparent #16233d;
    border-top-left-radius: 2px;
    transform: rotate(60deg) skewX(10deg);
    content: '';
  }
}