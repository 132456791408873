// common list
.page_list {
}
.section_list_filter {
  position: sticky;
  top: 52px;
  background-color: $color-white;
  z-index: map-get($z-index, sticky);
  .common_tab.type_capsule {
    padding-top: 12px;
    &:last-child {
      padding-bottom: 20px;
    }
  }
}
.list_filter_depth {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px 10px;
  .btn_dropdown,
  .dropdown_wrap {
    &:only-child {
      margin-left: auto;
    }
  }
  .btn_dropdown {
    &:first-child:nth-last-child(2) {
      margin-left: -6px;
    }
  }
  .list_filter_count {
    font-size: 13px;
    line-height: 1.38;
    color: $color-black;
    .text_primary {
      color: $color-primary;
    }
    &+.checkbox_item {
      margin: 0 auto 0 8px;
      .label_checkbox {
        &::before {
          width: 18px;
          height: 18px;
        }
        padding-left: 22px;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

}
.section_product_list {
  .product_grid + .common_banner {
    margin: 44px 0 36px;
  }
}

// plan
.page_plan {
  .common_data_none {
    margin-top: 60px;
  }
}
.section_plan_banner {
  margin-bottom: 32px;
}
.plan_top_banner {
  position: relative;
  height: 120px;
  background-color: $color-black;
  .plan_title {
    position: absolute;
    top: 50%;
    padding: 0 20px;
    width: 100%;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.45;
    color: $color-white;
    text-align: center;
    box-sizing: border-box;
    transform: translateY(-50%);
    z-index: 1;
  }
  .plan_banner_imgbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .plan_banner_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.section_list_banner {
  margin-bottom: 32px;
}
.list_banner_link {
  display: block;
  position: relative;
  padding-top: 33.333%;
  background-color: map-get($color-background, bg-01);
  .list_banner_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.page_bugs_lounge {
  &.m_container { 
    padding-top: 0;
  }
  .section_heading {
    margin-bottom: 20px;
  }
  .section_title {
    font-size: 18px;
    line-height: 1.44;
  }
}
.section_bugs_gate {
  margin-top: 20px;
  padding: 0 20px;
  .common_btn {
    &::before {
      @include useSvg-common('movie_white', 25px);
      margin-right: 10px;
      content: '';
    }
  }
}
.section_bugs_guide {
  margin-top: 60px;
  padding: 0 20px;
}
.bugs_guide_banner {
  position: relative;
  .bugs_banner_img {
    width: 100%;
    vertical-align: top;
  }
  .bugs_banner_close {
    position: absolute;
    top: -36px;
    right: 0;
    padding: 10px 0;
    font-size: 11px;
    line-height: 1.454;
    color: map-get($color-type, gray-01);
  }
  
}
.section_bugs_live,
.section_bugs_premium {
  .common_data_none {
    position: static;
    margin: 100px 0;
    transform: none;
  }
}
.section_bugs_live {
  margin-top: 40px;
}
.section_bugs_premium {
  margin-top: 60px;
}

@-webkit-keyframes ani_skeleton {
  0% {
    background-position: 250% 0
  }
  100% {
    background-position: -250% 0
  }
}

.skeleton_container {
  position: relative;
  .ui_skeleton {
    -webkit-animation-duration: 1.75s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: ani_skeleton;
    -webkit-animation-timing-function: linear;
    background-image: linear-gradient(120deg, rgba(238,239,241,1) 40%, rgba(246,247,248,1) 48%, rgba(246,247,248,1) 52%, rgba(238,239,241,1) 60%);
    background-repeat: no-repeat;
    background-size: 400% auto;
  }
  .skeleton_banner {
    background-color: #eeeff1;
    -webkit-animation-duration: 1.75s;
  }
  .skeleton_grid {
    width: 100%;
    padding: 0 16px 100px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    .skeleton_item {
      width: calc(50% - 8px);
      padding: 0 4px;
      background-color: #fff;
      &.type_banner {
        width: 100%;
        padding: 20px 0 48px;
        .skeleton_banner {
          width: calc(100% + 32px);
          margin-left: -16px;
          padding: 20.833% 0 0;
        }
      }
    }
    .skeleton_img {
      width: 100%;
      padding-top: 121.153%;
      background-color: #eeeff1;
      border: 1px solid #fff;
      border-radius: 6px;
      overflow: hidden;
    }
    .skeleton_txt {
      max-height: 120px;
      margin-top: 10px;
      padding-bottom: 23.076%;
      box-sizing: border-box;
      span {
        display: block;
        border-radius: 2px;
        background-color: #eeeff1;
        &:nth-child(1) {
          width: 14.102%;
          height: 16px;
        }
        &:nth-child(2) {
          margin-top: 8px;
          width: 89.743%;
          height: 21px;
        }
        &:nth-child(3) {
          margin-top: 8px;
          width: 58.974%;
          height: 16px;
        }
        &:nth-child(4) {
          margin-top: 8px;
          width: 83.333%;
          height: 16px;
        }
      }
    }
  }
}

// 여행박사 리스트 
  .list_trip {
    .tab_frame_wrap {
      margin-top: 12px;
    }
  
    .common_tab.type_capsule {
      width: 100vw;
      padding-left: 0;
      overflow-x: hidden;
    }
  
    .area_wrap {
      margin: 10px 0;
      padding: 0 20px;
      text-align: right;
  
      &_tit {
        position: relative;
        font-size: 12px;
        font-weight: 700;
        
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: -8px;
          width: 1px;
          height: 12px;
          background-color: map-get($color-type, gray-01);
          transform: translateY(-50%);
        }
      }
  
      .btn_dropdown {
        padding: 8px 16px 6px 12px;
      }
    }
  
    .tab_cont_item{
      display: none;
      
      &.active {
        display: block;
      }
  
    }
  
    .trippkg_header {
      position: relative;

      & > img {
        width: 100%;
        max-width: 100%;
        filter: brightness(65%);
      }
  
      .trippkg_header_txt {
        position: absolute;
        top: 42%;
        left: 0;
        right: 0;
        padding: 0 20px;
        color: $color-white;
        z-index: 2;
      }
      
      &_tit {
        line-height: 26px;
        font-size: 18px;
        font-weight: 500;
      }
      
      &_price {
        @include font-family('Roboto');
        display: block;
        margin-top: 10px;
        font-size: 17px;
        
        & > span {
          @include font-family('Notosans');
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    .trippkg_header.trippkg_header_type1{
      position: relative;
      width: 100%;
      height: 126px;

      &::after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.4);
        z-index: 1;
      }

      & > img {
        position: absolute;
        left: 20px;
        top: 18px;
        width: 120px;
        height: 120px;
        border-radius: 6px;
        box-sizing: border-box;
        z-index: 2;
      }

      & .trippkg_header_txt {
        position: relative;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        height: 90px;
        padding: 18px 20px 18px 155px;
        color: $color-white;
        z-index: 2;

        & .trippkg_header_tit {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          padding-right: 5px;
        }

        & .trippkg_header_price {
          font-size: 16px;
          line-height: 16px;
          font-weight: 400;
          margin-top: 34px;
        }

        & > button {
          position: absolute;
          height: 50%;
          width: 15%;
          right: 0px;
          bottom: 0px;
          display: block;
          cursor: pointer;

          &::after {
            width: 16px;
            height: 16px;
            background-image: url(../img/common-svg.svg);
            background-size: 350px 336px;
            background-position: -328px -180px;
            background-repeat: no-repeat;
            content: '';
            display: block;
            filter: brightness(0) invert(1);
            position: absolute;
            right: 20px;
            bottom: 18px;
          }
        }

        & > button.active::after {
          transform: scaleY(-1);
        }
      }
    }

    .trippkg_header.trippkg_header_type1.trippkg_header_bg_type1{
      background-color: #264e4d;
    }

    .trippkg_header.trippkg_header_type1.trippkg_header_bg_type2{
      background-color: #025462;
    }

    .trippkg_header.trippkg_header_type1.trippkg_header_bg_type3{
      background-color: #77614c;
    }
    
    .trippkg_group > ul {
      position: relative;
      padding: 0 20px;
      top: -20px;
      & > li {
        margin-bottom: 20px;
        border: 1px solid map-get($color-line, line-01);
        border-radius: 10px;
        background-color: $color-white;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .trippkg_group.trippkg_group_type1 {
      margin-bottom: 32px;
    }
    
    .trippkg_group.trippkg_group_type1 > ul {
      top: 0px;
      padding: 32px 20px 20px 20px;
      display: none;

      &.active{
        display: block;
        margin-bottom: -32px;
      }
    }

    .trip_card {
      padding: 20px 16px;
      
      .badge_wrap {
        margin-bottom: 9px;
    
        & > span {
          padding: 3px 6px;
          border-radius: 100px;
          background-color: map-get($color-background, bg-01);
          font-size: 10px;
        }
      }
      &_tit {
        line-height: 24px;
        font-size: 16px;
        font-weight: 500;
      }
      & > p {
        margin: 12px 0;
        line-height: 16px;
        font-size: 12px;
        color: $color-gray;
      }
      &_price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > p {
          font-size: 17px;
          & > span {
            font-size: 10px;
          }
        }
        & > button {
          position: relative;
          align-items: end;
          height: 30px;
          padding: 5px 31px 7px 12px;
          font-size: 11px;
          &::after {
            @include useSvg-common('arrow_down', 16px);
            @include color-filter($color-white);
            content: '';
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
          }
          &.active {
            border: 1px solid map-get($color-line, line-04);
            background-color: $color-white;
            color: $color-black;
            &::after {
              @include useSvg-common('arrow_up', 16px);
              content: '';
              position: absolute;
              top: 50%;
              right: 10px;
              filter: none;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
    .trip_card.trip_card_type1{
      padding: 24px 16px 20px 16px;
    }
  
    .trip_calendar_frame {
      display: none;
      &.active {
        display: block;
      }
      .trip_calendar_wrap {
        overflow: hidden;
        max-height: 830px;
        transition: 0.3s;
        &.active {
          max-height: none;
        }
      }
      .product_templete_btnmore {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 15px 20px;
        border-top: 1px solid #ecedf2;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;
        color: $color-black;
  
        &::after {
          margin-left: 2px;
          @include useSvg-common('arrow_down', 16px);
          content: '';
        }
        &.active::after {
          transform: rotate(180deg);
        }
      }
    }
  }


.bottom_sheet_wrap {

  .bottom_sheet > h3 {
    line-height: 24px;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }

  .list_3depth_frame {
    padding: 8px 25px 20px 35px;
    height: 428px;
    box-sizing: border-box;

    .main_list {
      height: 50px;
      padding: 15px 0;
      position: relative;
      box-sizing: border-box;
      cursor: pointer;

      &.active > span {
        font-weight: 700;
      }

      .label_radio {
        padding: 1px 0 0 32px;
      }

      span {
        line-height: 20px;
      }

      .btn_hyperlink {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        &::after{
          border-color: #242428;
        }
      }
    }
  }

  .list_4depth_frame {
    padding: 8px 0px 0px 0px;
    height: 408px;
    box-sizing: border-box;
    
    & > ul {
      background-color: #F5F6F9;
      height: 400px;
      width: 50%;
      position: relative;

      .main_list {
        height: 50px;
        padding: 15px 0 15px 17.5px;
        box-sizing: border-box;

        &.active {
          background-color: #fff;
        }

        &.active > span {
          font-weight: 700;

          & .btn_hyperlink {
            display: inline-flex;
          }
        }

        & > span {
          line-height: 20px;
          display: block;
          position: relative;

          & .btn_hyperlink {
            position: absolute;
            right: 16.5px;
            top: 50%;
            transform: translateY(-50%);
            display: none;
          }
        }
        
        .sub_list {
          width: 100%;
          height: 400px;
          background-color: #fff;
          position: absolute;
          left: 100%;
          top: 0;
          
          & > li {
            box-sizing: border-box;
            height: 50px;
            padding: 15px 0 15px 17.5px;
          }

          & > li.active {
            font-weight: 700;
          }

        }
      }
    }
  }


}



