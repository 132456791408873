.flag_area {
  font-size: 0;
  margin: -4px 0 0 -3px;
}
.flag {
  display: inline-block;
  margin: 4px 0 0 3px;
  padding: 0 6px;
  font-size: 10px;
  line-height: 1.4;
  border: 1px solid $color-black;
  border-radius: 16px;
  background-color: $color-white;
  color: $color-black;
  &.flag_red {
    border-color: $color-primary;
    color: $color-primary;
  }
  &.flag_red_bg {
    background-color: $color-primary;
    border-color: $color-primary;
    color: $color-white;
  }
  &.flag_purple {
    border-color: map-get($color-type, purple-01);
    color: map-get($color-type, purple-01);
  }
  &.flag_blue {
    border-color: map-get($color-type, blue-01);
    color: map-get($color-type, blue-01);
  }
  &.flag_black {
    border-color: $color-black;
    color: $color-black;
  }
  &.flag_black_bg {
    border-color: $color-black;
    color: $color-white;
    background-color: $color-black;
  }
  $flag-icons: today, clean;
  @each $icon in $flag-icons {
    &.flag_#{$icon} {
      display: inline-flex;
      align-items: center;
      background-color: map-get($color-background, bg-01);
      border-color: map-get($color-background, bg-01);
      &::before {
        @include useSvg-common(#{$icon}, 12px);
        content: '';
      }
    }
  }
  &.flag_today {
    padding-left: 2px;
    color: $color-gray;
  }
  &.flag_clean {
    padding-left: 4px;
    color: map-get($color-type, clean-01);
  }
  &.flag_ghost {
    border-color: map-get($color-line, line-04);
    color: $color-black;
  }
  &.flag_device {
    border-color: #386dbe;
    color: #386dbe;
  }
}