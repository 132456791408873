.m_footer {
  padding: 0 20px;
  background-color: $color-white;
  color: $color-black;
  .m_toolbar ~ & {
    @include safeArea(false, padding, 20px bottom);
  }
}
.footer_inner {
  border-top: 1px solid map-get($color-line, line-01);
  padding: 12px 0 80px;
}
.footer_toggle_btn {
  padding: 8px 8px 8px 0;
  font-size: 12px;
  line-height: 1.33;
  .text {
    display: inline-block;
    vertical-align: middle;
  }
  &::after {
    display: inline-block;
    margin-left: 3px;
    border-top: 5px solid $color-black;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    vertical-align: middle;
    content: '';
  }
  &[aria-expanded="true"] {
    &::after {
      margin-top: -1px;
      transform: rotate(180deg);
    }
    & + .footer_toggle_cont {
      display: block;
    }
  }
}
.footer_toggle_cont {
  display: none;
  margin-bottom: 12px;
  font-size: 11px;
  line-height: 1.45;
  color: $color-gray;
}
.footer_term_list {
  margin-top: 4px;
  font-size: 0;
}
.footer_term_item {
  display: inline-block;
  position: relative;
  & + & {
    padding-left: 2px;
    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 2px;
      height: 2px;
      margin-top: -1px;
      background-color: $color-gray;
      border-radius: 50%;
      content: '';
    }
  }
}
.footer_term_link {
  display: inline-block;
  padding: 4px 5px 4px 4px;
  font-size: 12px;
  line-height: 1.33;
  color: $color-black;
  .footer_term_item:first-child & {
    padding-left: 0;
  }
}
.footer_copyright {
  display: block;
  margin-top: 2px;
  @include font-family('Roboto');
  font-size: 10px;
  line-height: 1.6;
  color: map-get($color-type, gray-02);
}
.m_footer_simple {
  padding: 7px 15px;
  @include safeArea(false, padding, 7px bottom);
  border-top: 1px solid map-get($color-line, line-01);
  background-color: map-get($color-background, bg-02);
}
.footer_copyright_simple {
  font-size: 12px;
  line-height: 1.33;
  color: map-get($color-type, gray-01);
  text-align: right;
}